<template>
    <v-card-text>
        <v-container class="px-0 px-sm-2 mt-6">
            <slot></slot>
            <v-row no-gutters class="justify-center justify-md-end mt-4">
                <slot name="footer"></slot>
            </v-row>
        </v-container>
    </v-card-text>
</template>
<script>
    export default {
        name:"modal-container-wrapper"
    }
</script>