<template>
    <v-row no-gutters>
<!--        <v-col cols="12">{{JSON.stringify(herdings)}}</v-col>-->
        <v-col class="inspections">
            <v-row no-gutters class="mb-3">
                <v-col>
                    <transition-group name="inspection-row">
                        <v-row no-gutters v-for="(herding, index) in herdings" :key="'herding' + index"
                               :class="$vuetify.breakpoint.mdAndDown ? 'boxed-content mb-3' : null" data-cy="herding-row">
                            <v-col>
                                <v-row no-gutters>
                                    <v-col class="col-12 col-md-12 col-lg-3 pr-2 pr-sm-0">
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-select
                                                    :prepend-icon="mdiDonkey()"
                                                    :items="herdingAnimalTypes"
                                                    v-model="herding.animalType"
                                                    item-text="label"
                                                    item-value="value"
                                                    label="Looma tüüp"
                                                    data-cy="herding-animal-type"
                                                    :error-messages="herdingErrors[index].animalType"
                                                ></v-select>
                                            </v-col>
                                            <div v-if="$vuetify.breakpoint.mdAndDown"
                                                 class="ml-1 ml-sm-4 ml-md-4 align-self-center mt-n2"
                                                 style="width: 40px">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <div class="d-inline-block" v-on="on">
                                                            <delete-button title="Kustuta rida"
                                                                           :clickAction="() => deleteHerding(herding)"
                                                                           data-cy="herding-delete-button"/>
                                                        </div>
                                                    </template>
                                                    <span>{{ "Kustuta rida" }}</span>
                                                </v-tooltip>
                                            </div>
                                        </v-row>
                                    </v-col>

                                    <v-col
                                        class="col-12 col-sm-12 col-md-3 col-lg-3 pl-0 pl-md-4 pr-0 pr-sm-4 pr-md-4">
                                        <v-text-field
                                            :prepend-icon="mdiTallyMark5()"
                                            v-model="herding.amount"
                                            type="number"
                                            min="0"
                                            data-cy="herding-amount"
                                            label="Loomade arv"
                                            :error-messages="herdingErrors[index].amount"
                                        />
                                    </v-col>
                                    <v-col
                                        class="col-12 col-sm-4 col-md-3 col-lg-2 pr-0 pr-sm-4 pr-md-4">
                                        <v-text-field
                                            :prepend-icon="mdiCalendarRange()"
                                            v-model="herding.days"
                                            type="number"
                                            min="0"
                                            data-cy="herding-days"
                                            label="Päevi"
                                            :error-messages="herdingErrors[index].days"
                                        />
                                    </v-col>
                                    <v-col
                                        class="col-12 col-sm-4 col-md-3 col-lg-2 pr-0 pr-md-2">
                                        <v-text-field
                                            :prepend-icon="mdiClockPlusOutline()"
                                            v-model="herding.hoursPerDay"
                                            type="number"
                                            min="0"
                                            suffix="h"
                                            data-cy="herding-hours-per-day"
                                            label="Päevas tunde"
                                            :error-messages="herdingErrors[index].hoursPerDay"
                                        />
                                    </v-col>
                                    <v-col
                                        class="col-12 col-sm-4 col-md-3 col-lg-2" :class="isCreatingNew ? 'pr-0 pr-md-2' : 'pr-0 pr-md-1'">
                                        <v-text-field
                                            :prepend-icon="mdiEqual()"
                                            :value="totalHours[index]"
                                            :readonly="true"
                                            type="number"
                                            min="0"
                                            :disabled="herdingErrors[index].totalHours === null || herdingErrors[index].totalHours === undefined"
                                            suffix="h"
                                            data-cy="herding-total-amount"
                                            label="Kokku"
                                            :error-messages="herdingErrors[index].totalHours"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <div v-if="!$vuetify.breakpoint.mdAndDown" class="ml-1 align-self-center mt-n2"
                                 style="width: 40px">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <div class="d-inline-block" v-on="on">
                                            <delete-button title="Kustuta rida"
                                                           :clickAction="() => deleteHerding(herding)"
                                                           data-cy="herding-delete-button"/>
                                        </div>
                                    </template>
                                    <span>{{ "Kustuta rida" }}</span>
                                </v-tooltip>
                            </div>
                        </v-row>
                    </transition-group>
                    <v-row no-gutters>
                        <v-col cols="12" class="text-center">
                            <v-btn color="primary" dark large
                                   elevation="0"
                                   @click.native.stop="addHerding()"
                                   data-cy="herding-add-button">
                                <v-icon>add</v-icon>
                                Lisa looma tüüp
                                <v-icon class="ml-2">{{ mdiDonkey() }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="justify-center mt-4">
                        <v-col :class="isCreatingNew ? 'col-12 col-md-8 col-lg-7' : 'col-12'">
                            <v-simple-table style="border: 2px solid #dbe1d9; background-color:#f9f9f9;border-radius: 6px; padding: 0px 20px 10px 20px">
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left" style="width: 33.33%">
                                            Element
                                        </th>
                                        <th class="text-left" style="width: 33.33%">
                                            Pindala peale kokku
                                        </th>
                                        <th class="text-left" style="width: 33.33%">
                                            Hektari kohta
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr><td style="width: 33.33%">N</td>
                                        <td style="width: 33.33%;white-space: nowrap" data-cy="herding-nutrient-table-n">{{ nutrientN | toLocalRoundedNumber }} kg</td>
                                        <td style="width: 33.33%;white-space: nowrap">{{ nutrientNperHectare | toLocalRoundedNumber }} kg/ha</td>
                                    </tr>
                                    <tr><td style="width: 33.33%">P</td>
                                        <td style="width: 33.33%;white-space: nowrap" data-cy="herding-nutrient-table-p">{{ nutrientP | toLocalRoundedNumber }} kg</td>
                                        <td style="width: 33.33%;white-space: nowrap">{{ nutrientPperHectare | toLocalRoundedNumber }} kg/ha</td>
                                    </tr>
                                    <tr><td style="width: 33.33%">K</td>
                                        <td style="width: 33.33%;white-space: nowrap" data-cy="herding-nutrient-table-k">{{ nutrientK | toLocalRoundedNumber }} kg</td>
                                        <td style="width: 33.33%;white-space: nowrap">{{ nutrientKperHectare | toLocalRoundedNumber }} kg/ha</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </v-col>
    </v-row>
</template>
<script>

import {VBtn} from 'vuetify/lib'
import {mdiCalendarRange, mdiClockPlusOutline, mdiDonkey, mdiEqual, mdiTallyMark5} from "@mdi/js";
import DeleteButton from "@/components/deleteButton.vue";
import {provideCloseDropdown, provideRequiredPositiveNumber} from "@/mixins/mixins";
import {HERDING_INFO} from "../../../backend/common/constants";
import {herdingNutrientsPerHectare, herdingNutrientsTotal} from "../../../backend/common/herdingCalculator";

export default {
    components: {DeleteButton, VBtn},
    props: {
        initialHerdings: {
            type: Array,
            default: () => []
        },
        dateStart: {
            type: String,
        },
        dateEnd: {
            type: String,
        },
        isCreatingNew: {
            type: Boolean,
            default: () => false
        },
        area: {
            default: () => 0
        }
    },
    data() {
        return {
            herdings: [],
            herdingErrors: [],
            totalHours: [],
            nutrientN: 0,
            nutrientP: 0,
            nutrientK: 0,
            nutrientNperHectare: 0,
            nutrientPperHectare: 0,
            nutrientKperHectare: 0,
        }
    },
    mixins: [provideCloseDropdown, provideRequiredPositiveNumber],
    computed: {
        herdingAnimalTypes() {
            return HERDING_INFO.map(nutrientInfo => ({
                label: nutrientInfo.label,
                value: nutrientInfo.unit
            }))
        },
        dayCountFromDates() {
            let dayCount = 1;
            if (this.dateStart && this.dateEnd) {
                const startMillis = new Date(this.dateStart).getTime();
                const endMillis = new Date(this.dateEnd).getTime();
                const differenceMillis = endMillis - startMillis;
                dayCount = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24))+1;
            }
            return dayCount
        }
    },
    methods: {
        mdiClockPlusOutline() {
            return mdiClockPlusOutline
        },
        mdiCalendarRange() {
            return mdiCalendarRange
        },
        mdiTallyMark5() {
            return mdiTallyMark5
        },
        mdiEqual() {
            return mdiEqual
        },
        mdiDonkey() {
            return mdiDonkey
        },
        herdingTotalHours(herding) {
            if (herding.days && herding.hoursPerDay) {
                return herding.days * herding.hoursPerDay
            }
            return undefined
        },
        addHerding() {
            let days = null
            let hoursPerDay = null
            if (this.herdings && this.herdings.length > 0) {
                const lastHerding = this.herdings[this.herdings.length - 1];
                if (lastHerding.days !== null) {
                    days = lastHerding.days;
                }
                if (lastHerding.hoursPerDay !== null) {
                    hoursPerDay = lastHerding.hoursPerDay;
                }
            }
            this.herdings.push({
                animalType: null,
                amount: null,
                days: days,
                hoursPerDay: hoursPerDay,
            });
            this.herdingErrors.push({});
            this.totalHours.push({});
        },
        deleteHerding(herdingToDelete) {
            this.herdings = this.herdings.filter(inspection => inspection !== herdingToDelete);
        },
        validateHerdings(fullValidation = true) {
            let formHasErrors = null
            if (fullValidation) {
                this.herdingErrors.forEach(errors => {
                    for (let key in errors) {
                        delete errors[key];
                    }
                });
            }
            this.herdings.forEach((herding, index) => {
                const errors = {};

                if (!herding.animalType || herding.animalType === '') {
                    errors.animalType = ['Kohustuslik väli'];
                    formHasErrors = true
                }
                errors.amount = this.errorsForExistingPositiveNumber(herding.amount);
                errors.days = this.errorsForExistingPositiveNumber(herding.days);

                if (!herding.hoursPerDay || herding.hoursPerDay === '') {
                    errors.hoursPerDay = ['Kohustuslik väli'];
                } else if (herding.hoursPerDay < 0) {
                    errors.hoursPerDay = ['Ei saa olla negatiivne'];
                } else if (herding.hoursPerDay > 24) {
                    errors.hoursPerDay = ['Max 24h'];
                }
                const maxHours = this.dayCountFromDates * 24;
                if (herding.hoursPerDay && herding.days && herding.days * herding.hoursPerDay > maxHours) {
                    errors.totalHours = ['Max ' + maxHours + 'h'];
                }

                if (errors.animalType || errors.amount || errors.days || errors.hoursPerDay || errors.totalHours) {
                    formHasErrors = true
                }
                if (fullValidation) {
                    this.$set(this.herdingErrors, index, errors);
                } else { // only remove errors that have been resolved
                    const currentErrors = this.herdingErrors[index];
                    for (let currentErrorKey in currentErrors) {
                        if (!errors[currentErrorKey]) {
                            delete currentErrors[currentErrorKey];
                        }
                    }
                    this.$set(this.herdingErrors, index, currentErrors);
                }
            });
            this.$emit('validate-herdings', formHasErrors)

        },
        automaticallyFillHerdingDays(dateWasChanged = false) {
            this.herdings.forEach((herding) => {
                if ((herding.days === null || herding.days === '' || herding.days === undefined) || (dateWasChanged)) {
                    herding.days = this.dayCountFromDates;
                }
            });
        },
        calculateNutrients() {
            const areaValue = parseFloat(this.area);
            this.nutrientN = herdingNutrientsTotal(this.herdings, 'N');
            this.nutrientP = herdingNutrientsTotal(this.herdings, 'P');
            this.nutrientK = herdingNutrientsTotal(this.herdings, 'K');
            this.nutrientNperHectare = herdingNutrientsPerHectare(this.nutrientN, areaValue);
            this.nutrientPperHectare = herdingNutrientsPerHectare(this.nutrientP, areaValue);
            this.nutrientKperHectare = herdingNutrientsPerHectare(this.nutrientK, areaValue);
        },
    },
    watch: {
        dateStart: {
            handler: function () {
                this.automaticallyFillHerdingDays(true);
            },
            deep: true,
            immediate: true
        },
        dateEnd: {
            handler: function () {
                this.automaticallyFillHerdingDays(true);
            },
            deep: true,
            immediate: true
        },
        area: {
            handler: function () {
                this.calculateNutrients();
            },
            immediate: true
        },
        herdings: {
            handler: function (newVal) {
                this.herdings.forEach((herding, index) => {
                    this.totalHours[index] = this.herdingTotalHours(herding)
                })

                this.automaticallyFillHerdingDays();
                this.calculateNutrients();
                this.$emit('update:initialHerdings', newVal);
                this.validateHerdings(false);
            },
            deep: true,
            immediate: true
        },
        initialHerdings(newVal, oldVal) {
            if (JSON.stringify(newVal) !== JSON.stringify(oldVal) && newVal) {
                this.herdings = [...newVal];
                this.herdingErrors = this.herdings.map(() => ({}));
                this.totalHours = this.herdings.map(() => ({}));
                this.automaticallyFillHerdingDays();
            }
            if (newVal === null) {
                this.herdings = [];
            }
        },
    },
    created() {
        if (this.initialHerdings) {
            this.herdings = [...this.initialHerdings];
            this.herdingErrors = this.herdings.map(() => ({}));
            this.totalHours = this.herdings.map(() => ({}));
        }
        if (this.herdings && this.herdings.length === 0 && this.isCreatingNew) {
            this.addHerding();
        }
    },
}
</script>

