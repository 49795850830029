<template>
    <div>
            <v-tooltip max-width="520" open-delay="120" top style="background-color: #d32f2f" >
                <template v-slot:activator="{ on }">
                    <div v-if="$slots.content" @click.stop="showWarningModel()" v-on="on"><slot name="content"></slot></div>
                    <v-btn v-else color="gray" v-on="on" icon small :style="iconStyle" @click.stop="showWarningModel()" :class="$slots.icon ? 'mt-0 mb-1' : 'my-0 ml-n2 mr-n2'" style="cursor: default">
                        <template v-if="$slots.icon"><slot name="icon"></slot></template>
                        <template v-else><v-icon style="font-size: 20px">help_outline</v-icon></template>
                    </v-btn>
                </template>
                <span><slot></slot></span>
            </v-tooltip>

            <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="showModal" max-width="550">
                <v-card>
                    <v-card-title class="text-h5 justify-center text-center mb-4">
                        {{ title }}
                    </v-card-title>
                    <v-card-text class="text-md-center">
                        <slot></slot>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" outlined @click.native="showModal = false" title="Sulge">
                            <v-icon color="gray">close</v-icon>
                            Sulge
                        </v-btn>
                        <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </div>
</template>
<script>
export default {
    props: {
        title: { type: String },
        iconStyle: { type: String },
    },
    data: () => ({
        showModal: false,
    }),
    computed: {
    },
    methods: {
        showWarningModel() {
            this.showModal = true;
        },
    }
}
</script>