<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialogModel" absolute max-width="600px">
        <template v-slot:activator="{ on }">
<!--            <v-btn v-if="$vuetify.breakpoint.smAndDown" color="primary"-->
            <template>
            <v-btn color="primary"
                   v-on="on"
                   elevation="0"
                   class="px-5 mr-1 mt-6 mt-sm-2 smallManageButton v-btn--absolute"
                   style="right: 20px;top:6px;z-index: 2;"
            >
                <v-icon>access_alarm</v-icon>Lisa Tööaeg
            </v-btn>
            <div :style="$vuetify.breakpoint.smAndDown ? 'height: 60px' : ''"></div>
            </template>
        </template>
        <work-time-main-page v-if="dialogModel" @close-modal="dialogModel = false"></work-time-main-page>
    </v-dialog>
</template>
<script>

    export default {
        data: () => ({
            dialogModel: false,
        }),
        components: {
            WorkTimeMainPage: () => import('./workTimeMainPage')
        },
        methods: {
        },
        created() {
        },
        mounted() {
            window.scrollTo(0, 0);
            this.yearModel = parseInt(this.$route.params.year)
            // this.loadData();
        },
        computed: {
        },
        watch: {
        },
    }
</script>