<template>
    <div v-if="hasSafety || hasWorkDelay || hasHarvestDelay || hasUsageTimes || hasSafetyArea || hasSafetyWaterArea || hasWarning || hasImage"
         :style="$vuetify.breakpoint.smAndUp ? 'margin-bottom:-4px; margin-top:0' : 'margin-bottom:5px; margin-top:0px'">
        <div v-if="hasImage">
            <v-tooltip top v-for="(file) in filesWithThumbnails"  :key="file.thumbnail">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="primary" icon small @click="openImageDialog(file.thumbnail)">
                        <v-icon color="gray darken-2">
                            {{ mdiCameraOutline() }}
                        </v-icon>
                    </v-btn>
                </template>
                <span>Lisatud pilt</span>
            </v-tooltip>
        </div>
        <v-tooltip top v-if="hasSafety">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small
                       @click="showSafetyTextModal('Ohutuskirje', 'error_outline', event.requisite.safetyRecord)">
                    <v-icon color="orange darken-2">
                        error_outline
                    </v-icon>
                </v-btn>
            </template>
            <span>Ohutuskirje</span>
        </v-tooltip>

        <v-tooltip top v-if="hasWorkDelay">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small
                       @click="showSafetyTextModal('Tööoode', 'timer', 'tööoode ' + event.requisite.workDelayDays + ' päeva')">
                    <v-icon color="orange darken-2">
                        {{ mdiProgressClock() }}
                    </v-icon>
                </v-btn>
            </template>
            <span>Tööoode {{event.requisite.workDelayDays}} päeva</span>
        </v-tooltip>

        <v-tooltip top v-if="hasHarvestDelay">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small
                       @click="showSafetyTextModal('Ooteaeg', 'update', 'ooteaeg ' + event.requisite.harvestDelayDays+ ' päeva')">
                    <v-icon color="orange darken-2">
                        update
                    </v-icon>
                </v-btn>
            </template>
            <span>Ooteaeg {{event.requisite.harvestDelayDays}} päeva</span>
        </v-tooltip>

        <v-tooltip top v-if="hasUsageTimes">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small
                       @click="showSafetyTextModal('Kasutuskordi', 'rotate_right', 'lubatud kasutuskordi ' + event.requisite.usageTimes)">
                    <v-icon color="orange darken-2">
                        rotate_right
                    </v-icon>
                </v-btn>
            </template>
            <span>Kasutuskordi {{event.requisite.usageTimes}}</span>
        </v-tooltip>

        <v-tooltip top v-if="hasSafetyArea">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small
                       @click="showSafetyTextModal('Ohutusala', 'remove_circle_outline', 'ohutusala ' + event.requisite.safetyArea + 'm')">
                    <v-icon color="orange darken-2">
                        remove_circle_outline
                    </v-icon>
                </v-btn>
            </template>
            <span>Ohutusala {{event.requisite.safetyArea}}m</span>
        </v-tooltip>


        <v-tooltip top v-if="hasSafetyWaterArea">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small
                       @click="showSafetyTextModal('Ohutusala veepiirist', 'waves', 'ohutusala veepiirist ' + event.requisite.safetyWaterArea + 'm')">
                    <v-icon color="orange darken-2">
                        waves
                    </v-icon>
                </v-btn>
            </template>
            <span>Ohutusala veepiirist {{event.requisite.safetyWaterArea}}m</span>
        </v-tooltip>


        <v-tooltip top :key="warning.targetId + warning.type.key+index" v-for="(warning, index) in warnings">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" v-if="hasWarning" icon small @click="showWarningModel(warning)">
                    <v-icon :color="warningIconColor(warning.type)">{{warningIcon(warning.type)}}</v-icon>
                </v-btn>
            </template>
            <span>{{warning.title}}</span>
        </v-tooltip>

        <v-dialog v-model="shouldShowWarningModal" max-width="450">
            <v-card>
                <v-card-title class="text-h5 justify-center text-center mb-4">
                    <v-icon style="margin-right:5px; margin-bottom:-1px" color="black">{{safetyModal.icon}}</v-icon>
                    {{safetyModal.title}}
                </v-card-title>
                <v-card-text class="text-md-center">{{safetyModal.text}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click.native="shouldShowWarningModal = false" title="Sulge">
                        <v-icon color="gray">close</v-icon>
                        Sulge
                    </v-btn>
                    <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="shouldShowViolationWarningModal" max-width="600">
            <v-card>
                <v-card-title class="text-h5 justify-center text-center mb-4 pt-8">
                    <v-icon large style="margin-right:5px; margin-bottom:-1px"
                            :color="warningToShow && warningIconColor(warningToShow.type)">{{warningToShow &&
                        warningIcon(warningToShow.type)}}
                    </v-icon>
                    {{warningToShow && warningToShow.title}}
                </v-card-title>
                <v-card-text v-if="warningToShow" class="text-md-center" v-html="warningToShow && warningToShow.message"></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click.native="shouldShowViolationWarningModal = false" title="Sulge">
                        <v-icon color="gray">close</v-icon>
                        Sulge
                    </v-btn>
                    <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="imageDialog"
            width="100%"
        >
            <v-btn color="white" elevation="0" :small="!$vuetify.breakpoint.mdAndUp" right fab absolute
                   class="closeButton mt-6 mr-6" style="box-shadow: 0 0 40px rgb(0, 0, 0) !important"
                   @click="imageDialog = false">
                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 50 : 30" style="color: #31a80b">{{ mdiClose() }}</v-icon>
            </v-btn>
            <v-img :src="dialogImageUrl" class="white--text" @click="imageDialog = false">
                <v-row class="fill-height" align="center" justify="center">
                    <v-icon size="56">mdi-close</v-icon>
                </v-row>
            </v-img>
        </v-dialog>
    </div>
</template>
<script>
    import {warningIcon, warningIconColor} from "../../../dist/common/warningVisuals.js"
    import _ from "lodash";
    import {mdiCameraOutline, mdiClose, mdiProgressClock} from "@mdi/js";
    import {VBtn} from "vuetify/lib";

    export default {
        components: {VBtn},
        props: {
            event: {
                type: Object
            }
        },
        data: () => ({
            safetyModal: {},
            shouldShowWarningModal: false,
            shouldShowViolationWarningModal: false,
            warningToShow: null,
            imageDialog: null,
            dialogImageUrl: ''
        }),
        computed: {
            workers() {
                return this.$store.getters.getWorkers
            },
            hasWarning() {
                let warnings = this.$store.getters.getWarnings;
                return _.some(warnings, warning => warning.targetId === this.event._id)
            },
            warnings() {
                let warnings = this.$store.getters.getWarnings;
                return _.filter(warnings, warning => warning.targetId === this.event._id);
            },
            hasSafety() {
                return this.event.requisite && this.event.requisite.safetyRecord;
            },
            hasWorkDelay() {
                return this.event.requisite && this.event.requisite.workDelayDays !== null && this.event.requisite.workDelayDays !== undefined && this.event.requisite.workDelayDays > 0;
            },
            hasHarvestDelay() {
                return this.event.requisite && this.event.requisite.harvestDelayDays !== null && this.event.requisite.harvestDelayDays !== undefined && this.event.requisite.harvestDelayDays > 0;
            },
            hasUsageTimes() {
                return this.event.requisite && this.event.requisite.usageTimes !== null && this.event.requisite.usageTimes !== undefined && this.event.requisite.usageTimes > 0;
            },
            hasSafetyArea() {
                return this.event.requisite && this.event.requisite.safetyArea !== null && this.event.requisite.safetyArea !== undefined && this.event.requisite.safetyArea > 0;
            },
            hasSafetyWaterArea() {
                return this.event.requisite && this.event.requisite.safetyWaterArea !== null && this.event.requisite.safetyWaterArea !== undefined && this.event.requisite.safetyWaterArea > 0;
            },
            hasImage() {
                return this.event.images && this.event.images.length > 0;
            },
            safetyText() {
                return this.requisite.safetyRecord;
            },
            filesWithThumbnails(){
                return this.event.images.map((fileId) => {
                    let imageUrl = `https://s3.eu-north-1.amazonaws.com/ee.abimasin.photo/polluraamat/${fileId}.webp`;
                    if (window.location.hostname === 'localhost') {
                        imageUrl = `https://s3.eu-north-1.amazonaws.com/ee.abimasin.photo/polluraamat/_test_${fileId}.webp`;
                    }
                    return {
                        file: null,
                        thumbnail: imageUrl
                    };
                });
            }
        },
        methods: {
            mdiClose() {
                return mdiClose
            },
            mdiProgressClock() {
                return mdiProgressClock
            },
            mdiCameraOutline() {
                return mdiCameraOutline
            },
            showSafetyTextModal(title, icon, text) {
                this.shouldShowWarningModal = true;
                this.safetyModal = {
                    title: title,
                    icon: icon,
                    text: icon === 'error_outline' ? text : 'Tarviku ' + text
                }
            },
            showWarningModel(warning) {
                this.shouldShowViolationWarningModal = true;
                this.warningToShow = warning;
            },
            warningIcon(type) {
                return warningIcon(type)
            },
            warningIconColor(type) {
                return warningIconColor(type)
            },
            openImageDialog(imageUrl) {
                this.dialogImageUrl = imageUrl;
                this.imageDialog = true;
            },
        }
    }
</script>