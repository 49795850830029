<template>
    <v-main class="grey lighten-4">
        <v-container class="mt-sm-n12 ma-n0 pa-0" :class="$vuetify.breakpoint.smAndUp ? 'fill-height' : ''" fluid>
            <v-row class="text-center justify-center align-center" no-gutters>
                <v-col>
                    <v-container :class="{'pa-0': $vuetify.breakpoint.smAndDown, 'mt-6': $vuetify.breakpoint.mdAndUp}"
                                 :style="{maxWidth: '490px', position: 'relative'}">
                        <v-card class="px-md-6">
                            <v-container>
                                <v-col>
                                    <div class="pt-4">
                                        <router-link :to="{ name: 'welcome'}">
                                            <img src="/static/img/logo.svg" width="237" height="105"
                                                 style="margin-right:10px;margin-bottom:10px;filter: drop-shadow(0px 0px 3px rgba(88, 255, 53, 0.3));"/>
                                        </router-link>
                                    </div>
                                    <h1 class="regForm">Keskkonda sisenemine</h1>
                                    <v-form ref="form" v-model="valid" lazy-validation>
                                        <v-text-field autofocus ref="email" prepend-icon="person"
                                                      label="E-posti aadress"
                                                      data-cy="login-username-input"
                                                      v-model="email" validate-on-blur :rules="[
                v => !!v && v.length > 6 || 'Kohustuslik väli',
                v => /.+@.+/.test(v) || 'Sisestage korrektne e-posti aadress'
                ]"></v-text-field>
                                        <v-text-field ref="password" prepend-icon="lock" label="Salasõna"
                                                      :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                                                      :type="showPassword ? 'text' : 'password'"
                                                      @click:append="showPassword = !showPassword"
                                                      data-cy="login-password-input"
                                                      v-model="password" @keyup.enter="login" validate-on-blur
                                                      :rules="[() => password.length > 1 || 'Kohustuslik väli',
                              () => password.length > 7 || 'Salasõna liiga lühike']"></v-text-field>
                                        <v-checkbox color="primary" v-model="checkbox" label="Jäta meelde"></v-checkbox>
                                        <div v-if="incorrectLogin" class="loginErrorMessage">Sellist kasutajat ei
                                            eksisteeri või
                                            vale salasõna!
                                        </div>

                                        <v-btn primary color="primary" large block class="ml-0 mb-3" @click="login">
                                            Sisene
                                        </v-btn>
                                    </v-form>

                                    <router-link :to="{ name: 'resetPassword'}">Unustasin parooli</router-link>
                                    -
                                    <router-link :to="{ name: 'registration'}">Registreeri uus konto</router-link>
                                </v-col>
                            </v-container>
                        </v-card>
                    </v-container>
                </v-col>
                <v-snackbar :color="messageBarColor" :transition="isErrorMessage ? 'shake-error' : 'v-snack-transition'" v-model="messageBar" top :timeout="-1">
                    <v-row class="font-weight-bold justify-center align-center">
                        <v-icon color="white" class="mx-3">{{messageBarIcon}}</v-icon>
                        {{ messageBarText }}
                        <v-icon v-if="isErrorMessage" color="white" class="mx-3">{{messageBarIcon}}</v-icon>
                    </v-row>
                </v-snackbar>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>

    export default {
        //Variables
        data: () => ({
            valid: true,
            email: '',
            password: '',
            checkbox: false,
            incorrectLogin: false,
            showPassword: false,
        }),
        computed: {
            form() {
                return {
                    email: this.email,
                    password: this.password,
                }
            },
            messageBar() {
                return this.$store.getters.hasSystemMessage;
            },
            messageBarText() {
                return this.$store.getters.getSystemMessageText;
            },
            messageBarIcon() {
                return this.isErrorMessage ? 'error_outline' : 'check_circle';
            },
            messageBarColor() {
                return this.isErrorMessage ? 'error' : 'default';
            },
            isErrorMessage() {
                return this.$store.getters.getSystemMessageType === "ERROR";
            },
        },
        methods: {
            login: function () {
                this.incorrectLogin = false;
                if (this.$refs.form.validate()) {
                    const {email, password, checkbox} = this
                    const credentials = {
                        email: email,
                        password: password,
                        remember: checkbox
                    };
                    this.$store.dispatch("login", credentials).then(() => {
                        if (this.$route.path !== '/lisa') {
                            this.$router.push('/lisa');
                        }
                    }).catch((err) => {
                        if (err.response && (err.response.status === 400 || err.response.status === 403)) {
                            this.incorrectLogin = true;
                        } else {
                            this.$store.dispatch('setSystemMessage', {text: "Puudub internetiühendus või tekkis tõrge!"});
                        }
                    })
                }
            }
        }
    };
</script>