<template>
    <!-- Add Dialog -->
    <v-card>
        <v-card-title v-if="$vuetify.breakpoint.mdAndUp || fieldSpecificJobs" class="grey lighten-4 text-h6">
            <v-btn v-if="fieldSpecificJobs" color="primary" elevation="0" small right fab absolute class="closeButton" data-cy="close-modal-button"
                   @click="$emit('closeModal')">
                <v-icon>close</v-icon>
            </v-btn>
            {{title}}
        </v-card-title>
        <work-time-modal v-if="showWorkTime"></work-time-modal>
        <modal-container-wrapper :class="fieldSpecificJobs || $vuetify.breakpoint.mdAndUp ? '' : 'mt-n6'">
            <v-row no-gutters>
                <v-col>
                    <v-row no-gutters>
                        <v-col>
                            <v-combobox multiple
                                        v-model="fieldModel"
                                        :items="fields"
                                        :label="fieldModel && fieldModel.length > 1 ? 'Põllud': 'Põld'"
                                        :item-text="fieldNameByYear"
                                        chips
                                        clearable
                                        :ripple="false"
                                        auto-select-first
                                        id="fieldDropdownId"
                                        @update:search-input=customSortFields
                                        :attach="$vuetify.breakpoint.smAndDown"
                                        :menu-props="{closeOnContentClick: shouldKeepFieldSelectOpen}"
                                        ref="fieldModel"
                                        v-on:click:append="() => provideCloseDropdown(this.$refs.fieldModel)"
                                        :disabled="fieldSelectShouldBeLocked"
                                        :error-messages="fieldErrors"
                                        data-cy="jobevent-field-select"
                            >
                                <template v-if="!fieldSelectShouldBeLocked && $vuetify.breakpoint.smAndUp" v-slot:append-outer>
                                    <additional-info title="Põldude valik" class="align-self-center">
                                        Korraga on võimalik valida ka <strong>rohkem kui 1 põld</strong>. Selleks tuleb peale
                                        ühe põllu valimist avada põldude valik uuesti ja valida ka teised soovitud põllud.
                                    </additional-info>
                                </template>
                                <template v-slot:no-data>
                                    <v-col class="mx-4" v-if="fields && fields.length > 0">
                                        Sellise nimega põldu ei eksisteeri. Lisage põld <router-link :to="{ name: 'pollud', params: currentYear}">põlluraamatusse</router-link>.
                                    </v-col>
                                    <v-col class="mx-4" v-else>
                                        Esmalt lisage vähemalt üks põld enda <router-link :to="{ name: 'pollud', params: currentYear}">põlluraamatusse</router-link>.
                                    </v-col>
                                </template>
                                <template v-slot:selection="data">
                                    <v-tooltip top :key="'tooltip' + data.item._id+ cropName(data.item)">
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="cropName(data.item) ? on : null"
                                                color="white"
                                                :input-value="data.selected"
                                                :key="JSON.stringify(data.item)"
                                                :close="!fieldSelectShouldBeLocked"
                                                class="chip--select-multi"
                                                :style="cropColor(data.item) !== 'transparent' ? 'padding-left:8px; border-left: 10px solid ' + cropColor(data.item) + ' !important' : ''"
                                                @click:close="data.parent.selectItem(data.item)"
                                            >
                                                {{ fieldNameByYear(data.item) }}
                                            </v-chip>
                                        </template>
                                        <span>{{ cropName(data.item) }}</span>
                                    </v-tooltip>

                                </template>
                                <template v-slot:item="data">
                                    <v-list-item-action :style="$vuetify.breakpoint.smAndUp? 'min-width: 38px' : 'min-width: 18px'" class="mr-2 mr-md-3" :key="'checkbox'+data.item._id">
                                        <v-row no-gutters class="justify-end">
                                            <v-checkbox color="primary" v-model="data.attrs.inputValue"/>
                                            <div class="fieldColor" :style="'background-color: ' + cropColor(data.item)"></div>
                                        </v-row>
                                    </v-list-item-action>
                                    <v-list-item-content class="pa-0" :key="'checkbox-content'+data.item._id">
                                        <v-list-item-title>
                                            <v-row no-gutters class="align-center">
                                                <v-col class="align-center overflow-ellipsis">
                                                    {{ fieldNameByYear(data.item) }}
                                                </v-col>
                                                <v-chip
                                                    color="primary elevation-0"
                                                    v-if="cropColor(data.item) !== 'transparent'"
                                                    class="chip--select-multi fieldTag float-rights pr-2"
                                                    style="max-width: 40%"
                                                    @input="data.parent.selectItem(data.item)"
                                                    @click.native.stop="selectFieldsByCrop(cropName(data.item))"
                                                >
                                                    <div class="overflow-ellipsis" v-if="$vuetify.breakpoint.smAndUp">
                                                    {{ cropName(data.item) }}
                                                    </div>
                                                    <div v-else class>
                                                        <v-icon class="ml-n1">{{
                                                                mdiTableRowPlusBefore()
                                                            }}</v-icon>
                                                    </div>
                                                </v-chip>
                                            </v-row>

                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>

                            </v-combobox>
                        </v-col>
                        <v-col class="col-12 col-sm-auto ml-0 ml-sm-6 align-content-center mb-4 mb-sm-0">
                            <v-btn color="primary" block outlined elevation="0" @click="showMapModel = true" :class="$vuetify.breakpoint.xs ? 'pa-0 ma-0' : null">
                                {{ isCreatingNew() && !fieldSelectShouldBeLocked ? 'Vali kaardilt' : 'Vaata kaardil' }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-3'">
                            <v-text-field
                                    prepend-icon="layers"
                                    v-model="areaModel"
                                    :label="areaLabel"
                                    type="number"
                                    ref="fieldArea"
                                    :rules="[ v => !!this.$refs.fieldArea && !this.$refs.fieldArea.badInput || this.$refs.fieldArea===undefined || ('Number ei ole korrektne')]"
                                    :disabled="hasMoreThanOneField()"
                                    suffix="ha"
                                    :style="$vuetify.breakpoint.mdAndUp && 'margin-right: 20px'"
                                    :class="{ 'custom-notification': !!areaReductionMessage }"
                                    :error-messages="areaErrors"
                                    :messages="areaReductionMessage ? [areaReductionMessage] : null"
                                    data-cy="jobevent-area-input"
                                    :clear-icon="mdiUndoVariant()"
                                    :append-icon="areaReductionMessage && areaWasAutomaticallySet && !hasMoreThanOneField() ? mdiUndoVariant() : null"
                                    @click:append="resetAreaWithoutReduction"
                            ></v-text-field>
                        </v-col>
                        <v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-3 pr-2'">
                            <v-select
                                :prepend-icon="mdiProgressCheck()"
                                :items="jobStatusOptions"
                                v-model="jobStatusModel"
                                item-text="label"
                                item-value="value"
                                label="Staatus"
                                class="job-status-select"
                                data-cy="jobevent-status-select"
                            >
                                <template #selection="{item}">
                                    <v-chip
                                        style="width: 100%; border-width: 2px !important"
                                        class="mt-1 mb-0 mx-0 pl-2 pr-1 job-status-chip "
                                        outlined
                                        :color="item.value === 'DONE' ? 'primary' : (item.value === 'PLANNED' ? 'deep-purple lighten-1' : 'light-blue darken-2')"
                                        small
                                    >
                                        <div class="overflow-ellipsis">
                                            {{item.label.toUpperCase()}}
                                        </div>
                                    </v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col :class="$vuetify.breakpoint.smAndDown  ? 'col-6' : 'col-3 pl-2'">
                            <v-menu
                                ref="jobDateStart"
                                :close-on-content-click="false"
                                v-model="jobDateStart"
                                nudge-right=32
                                nudge-top=20
                                :return-value.sync="dateStart"
                                transition="scale-transition"
                                min-width="290px"
                                offset-y
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-on="on"
                                        v-model="computedDateStartFormatted"
                                        :label="$vuetify.breakpoint.smAndDown ? 'Algus' : 'Tegevuse algus'"
                                        :prepend-icon="mdiCalendarToday()"
                                        readonly
                                        data-cy="jobevent-date-start-input"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dateStart"
                                    first-day-of-week=1
                                    color="primary"
                                    no-title
                                    @input="$refs.jobDateStart.save(dateStart)"
                                    locale="et-ee"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :class="$vuetify.breakpoint.smAndDown  ? 'col-6' : 'col-3 pl-2'">
                            <v-menu
                                ref="jobDateEnd"
                                :close-on-content-click="false"
                                v-model="jobDateEnd"
                                nudge-right=32
                                :disabled="jobStatusModel === 'IN_PROGRESS'"
                                nudge-top=20
                                :return-value.sync="dateEnd"
                                transition="scale-transition"
                                min-width="290px"
                                offset-y
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-on="on"
                                        v-model="computedDateEndFormatted"
                                        :label="jobStatusModel === 'IN_PROGRESS' ? '...' : ($vuetify.breakpoint.smAndDown ? 'Lõpp' : 'Tegevuse lõpp')"
                                        :prepend-icon="mdiCalendar()"
                                        readonly
                                        :disabled="jobStatusModel === 'IN_PROGRESS'"
                                        data-cy="jobevent-date-end-input"
                                        :error-messages="dateEndErrors"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dateEnd"
                                    first-day-of-week=1
                                    color="primary"
                                    :min="dateStart"
                                    no-title
                                    @input="$refs.jobDateEnd.save(dateEnd)"
                                    locale="et-ee"
                                >
                                    <v-row no-gutters class="mt-n2 flex">
                                        <v-btn block color="primary" @click="() => {jobDateEnd = false; $nextTick(() => jobStatusModel = 'IN_PROGRESS')}">Töö on veel tegemisel</v-btn>
                                    </v-row>
                                </v-date-picker>
                            </v-menu>



                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-select
                                :prepend-icon="mdiFolderWrench()"
                                :items="jobCategories"
                                v-model="jobCategoryModel"
                                label="Töö kategooria"
                                ref="jobCategoryModel"
                                no-data-text="Palun muutke mõned kategooriad nähtavaks"
                                :error-messages="jobCategoryErrors"
                                data-cy="jobevent-job-category"
                        >
                            <template v-slot:append-item>
                                <v-row no-gutters class="pr-5 pr-sm-auto my-2 px-sm-4">
                                    <v-btn
                                        color="primary"
                                        class="mb-2"
                                        block
                                        data-cy="navigate-to-job-category-management"
                                        @click.stop="$router.push({name:'categories'})">
                                        <v-icon class="mr-2">{{ mdiFolderWrenchOutline() }}</v-icon>
                                        Töötüüpide haldamine
                                    </v-btn>
                                </v-row>
                            </template>
                        </v-select>
                    </v-row>
                    <v-row no-gutters v-if="requiresJobSelection">
                        <v-select
                                :prepend-icon="mdiWrench()"
                                :disabled="showJobTasks"
                                :items="jobs"
                                item-text="name"
                                v-model="jobModel"
                                label="Töö nimetus"
                                return-object
                                ref="jobModel"
                                no-data-text="Palun muutke mõned tööd nähtavaks"
                                :error-messages="jobErrors"
                                data-cy="jobevent-job-type"
                        >
                            <template #append-outer>
                                <v-tooltip v-if="priaEnabled && jobModel && jobModel.priaSupport" top :key="'tooltip' + jobModel.name" class="justify-end">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: gray" @click.stop>{{ICONS.PRIA_CHECK}}</v-icon>
                                    </template>
                                    <span>Seda tüüpi töid on võimalik soovi korral PRIAsse saata</span>
                                </v-tooltip>
                            </template>
                            <template #item="{item}">
                                <v-list-item-content>
                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.hint" style="overflow: auto; text-overflow: initial; white-space: initial;">{{ item.hint }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-tooltip v-if="priaEnabled && item.priaSupport" top :key="'tooltip' + item.name">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: gray" class="ml-1 mr-0 mr-md-4" @click.stop>{{ICONS.PRIA_CHECK}}</v-icon>
                                    </template>
                                    <span>Seda tüüpi töid on võimalik soovi korral PRIAsse saata</span>
                                </v-tooltip>
                            </template>
                            <template #append-item>
                                <v-row no-gutters class="pr-5 pr-sm-auto my-2 px-sm-4">
                                    <v-btn
                                        color="primary"
                                        class="mb-2"
                                        block
                                        data-cy="navigate-to-job-category-management"
                                        @click.stop="$router.push({name:'categories'})">
                                        <v-icon class="mr-2">{{ mdiFolderWrenchOutline() }}</v-icon>
                                        Töötüüpide haldamine
                                    </v-btn>
                                </v-row>
                            </template>
                        </v-select>
                    </v-row>

                    <div v-if="requiresJobVariant">
                        <job-variant
                            ref="jobVariantComponent"
                            :jobCategoryModel="jobCategoryModel"
                            :jobModel="jobModel"
                            v-bind:initialJobVariant.sync="jobVariantModel"
                            @validate-job-variant="validateJobVariant"
                        />
                    </div>
                    <div v-if="requiresLandImprovementAmountAndUnit">
                        <land-impovement
                            ref="landImprovementComponent"
                            v-bind:initialAmount.sync="jobAmountModel"
                            v-bind:initialUnit.sync="jobUnitModel"
                            :jobModel="jobModel"
                            :isCreatingNew="isCreatingNew()"
                            @validate-land-improvement="validateLandImprovement"
                        />
                    </div>
                    <div v-if="requiresManureAmount">
                        <manure-amount
                            ref="manureAmountComponent"
                            v-bind:initialAmount.sync="jobAmountModel"
                            v-bind:initialUnit.sync="jobUnitModel"
                            :jobModel="jobModel"
                            :isCreatingNew="isCreatingNew()"
                            @validate-manure="validateManure"
                        />
                    </div>
                    <div v-if="requiresHerding">
                        <herding-component
                            ref="herdingComponent"
                            v-bind:initialHerdings.sync="herdingsModel"
                            :isCreatingNew="isCreatingNew()"
                            :dateStart="dateStart"
                            :dateEnd="dateEnd"
                            :area="areaModel ? areaModel : 0"
                            @validate-herdings="validateHerding"
                        />
                    </div>
                    <div v-if="requiresRequisite" class="mb-4">
                        <v-row no-gutters class="requisitesSpacing" v-for="(requisiteBlock, index) in requsiteBlocks" :key="'requisiteBlock' + index">
                            <requisite-component :ref="requisiteBlock"
                                                 class="requisites"
                                                 :style="requisiteBlockStyles"
                                                 :requisiteBlock=requisiteBlock
                                                 :insideModal=fieldSpecificJobs
                                                 :jobRequirements=jobRequirements
                                                 :isCreatingNewProperty="isCreatingNew()"
                                                 :area="areaModel === '' ? 0 : areaModel"
                                                 :addRequisite="addAdditionalRequisite"
                                                 v-bind.sync="requisiteBlock"
                                                 v-on:inspections="(inspections) => updateInspection(inspections, index)"
                                                 v-on:type="(type) => updateType(type, index)"
                                                 v-on:subtype="(subtype) => updateSubtype(subtype, index)"
                                                 v-on:requisiteForWarningCheck="(requisite) => updateRequisiteForWarningChecking(requisite, index)"
                                                 :removeRequisite="() => removeRequisite(index)">
                            </requisite-component>
                        </v-row>
                        <v-row v-if="isCreatingNew() || (requsiteBlocks && requsiteBlocks.length === 0)" no-gutters class="mb-6">
                            <v-col cols="12" class="text-center">
                                <v-btn color="primary" dark large
                                       elevation="0"
                                       @click.native.stop="addAdditionalRequisite"
                                        data-cy="jobevent-add-requisite-button">
                                    <v-icon>add</v-icon>
                                    Lisa tarvik
                                    <v-icon class="ml-2">{{ mdiPackageVariantClosed() }}</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>


                    <v-row no-gutters v-if="requiresInspections && requsiteBlocks && requsiteBlocks.length > 0">
                        <inspections-component
                            ref="inspectionsComponent"
                            v-bind:initialInspections.sync="inspectionsModel"
                            :requisiteManagementMode="false"
                            :isCreatingNew="isCreatingNew()"
                            :chemicalSubtype="null"
                            @validate-inspections="validateInspections"
                        />
                    </v-row>
                    <v-row no-gutters v-if="requiresFertilizationInfo">
                        <v-checkbox
                            v-model="fertilizationFertilizationBeforeSow"
                            label="Väetise paisklaotus külvi eel"
                            :attach="$vuetify.breakpoint.smAndDown"
                            data-cy="jobevent-requisite-fertilization-before-sow-input"

                        >
                            <template v-slot:append>
                                <additional-info title="03.10.2019.a määruse nr 45 lisa 2">
                                    Väetiste paisklaotamise (hajuskülvi) kasutamisel külvi eel võib suurendada lämmastiku kogust 15-20kg. Keskkonnaministri 03.10.2019.a määruse nr 45 lisa 2 alusel.
                                </additional-info>
                            </template>

                        </v-checkbox>
                    </v-row>
                    <v-row no-gutters v-if="requiresFertilizationInfo" style="min-height: 70px">
                        <v-col cols="12" md="5">
                            <div style="display: flex">
                            <v-checkbox
                                v-model="fertilizationHighNutrientNModel"
                                label="Suur fosfori kogus"
                                :attach="$vuetify.breakpoint.smAndDown"
                                data-cy="jobevent-requisite-fertilization-high-nutrient-input"
                            >
                                <template v-slot:append>
                                    <additional-info title="VeeS §161 lõige 9 erisuse kasutamine">
                                        Märkida juhul, kui mullas taimedele omistatava fosfori tarve on suur või väga
                                        suur (VeeS §161 lõige 9 erisuse kasutamine).<br/>
                                        Sõnnikuga tohib aastas anda fosforit <strong>aastas 25kg/ha</strong>, aga kui
                                        mullas on taimede fosfori tarve suur ja selle tõendamiseks <strong>on võetud
                                        mullaproov
                                        viie aasta jooksul</strong>, siis võib fosfori kogust suurendada.
                                    </additional-info>
                                </template>
                            </v-checkbox>
                            </div>
                        </v-col>
                        <v-col v-if="fertilizationHighNutrientNModel" cols="12" md="4">
                            <v-text-field
                                :prepend-icon="mdiShapePolygonPlus()"
                                v-model="fertilizationNutrientNModel"
                                type="number"
                                ref="fertilizationNutrientNModel"
                                :rules="[
                                        v => !!v && this.$refs.fertilizationNutrientNModel && !this.$refs.fertilizationNutrientNModel.badInput || ('Number ei ole korrektne'),
                                     ]"
                                data-cy="jobevent-requisite-fertilization-nutrient-input"
                                suffix="mg/kg"
                                :error-messages="fertilizationNutrientNErrors"
                                label="Fosfori sisaldus"></v-text-field>
                        </v-col>
                        <v-col v-if="fertilizationHighNutrientNModel" cols="12" md="3" class="pl-0 pl-md-4">
                            <v-select
                                :prepend-icon="$vuetify.breakpoint.smAndDown ? mdiCalendarBadgeOutline() : null"
                                :items="lastFiveYears"
                                v-model="fertilizationSoilSampleYearModel"
                                :error-messages="fertilizationSoilSampleYearErrors"
                                no-data-text="Pole ühtegi seotud kasutajat"
                                data-cy="jobevent-requisite-fertilization-soil-sample-year-input"
                                label="Mullaproovi aasta"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="align-left" v-if="shouldShowCropYield">
                        <v-col class="col-12 pr-0">
                            <v-select
                                :items="yieldTypes"
                                :prepend-icon="mdiBarley()"
                                v-model="mainCropYieldTypeWithDefaultValue"
                                label="Saagi tüüp"
                                data-cy="jobevent-crop-yield-type-input"
                            >
                            </v-select>
                        </v-col>
                        <v-col class="flex-grow-1 col-8 col-sm-4 col-md-4 pr-0 pr-md-2">
                            <v-text-field
                                :prepend-icon="mdiStackOverflow()"
                                v-model="cropYieldModel"
                                label="Kogu saak"
                                type="number"
                                ref="cropYieldModel"
                                :rules="[
                                    v => !!v && this.$refs.cropYieldModel && !this.$refs.cropYieldModel.badInput || ('Number ei ole korrektne'),
                                 ]"
                                :error-messages="cropYieldErrors"
                                data-cy="jobevent-crop-yield-input"
                            ></v-text-field>
                        </v-col>
                        <v-col class="flex-grow-1 col-4 col-sm-2 col-md-2 pr-0 pr-sm-2">
                            <v-select
                                :items="yieldUnits"
                                v-model="cropYieldUnitsModel"
                                label="Ühik"
                                ref="yieldUnitInput"
                                data-cy="crop-yield-unit-input"
                            >

                            </v-select>
                        </v-col>
                        <v-col class="flex-grow-1 col-12 col-sm-6 col-md-4">
                            <v-text-field
                                :prepend-icon="mdiEqual()"
                                v-model="cropYieldPerHectareModel"
                                :label="'Saagikus'"
                                :suffix="cropYieldUnitsModel ? cropYieldUnitsModel + '/ha' : 't/ha'"
                                type="number"
                                ref="cropYieldModel"
                                :rules="[
                                    v => !!v && this.$refs.cropYieldModel && !this.$refs.cropYieldModel.badInput || ('Number ei ole korrektne'),
                                 ]"
                                :error-messages="cropYieldErrors"
                                data-cy="jobevent-crop-yield-per-hectare-input"
                            >
                                <template v-slot:append-outer>
                                    <additional-info title="Saagikuse arvutamine">
                                        Kui korraga on valitud mitu põldu, siis jagatakse saak kogu põldude ühisele pindalale.
                                        Arvulise ühikuga saagi puhul (näiteks silopallid), jagatakse saak põllu pindalade põhjal
                                        proportsionaalselt.
                                    </additional-info>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col class="flex-grow-1 col-12 col-sm-6 pr-0 pr-sm-2" v-if="shouldShowCropWeightOrWeightExists">
                            <v-text-field
                                prepend-icon="network_check"
                                v-model="cropYieldUnitWeightModel"
                                :label="'Saagiühiku ligikaudne kaal'"
                                suffix="t"
                                type="number"
                                ref="cropYieldUnitWeightModel"
                                :error-messages="cropYieldUnitWeightModelErrors"
                                :rules="[v => !!this.$refs.cropYieldUnitWeightModel && !this.$refs.cropYieldUnitWeightModel.badInput || this.$refs.cropYieldUnitWeightModel===undefined || ('Number ei ole korrektne')]"
                                data-cy="jobevent-crop-yield-weight-input"
                            ></v-text-field>
                        </v-col>
                        <v-col class="flex-grow-1 col-12 col-sm-6 col-md-4" v-if="shouldShowCropWeightOrWeightExists">
                            <v-text-field
                                :prepend-icon="mdiEqual()"
                                disabled
                                :value="cropYieldTotalComputedWeight"
                                label="Kaal kokku"
                                suffix="t"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="requiresNote">
                        <v-col>
                            <v-text-field
                                    :prepend-icon="mdiTextBoxEditOutline()"
                                    v-model="notesModel"
                                    label="Märkmed"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="align-left" v-if="requiresWorkerName">
                        <v-col class="col-12 col-sm-5">
                            <v-select
                                    prepend-icon="account_circle"
                                    :items="workersWithMissingPersons"
                                    item-text="name"
                                    v-model="workerModel"
                                    label="Töö teostaja"
                                    return-object
                                    :error-messages="workerErrors"
                                    no-data-text="Töötajaid ei ole lisatud"
                            >
                                <template v-slot:append-item>
                                    <v-row no-gutters class="pr-5 pr-sm-auto my-2 px-sm-4">
                                        <v-btn
                                                color="primary" class="mb-2"
                                                block
                                                @click.stop="addNewWorker">
                                            <v-icon>add</v-icon>
                                            Lisa töötaja
                                        </v-btn>
                                    </v-row>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="align-left" v-if="requiresSoilSampler">
                        <v-col class="col-12 col-sm-5">
                            <v-text-field
                                    prepend-icon="account_circle"
                                    v-model="soilSamplerName"
                                    label="Proovi võtja"
                                    :error-messages="soilSamplerError"
                                    data-cy="soil-sampler-name-input"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <image-upload v-if="requiresImages" v-bind:initialPhotoIds.sync="imagesModel" />
                    <v-row no-gutters class="align-left mt-4 flex-nowrap" v-for="(warning, index) in warnings" :key="warning.targetId + warning.type.key+index" data-cy="virtual-warnings">
                        <v-icon :color="warningIconColor(warning.type)" :class="$vuetify.breakpoint.smAndDown  ? 'mr-3 mt-2 mb-4' : 'mr-3 mt-0'">{{warningIcon(warning.type)}}</v-icon>
                        <span v-html="warning.message"></span>
                    </v-row>
                    <v-row no-gutters v-if="!fieldSpecificJobs || (isFromJobManagementPage && isCreatingNew())" :justify="$vuetify.breakpoint.smAndDown? 'center' : 'end'">
                        <v-checkbox class="mt-8 mb-n8"
                                    color="primary" v-model="clearFormAfterSubmit"
                                    :label="isFromJobManagementPage ? 'Peale salvestamist sulge ankeet' : 'Puhasta ankeet peale salvestamist'"
                                    data-cy="jobevent-clear-form-checkbox" />

                    </v-row>
                </v-col>
            </v-row>

            <template #footer>
                <v-btn v-if="fieldSpecificJobs" :block="!$vuetify.breakpoint.smAndUp"  color="primary" outlined class="mx-2" @click="$emit('closeModal')">Katkesta</v-btn>
                <v-btn color="primary" :block="!$vuetify.breakpoint.smAndUp" elevation="0" light @click="addOrUpdate" :large="isCreatingNew() && !fieldSelectShouldBeLocked && !insideModal" :disabled="disableSubmit" :loading="loadingSubmit" data-cy="jobevent-save-button">
                    <v-col style="text-align: center">
                        <v-row no-gutters class="justify-center" :style="isCreatingNew() && (!fieldSelectShouldBeLocked && clearFormAfterSubmit && !insideModal || (isFromJobManagementPage && !clearFormAfterSubmit)) ? 'font-size: 1rem' : null">
                            Salvesta
                        </v-row>
                        <v-row v-if="isCreatingNew() && !fieldSelectShouldBeLocked && clearFormAfterSubmit && !insideModal" no-gutters class="justify-center" >
                            <span style="display: block; font-weight: 400; font-size: 9px;margin-top:-2px">Ja puhasta ankeet</span>
                        </v-row>
                        <v-row v-else-if="isFromJobManagementPage && isCreatingNew() && !clearFormAfterSubmit" no-gutters class="justify-center" >
                            <span style="display: block; font-weight: 400; font-size: 9px;margin-top:-2px">Ja jätka tööde lisamisega</span>
                        </v-row>

                    </v-col>
                </v-btn>
            </template>
        </modal-container-wrapper>
        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="showWorkerManagementModal" absolute style="z-index: 300"
                  max-width="800px">
            <workerManagementModal @close-modal="showWorkerManagementModal = false" :visible="showWorkerManagementModal">
            </workerManagementModal>
        </v-dialog>
        <v-dialog :fullscreen="true" v-model="showMapModel" absolute>
            <map-wrapper
                :initialFieldIds="selectedFieldIds"
                :isVisible=showMapModel
                :date="this.dateStart"
                :allowedToChange="isCreatingNew() && !fieldSelectShouldBeLocked"
                @close="showMapModel = false"
                @closeAllPopups="closeAllPopups"
                @fieldSelection="fieldSelectionFromMap"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import requisiteComponent from "./requisiteComponent"
import {findFieldYearInfoForDate} from "../../../dist/common/fieldYearInfo.js"
import {VBtn} from 'vuetify/lib'

import {REQUISITE_CHEMICAL, REQUISITE_FERTILIZER, REQUISITE_MATERIAL, REQUISITE_SEED} from "../constant/requisiteType";

import _ from "lodash";
import {warningIcon, warningIconColor} from "../../../dist/common/warningVisuals.js"
import {ANIMATION} from "../../../dist/common/constants.js"
import workerManagementModal from "../management/workerManagementModal.vue"
import {findCropColor, provideCloseDropdown, provideRequiredPositiveNumber} from "@/mixins/mixins";
import modalContainerWrapper from "../components/modalContainerWrapper";
import AdditionalInfo from "@/components/additionalInfo";
import {mapActions, mapGetters} from "vuex";
import WorkTimeModal from "@/worktime/workTimeModal";
import {
    mdiBarley,
    mdiCalendar,
    mdiCalendarBadgeOutline,
    mdiCalendarToday,
    mdiEqual,
    mdiFolderWrench,
    mdiFolderWrenchOutline,
    mdiHammerWrench,
    mdiPackageVariantClosed,
    mdiProgressCheck,
    mdiShapePolygonPlus,
    mdiStackOverflow,
    mdiTableRowPlusBefore,
    mdiTextBoxEditOutline,
    mdiUndoVariant,
    mdiWrench
} from "@mdi/js";
import InspectionsComponent from "@/management/inspectionsComponent.vue";
import HerdingComponent from "@/jobEvent/herdingComponent.vue";
import LandImpovement from "@/jobEvent/landImprovement.vue";
import ManureAmount from "@/jobEvent/manureAmount.vue";
import MapWrapper from "@/jobEvent/mapWrapper.vue";
import JobVariant from "@/jobEvent/jobVariant.vue";
import ImageUpload from "@/jobEvent/imageUpload.vue";
import {FERTILIZER_TYPE, JOB_STATUS} from "../../../dist/common/constants";
import {ICONS} from "../constant/constants";

const blankRequisites = {
        name: null,
        unit: "kg/ha",
        amount: null,
        type: null,
        safetyRecord: null,
        workDelayDays: null,
        harvestDelayDays: null,
        usageTimes: null,
        safetyArea: null,
        safetyWaterArea: null,
        subtype: null,
        subtypeVariant: null,
        batchNo: null,
        seedCertificate: null,
        nutrient: null,
        requisiteError: null,
        requisiteUnitError: null,
        requisiteAmountError: null,
        coatingError: null,
        coating: null,
        note: null,
        ingredients: null,
        coatingUnitError: null,
        coatingAmountError: null,
        requisiteTypeError: null,
        subTypeError: null,
        subtypeVariantError: null,
    };
    export default {
        props: {
            field: {
                type: Object
            },
            yearNumber: {
                type: Number
            },
            jobEventToEdit: {
                type: Object
            },
            fieldSpecificJobs: {
                default: true,
                type: Boolean
            },
            visible: Boolean,
            insideModal: {
                default: false,
                type: Boolean
            },
            isFromJobManagementPage: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                initialInspectionsForRequisites: [],
                fieldErrors: null,
                areaErrors: null,
                jobCategoryErrors: null,
                jobErrors: null,
                workerErrors: null,
                inspectionsErrors: null,
                herdingErrors: null,
                landImprovementErrors: null,
                manureErrors: null,
                dateStart: new Date().toISOString().substring(0, 10),
                dateEnd: new Date().toISOString().substring(0, 10),
                jobDateStart: false,
                jobDateEnd: false,
                dateEndErrors: null,
                workerModel: null,
                soilSamplerName: null,
                soilSamplerError: null,
                jobVariantModel: null,
                jobVariantErrors: null,
                jobCategoryModel: null,
                jobModel: null,
                fieldModel: null,
                requsiteBlocks: [],
                types: [],
                subtypes: [],
                virtualRequisites: [],
                virtualWarnings: [],
                imagesModel: [],
                areaModel: null,
                jobStatusModel: 'DONE',
                areaWasAutomaticallySet: true,
                areaReductionMessage: null,
                inspectionsModel: null,
                herdingsModel: null,
                jobAmountModel: null,
                jobUnitModel: null,
                fertilizationHighNutrientNModel: false,
                fertilizationNutrientNModel: null,
                fertilizationSoilSampleYearModel: null,
                fertilizationFertilizationBeforeSow: false,
                fertilizationNutrientNErrors: null,
                fertilizationSoilSampleYearErrors: null,
                cropYieldUnitWeightModelErrors: null,
                notesModel: null,
                fields: [],
                showWorkerManagementModal: false,
                clearFormAfterSubmit: true,
                disableSubmit: false,
                loadingSubmit: false,
                cropYieldModel: null,
                cropYieldTypeModel: null,
                cropYieldPerHectareModel: null,
                cropYieldIsManuallyBeingChanged: false,
                cropYieldPerHectareIsManuallyBeingChanged: false,
                cropYieldErrors: null,
                cropYieldUnitsModel: "t",
                cropYieldUnitWeightModel: null,
                totalFieldArea: 0,
                previouslyAddedJobEvent: {},
                showMapModel: false,
                rules: {
                    required: (value) => !!value || 'Kohustuslik väli.',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    }
                }
            }
        },
        components: {
            ImageUpload,
            JobVariant,
            MapWrapper,
            ManureAmount,
            LandImpovement,
            HerdingComponent,
            VBtn,
            InspectionsComponent,
            WorkTimeModal,
            AdditionalInfo,
            requisiteComponent,
            workerManagementModal,
            modalContainerWrapper,
        },
        mixins: [provideCloseDropdown, findCropColor, provideRequiredPositiveNumber],
        computed: {
            ICONS() {
                return ICONS
            },
            computedDateStartFormatted() {
                return this.formatDate(this.dateStart)
            },
            computedDateEndFormatted() {
                return this.formatDate(this.dateEnd)
            },
            warnings() {
                let currentFieldVirtualWarnings;
                if (this.isModifingExisting()) {
                    currentFieldVirtualWarnings = _.uniqBy(_.filter(this.virtualWarnings, warning => warning.targetId === this.jobEventToEdit._id), warning => warning.type.key);
                } else {
                    currentFieldVirtualWarnings = _.filter(this.virtualWarnings, warning => warning.targetId === undefined);
                }
                if (currentFieldVirtualWarnings && currentFieldVirtualWarnings.length > 0) {
                    return currentFieldVirtualWarnings
                }
                return undefined;
            },
            jobCategories() {
                let restoredMissingCategory = [];
                if (this.isModifingExisting() && this.jobEventToEdit.jobCategory !== undefined && this.jobEventToEdit.jobCategory !== null) {
                    let existingCategory = this.findByName(this.$store.getters.jobCategories, this.jobEventToEdit.jobCategory);
                    if (existingCategory === undefined) {
                        restoredMissingCategory.push(this.jobEventToEdit.jobCategory);
                    }
                }
                return _.concat(this.$store.getters.jobCategories, restoredMissingCategory);
            },
            stateFields() {
                return this.$store.getters.fields.map(field => {
                    delete field.hasJobEventsThisYear
                    return field
                }); // So adding a job to new field does not change the "matching" logic for field select when adding more job events after that
            },
            jobs() {
                let restoredMissingJob = [];
                if (this.isModifingExisting() && this.jobEventToEdit.job !== undefined && this.jobEventToEdit.job !== null && this.jobEventToEdit.jobCategory === this.jobCategoryModel) {
                    let existingJob = this.findByName(this.$store.getters.jobs(this.jobCategoryModel), this.jobEventToEdit.job);
                    if (existingJob === undefined) {
                        let virtualJob = {
                            name: this.jobEventToEdit.job,
                            requires: []
                        };
                        if (this.jobEventToEdit.requisite && this.jobEventToEdit.requisite.name) {
                            virtualJob.requires.push("material");
                        }
                        if (this.jobEventToEdit.requisite && this.jobEventToEdit.requisite.inspections !== null &&
                            this.jobEventToEdit.requisite.inspections !== undefined &&
                            this.jobEventToEdit.requisite.inspections.length > 0) {
                            virtualJob.requires.push("inspections");
                        }
                        if (this.jobEventToEdit.worker) {
                            virtualJob.requires.push("worker")
                        }
                        restoredMissingJob.push(virtualJob);
                    }
                }
                return _.concat(this.$store.getters.jobs(this.jobCategoryModel), restoredMissingJob);
            },
            units() {
                return this.$store.getters.units
            },
            workersWithMissingPersons() {
                let restoredMissingWorker = [];
                if (this.isModifingExisting() && this.jobEventToEdit.worker !== undefined && this.jobEventToEdit.worker !== null) {
                    let existingWorker = this.findByName(this.$store.getters.getWorkers, this.jobEventToEdit.worker);
                    if (existingWorker === undefined) {
                        restoredMissingWorker.push({name: this.jobEventToEdit.worker});
                    }
                }
                if (_.includes(this.types, REQUISITE_CHEMICAL.typeName)) {
                    const chemicalCertifiedWorkers = _.filter(this.$store.getters.getWorkers, worker => worker.chemicalCertification);
                    return _.concat(chemicalCertifiedWorkers, restoredMissingWorker);
                } else {
                    return _.concat(this.$store.getters.getWorkers, restoredMissingWorker);
                }
            },
            showJobTasks() {
                return this.jobCategoryModel == null;
            },
            requiresRequisite() {
                return this.isDefined(this.jobModel) && this.isDefined(this.jobModel.requires) &&
                    _.intersection(this.jobModel.requires, [REQUISITE_SEED.typeName, REQUISITE_FERTILIZER.typeName, REQUISITE_CHEMICAL.typeName, REQUISITE_MATERIAL.typeName]).length > 0 ||
                    (this.isModifingExisting() && this.isDefined(this.jobEventToEdit.requisite) && this.isDefined(this.jobEventToEdit.requisite.name));

            },
            requiresInspections() {
                return (_.includes(this.types, REQUISITE_CHEMICAL.typeName) || (this.jobModel !== undefined && this.jobModel !== null &&
                    this.isDefined(this.jobModel.requires) && _.includes(this.jobModel.requires, "inspections"))) ||
                    (this.isModifingExisting() && this.jobEventToEdit.requisite !== null && this.jobEventToEdit.requisite !== undefined &&
                        this.jobEventToEdit.requisite.inspections !== null &&
                        this.jobEventToEdit.requisite.inspections !== undefined &&
                        this.jobEventToEdit.requisite.inspections.length > 0);
            },
            requiresHerding() {
                return (this.jobModel && this.jobModel.name === "Karjatamine") ||
                    (this.jobModel !== undefined && this.jobModel !== null && this.isDefined(this.jobModel.herding) && this.jobModel.herdings.length > 0) ||
                    (this.isModifingExisting() && this.jobEventToEdit.herding !== null && this.jobEventToEdit.herding !== undefined && this.jobEventToEdit.herding.length > 0);
            },
            requiresLandImprovementAmountAndUnit() {
                return (this.jobCategoryModel && this.jobCategoryModel === 'Maaparandushoiutööd')
            },
            requiresManureAmount() {
                return (this.jobCategoryModel && this.jobCategoryModel === 'Sõnniku aunastamine')
            },
            requiresFertilizationInfo() {
                return (_.includes(this.types, REQUISITE_FERTILIZER.typeName) && _.includes(this.subtypes, FERTILIZER_TYPE.FERTILIZER)) ||
                    (this.isModifingExisting() &&
                        this.jobEventToEdit.requisite !== null &&
                        this.jobEventToEdit.requisite !== undefined &&
                        this.jobEventToEdit.requisite.subtype === FERTILIZER_TYPE.FERTILIZER &&
                        this.jobEventToEdit.requisite.fertilization !== null &&
                        this.jobEventToEdit.requisite.fertilization !== undefined);
            },
            requiresNote() {
                return (this.isModifingExisting() && this.isDefined(this.jobEventToEdit.notes)) && this.jobEventToEdit.notes !== '' || // modifying existing and the note is defined
                    (this.isDefined(this.jobModel) && this.isDefined(this.jobModel.requires) && (_.includes(this.jobModel.requires, "note"))) ||
                    (this.isDefined(this.notesModel) && this.notesModel !== ''); // Not existing but notes exist.
            },
            requiresImages() {
                return (this.isModifingExisting() && this.isDefined(this.jobEventToEdit.images) && this.jobEventToEdit.images.length > 0) || // modifying existing and has images
                    (this.isDefined(this.jobModel) && this.isDefined(this.jobModel.requires) && (_.includes(this.jobModel.requires, "image"))) ||
                    (this.isDefined(this.imagesModel) && this.imagesModel.length > 0);
            },
            requiresWorkerName() {
                return (
                    (_.includes(this.types, REQUISITE_CHEMICAL.typeName)) || // Requisite is chemical (so its required)
                    (this.isModifingExisting() && this.jobEventToEdit.worker !== null) || // modifying existing and the worker is defined
                    (this.isDefined(this.jobModel) && this.isDefined(this.jobModel.requires) && (_.includes(this.jobModel.requires, "worker")))
                ) && !this.requiresSoilSampler; // Worker name is replaced by soil sampler name
            },
            requiresSoilSampler() {
                return (this.jobModel && this.jobModel.name && this.jobModel.name.includes("Mullaproovi"));
            },
            requiresJobVariant() {
                return this.requiresSoilSampler || this.shouldShowCropYield
            },
            shouldShowCropYield() {
                return (this.fieldModel && this.fieldModel.length > 0 && this.jobModel && this.jobModel.name === "Koristus") ||
                    (this.isDefined(this.jobModel) && this.isDefined(this.jobModel.requires) && (_.includes(this.jobModel.requires, "yield"))) ||
                    (this.isModifingExisting() && this.jobEventToEdit.yield !== null && this.jobEventToEdit.yield !== undefined);
            },
            shouldShowCropWeight() {
              const yieldUnitsWithWeight = this.$store.getters.yieldUnitsWithWeight;
              return this.shouldShowCropYield && this.cropYieldUnitsModel && yieldUnitsWithWeight.indexOf(this.cropYieldUnitsModel) === -1;
            },
            shouldShowCropWeightOrWeightExists() {
              return this.cropYieldUnitWeightModel || this.shouldShowCropWeight;
            },
            jobRequirements() {
                if (this.jobModel) { //Jobmodel on ise "koebstamine" string
                    const existingTypes = this.requsiteBlocks.map(block => block.type); // Also add back types from existing requisites
                    const jobModelRequirements = this.jobModel.requires ?? []; // can be undefined when jobModel is string
                    return [...jobModelRequirements, ...existingTypes];
                }
                return undefined;
            },
            requiresJobSelection() {
                return !(this.jobCategoryModel !== null && (this.jobCategoryModel === 'Muu' && this.jobs && this.jobs.length === 1));
            },
            title() {
                if (this.jobEventToEdit && this.jobEventToEdit._id) {
                    return "Muuda tööd";
                } else {
                    return "Lisa uus töö"
                }
            },
            shouldKeepFieldSelectOpen: function () {
                return !(this.fieldModel && this.fieldModel.length >= 1)
            },
            fieldSelectShouldBeLocked: function () {
                return !!this.field
            },
            requisiteBlockStyles: function () {
                if (this.requsiteBlocks && this.requsiteBlocks.length > 1) {
                    return "margin-bottom: 8px;"
                }
                return '';
            },
            currentYear() {
                let currentFullYear = this.$store.getters.getCurrentFullYear;
                return {year: currentFullYear};
            },
            showWorkTime() {
                return (this.jobEventToEdit === null || this.jobEventToEdit === undefined) && !this.fieldSpecificJobs && this.$store.getters.getWorkTimeEnabledByConf;
            },
            yieldUnits() {
              const existingUnits = _.cloneDeep(this.$store.getters.yieldUnits);
              if (this.cropYieldUnitsModel) {
                const unitDoesNotExist = !_.includes(existingUnits, this.cropYieldUnitsModel);
                if (unitDoesNotExist) {
                  existingUnits.unshift(this.cropYieldUnitsModel);
                }
              }
              return existingUnits
            },
            areaLabel() {
                return this.fieldModel && this.fieldModel.length > 1 ? 'Pindalad kokku': 'Pindala'
            },
            stateCoatingChemicals() {
                return _.cloneDeep(this.getRequisitesByType()(REQUISITE_CHEMICAL))
            },
            lastFiveYears() {
                const currentYear = this.$store.getters.getCurrentFullYear;
                const lastFiveYears = [null];
                for (let i = 0; i < 5; i++) {
                    lastFiveYears.push(currentYear - i);
                }
                if (this.fertilizationSoilSampleYearModel) {
                    lastFiveYears.push(this.fertilizationSoilSampleYearModel);
                }
                return [null, ...(_.uniq(lastFiveYears)).sort().reverse()];
            },
            selectedFieldIds() {
                if (this.fieldModel) {
                    return this.fieldModel.map(field => field._id);
                } else {
                    return [];
                }
            },
            jobStatusOptions() {
                return JOB_STATUS
            },
            cropColors() {
                return this.$store.getters.cropColors;
            },
            yieldTypes() {
                return this.$store.getters.yieldTypes
            },
            mainCropYieldTypeWithDefaultValue: {
                get() {
                    if (this.isModifingExisting()) {
                        return this.cropYieldTypeModel || "Terad";
                    } else {
                        if (this.fieldModel && this.fieldModel.length > 0) {
                            const firstField = this.fieldModel[0];
                            return this.cropYieldTypeModel || this.fieldYearInfo(firstField)?.mainCrop?.yieldType || "Terad";
                        }
                        return this.cropYieldTypeModel || "Terad"
                    }
                },
                set(value) {
                    this.cropYieldTypeModel = value;
                }
            },
            priaEnabled() {
                return this.$store.getters.getPriaConnected
            },
            cropYieldTotalComputedWeight() {
                if (this.cropYieldModel && this.cropYieldUnitWeightModel) {
                    return _.round(this.cropYieldModel * this.cropYieldUnitWeightModel, 2);
                }
                return undefined;
            }
        },
        watch: {
            jobs: function (jobs) {
                if (jobs !== null && jobs !== undefined) {
                    if (this.jobEventToEdit && this.jobEventToEdit.job !== null && this.jobEventToEdit.job !== undefined) {
                        let jobModel = _.find(this.jobs, job => job.name === this.jobEventToEdit.job);
                        if (jobModel) {
                            this.jobModel = jobModel;
                        } else {
                            this.jobModel = this.jobEventToEdit.job;
                        }
                    } else {
                        this.jobModel = jobs[0];
                    }
                }
            },
            stateFields: {
                handler: function(newFields) {
                    this.fields = _.clone(newFields);
                    if (this.fieldModel && this.fieldModel.length > 0) { // Update year info after fields reload. Needed for crop yield unit refresh after change
                        this.fieldModel.forEach(field => {
                            const updatedField = _.find(newFields, f => f._id === field._id);
                            field.yearInfo = updatedField.yearInfo;
                        })
                    }
                },
                immediate: true
            },
            yearNumber: function() {
                if (this.yearNumber) {
                    let date = new Date(this.dateStart);
                    date.setFullYear(this.yearNumber)
                    const dateString = date.toISOString().substring(0, 10);
                    this.dateStart = dateString;
                    this.dateEnd = dateString;
                }
            },
            dateStart: function (newValue, oldValue) {
                if (this.isCreatingNew()) {
                    this.recalculateAreas()
                }
                if (this.isCreatingNew() && this.fieldModel && this.fieldModel.length === 1) {
                    this.recalculateCropYieldValues()
                }
                let currentDate = this.$store.getters.getCurrentDate.toISOString().substring(0, 10);
                if (oldValue === this.dateEnd && (this.isCreatingNew() || currentDate !== oldValue)) {
                    this.dateEnd = newValue;
                }
                this.updateVirtualWarnings()
            },
            visible(isVisible) {
                if (isVisible) {
                    this.initialize()
                }
            },
            jobEventToEdit: 'initialize',
            field: 'initialize',
            fieldModel: function () {
                if (this.fieldModel) {
                    if (this.fieldModel.some(element => typeof element === 'string')) {
                        this.fieldModel = this.fieldModel.filter(element => typeof element !== 'string');
                    }
                    this.areaWasAutomaticallySet = true
                    this.recalculateAreas()
                    this.recalculateCropYieldValues()
                }
                this.fieldErrors = null;
                this.areaErrors = null;
                if (_.some(this.fieldModel, field => field.yearInfo === undefined)) {
                    this.fieldModel = _.filter(this.fieldModel, field => field.yearInfo !== undefined)
                }
                // if (this.fieldModel.length <= 1) {
                //     this.$nextTick(() => {
                //         if (this.$refs.fieldModel) {
                //             this.$refs.fieldModel.blur();
                //         }
                //     });
                // }
                this.updateVirtualWarnings()
                this.cropYieldPerHectareModel = _.round(this.cropYieldModel / this.areaModel, 2);

            },
            areaModel: function () {
                this.areaErrors = null;
                if (!this.areaWasAutomaticallySet) {
                    this.areaReductionMessage = null
                }
                const newCropYieldPerHectareModel = _.round(this.cropYieldModel / this.areaModel, 2);
                if (newCropYieldPerHectareModel && isFinite(newCropYieldPerHectareModel)) {
                    this.cropYieldPerHectareModel = newCropYieldPerHectareModel;
                }
                this.areaWasAutomaticallySet = false;
                this.updateVirtualWarnings()
            },
            fertilizationNutrientNModel: function () {
                this.fertilizationNutrientNErrors = null;
            },
            fertilizationSoilSampleYearModel: function () {
                this.fertilizationSoilSampleYearErrors = null;
            },
            cropYieldUnitWeightModel: function () {
                this.cropYieldUnitWeightModelErrors = null;
            },
            cropYieldModel: function () {
                this.cropYieldErrors = null;
                if (this.cropYieldPerHectareIsManuallyBeingChanged === false && this.areaModel) {
                    this.cropYieldIsManuallyBeingChanged = true
                    this.cropYieldPerHectareModel = _.round(this.cropYieldModel / this.areaModel, 2);
                    this.$nextTick(() => {
                        this.cropYieldIsManuallyBeingChanged = false
                    })
                }
            },
            cropYieldPerHectareModel: function () {
                this.cropYieldPerHectareIsManuallyBeingChanged = true
                if (this.cropYieldIsManuallyBeingChanged === false && this.totalFieldArea) {
                    this.cropYieldModel = _.round(this.cropYieldPerHectareModel * this.areaModel, 2);
                }
                this.$nextTick(() => {
                    this.cropYieldPerHectareIsManuallyBeingChanged = false
                })

            },
            inspectionsModel: function () {
                this.updateVirtualWarnings();
            },
            jobCategoryModel: function () {
                if (this.jobCategoryModel === 'Muu' && this.jobs && this.jobs.length === 1 &&
                    this.$store.getters.getShouldShowOtherCategoryNotification) {
                    this.$confirm("Kui soovite lisada tööd, mis ei sobitu ühegi olemasoleva kategooria alla, siis võiksite <strong>lisada uue</strong> kategooria.</br></br>" +
                        "Mõistlik oleks ka ära peita töö tüübid, mida kunagi ei ole plaanis teostada.", {
                        title: 'Tööde kategooriad',
                        buttonTrueText: this.$vuetify.breakpoint.mdAndUp ? 'Kategooriaid seadistama': 'seadistama',
                        buttonFalseText: 'Mitte praegu',
                        icon: 'info',
                        width: 450,
                    }).then((result) => {
                            if (result) {
                                this.$router.push({name: 'categories'});
                            }
                        });
                    this.$store.dispatch('doNotShowOtherCategoryNotification');
                }
                if (this.fieldModel) {
                    this.recalculateAreas()
                }
                this.jobCategoryErrors = null;
                if (this.jobs && this.jobs.length > 1 && (this.isCreatingNew() && (this.jobEventToEdit === null || this.jobEventToEdit === undefined || this.jobEventToEdit.job === undefined ))) {
                    setTimeout(() => {
                        this.$refs.jobModel.focus()
                        this.$refs.jobModel.activateMenu();
                    });
                }

                this.$nextTick(() => {
                    if (this.$refs.jobCategoryModel) {
                        this.$refs.jobCategoryModel.blur();
                    }
                });
                this.updateVirtualWarnings()
            },
            jobModel: function () {
                this.jobErrors = null;
                this.workerErrors = null;
                this.inspectionsErrors = null;
                this.herdingErrors = null;
                this.landImprovementErrors = null;
                this.manureErrors = null;
                this.dateEndErrors = null;
                this.soilSamplerError = null;
                this.jobVariantErrors = null;
                this.fertilizationNutrientNErrors = null;
                this.cropYieldErrors = null;
                this.fertilizationSoilSampleYearErrors = null;
                this.cropYieldUnitWeightModelErrors = null;

                if (this.requiresWorkerName) {
                    if (this.isCreatingNew()) {
                        this.workerModel = this.workersWithMissingPersons[0];
                    }
                } else {
                    this.workerModel = null;
                }
                if (this.requiresSoilSampler) {
                    if (this.isCreatingNew()) {
                        this.soilSamplerName = null
                    }
                } else {
                    this.soilSamplerName = null
                }
                if (this.shouldShowCropYield) {
                    if (this.isCreatingNew()) {
                        this.cropYieldModel = null;
                        this.cropYieldTypeModel = null;
                        this.cropYieldPerHectareModel = null;
                        this.cropYieldUnitWeightModel = null;
                    }
                } else {
                    this.cropYieldModel = null;
                    this.cropYieldTypeModel = null;
                    this.cropYieldUnitWeightModel = null;
                }
                if (this.fieldModel) {
                    this.recalculateAreas()
                }
                this.virtualRequisites = [];
                this.updateVirtualWarnings()
                if (this.requiresRequisite) {
                    // if (this.isCreatingNew() || this.requsiteBlocks === null || this.requsiteBlocks.length === 0) {
                    if (this.isCreatingNew()) {
                        this.requsiteBlocks = [_.cloneDeep(blankRequisites)];
                        this.inspectionsModel = null;
                        this.fertilizationHighNutrientNModel = false;
                        this.fertilizationNutrientNModel = null;
                        this.fertilizationSoilSampleYearModel = null;
                        this.fertilizationFertilizationBeforeSow = false;

                            setTimeout(() => {
                                if (this.$refs.jobModel && this.$refs.jobModel.isMenuActive === false) {
                                    this.requsiteBlocks.forEach((requsite, index) => {
                                        try {
                                            this.$refs[requsite][index].$emit('clickAndFocusRequisite');
                                        } catch (e) { /* Can throw error when no requisites are defined at all */ }
                                    });
                                }

                            });
                    }

                } else {
                    this.requsiteBlocks = [];
                    this.types = [];
                    this.subtypes = [];
                    this.inspectionsModel = null;
                    this.fertilizationHighNutrientNModel = false;
                    this.fertilizationNutrientNModel = null;
                    this.fertilizationSoilSampleYearModel = null;
                    this.fertilizationFertilizationBeforeSow = false;
                }
                if (this.requiresHerding) {
                    if (this.isCreatingNew()) {
                        this.herdingsModel = null;
                    }
                } else {
                    this.herdingsModel = null;
                }
                if (this.requiresLandImprovementAmountAndUnit || this.requiresManureAmount) {
                    if (this.isCreatingNew()) {
                        this.jobAmountModel = null;
                        this.jobUnitModel = null;
                    }
                } else {
                    this.jobAmountModel = null;
                    this.jobUnitModel = null;
                }
                if (this.requiresImages) {
                    if (this.isCreatingNew()) {
                        this.imagesModel = [];
                    }
                } else {
                    this.imagesModel = [];
                }
                if (this.requiresNote) {
                    if (this.isCreatingNew()) {
                        this.notesModel = null;
                    }
                } else {
                    this.notesModel = null;
                }
                if (this.$refs.jobModel && !this.$refs.jobCategoryModel.isMenuActive) {
                    this.$nextTick(() => {
                        if (this.$refs.jobModel) {
                            this.$refs.jobModel.blur();
                        }
                    });
                }
                this.updateVirtualWarnings()
            },
            jobStatusModel: function (newVal, oldVal) {
                if (newVal === 'DONE' && oldVal === 'IN_PROGRESS' && this.dateEnd === null) { // when completing in progress job then automatically set end date as today
                    let currentDate = new Date(this.$store.getters.getCurrentDate);
                    if (this.yearNumber) {
                        currentDate.setFullYear(this.yearNumber)
                    }
                    this.dateEnd = currentDate.toISOString().substring(0, 10);
                }
                if (this.jobStatusModel === 'IN_PROGRESS') {
                    this.dateEnd = null;
                } else if (this.dateEnd === null) {
                    this.dateEnd = this.dateStart;
                }
            }
        },
        created() {
            this.initialize()
        },
        methods: {
            mdiBarley() {
                return mdiBarley
            },
            mdiEqual() {
                return mdiEqual
            },
            mdiTableRowPlusBefore() {
                return mdiTableRowPlusBefore
            },
            mdiProgressCheck() {
                return mdiProgressCheck
            },
            mdiFolderWrenchOutline() {
                return mdiFolderWrenchOutline
            },
            mdiTextBoxEditOutline() {
                return mdiTextBoxEditOutline
            },
            mdiWrench() {
                return mdiWrench
            },
            mdiFolderWrench() {
                return mdiFolderWrench
            },
            mdiHammerWrench() {
                return mdiHammerWrench
            },
            mdiCalendar() {
                return mdiCalendar
            },
            mdiCalendarToday() {
                return mdiCalendarToday
            },
            mdiCalendarBadgeOutline() {
                return mdiCalendarBadgeOutline
            },
            mdiShapePolygonPlus() {
                return mdiShapePolygonPlus
            },
            mdiUndoVariant() {
                return mdiUndoVariant
            },
            mdiStackOverflow() {
                return mdiStackOverflow
            },
            mdiPackageVariantClosed() {
                return mdiPackageVariantClosed
            },
            ...mapActions(["doNotShowUseMultipleFieldsNotification"]),
            ...mapGetters(["shouldShowUseMultipleFieldsNotification", "getRequisitesByType"]),
            customSortFields: function (searchValue) {
                const $this = this;
                if (searchValue && searchValue.length > 0) {
                    const searchValueLowerCase = searchValue.toLowerCase();
                    this.fields.sort((first, second) => {
                        const aName = $this.fieldNameByYear(first).toLowerCase();
                        const bName = $this.fieldNameByYear(second).toLowerCase();
                        const aStartsWithSearch = aName.startsWith(searchValueLowerCase) ? 1 : 0;
                        const bStartsWithSearch = bName.startsWith(searchValueLowerCase) ? 1 : 0;
                        return bStartsWithSearch - aStartsWithSearch;
                    });
                    if (searchValue.length === 1 && this.$vuetify.breakpoint.smAndDown) {
                        const currentUser = this.$store.getters.user;
                        if (currentUser && currentUser.email === "kaido@edenberg.ee") {
                            this.$nextTick(() => {
                                document.getElementById("fieldDropdownId").blur()
                            });
                        }
                    }
                } else {
                    this.fields = _.clone(this.stateFields);
                }
            },
            updateType: function (type, index) {
                this.$set(this.types, index, type)
                if (this.requiresWorkerName) {
                    if (this.isCreatingNew() && this.workersWithMissingPersons.length > 0) {
                        this.workerModel = this.workersWithMissingPersons[0];
                    }
                } else {
                    this.workerModel = null;
                }
            },
            updateSubtype: function (type, index) {
                this.$set(this.subtypes, index, type)
            },
            updateRequisiteForWarningChecking: function (requisite, index) {
                this.$set(this.virtualRequisites, index, requisite);
                this.updateVirtualWarnings(requisite)
            },
            deleteInspection: function (index) {
                this.initialInspectionsForRequisites.splice(index, 1);
                const inspectionTargetsInInitialInspections = this.initialInspectionsForRequisites.flatMap((inspections) => inspections.map((inspection) => inspection.target));
                this.inspectionsModel = _.filter(this.inspectionsModel, inspection => _.includes(inspectionTargetsInInitialInspections, inspection.target));
            },
            updateInspection: function (inspections, index) {
                if (this.inspectionsModel === null) {
                    this.inspectionsModel = [];
                }
                const $this = this;
                if (inspections && inspections.length > 0) {
                    // Remove empty inspections that where added automatically as placeholder to have atleast 1
                    $this.inspectionsModel = _.filter($this.inspectionsModel, inspection => !(inspection.inspectionType === null && inspection.target === null && inspection.amount === null));
                }
                if (this.initialInspectionsForRequisites[index] === null || this.initialInspectionsForRequisites[index] === undefined) {
                    // console.log("Dynamic inspections at index "+index+" are null or undefined", this.initialInspectionsForRequisites[index])
                    _.forEach(inspections, function (inspection) {
                        const inspectionTargets = $this.inspectionsModel.map(inspection => inspection.target);

                        if (!_.includes(inspectionTargets, inspection.target)) {
                            $this.inspectionsModel = _.concat($this.inspectionsModel, inspection)
                            // console.log("ADDED:", inspection.target)
                        }
                    });
                } else {
                    // console.log("Dynamic inspections at index "+index+" are NOT null or undefined", this.initialInspectionsForRequisites[index])
                    const initialInspectionsForRequisite = this.initialInspectionsForRequisites[index];
                    _.forEach(initialInspectionsForRequisite, function (removeCandidate) {
                        let counter = 0;
                        for (let key in $this.initialInspectionsForRequisites) {
                            // eslint-disable-next-line
                            if ($this.initialInspectionsForRequisites.hasOwnProperty(key)) {
                                let inspections = $this.initialInspectionsForRequisites[key];
                                const inspectionTargets = inspections.map(inspection => inspection.target);
                                if (_.includes(inspectionTargets, removeCandidate.target)) {
                                    counter++;
                                }
                            }
                        }
                        if (counter <= 1) { // has several duplicates, so remove them
                            $this.inspectionsModel = _.without($this.inspectionsModel, removeCandidate);
                            // console.log("REMOVED:", removeCandidate.target)
                        }
                    });

                    _.forEach(inspections, function (inspection) {
                        const inspectionTargets = $this.inspectionsModel.map(inspection => inspection.target);
                        if (!_.includes(inspectionTargets, inspection.target)) {
                            $this.inspectionsModel = _.concat($this.inspectionsModel, inspection)
                            // console.log("ADDING last:", inspection.target, $this.inspectionsModel)
                        }
                    });
                }
                this.initialInspectionsForRequisites[index] = inspections;
            },
            fieldYearInfo(field) {
                try {
                    return findFieldYearInfoForDate(field, this.dateStart)
                } catch (error) {
                    throw Error("Field year info calculated for unknown field. Year: " + this.dateStart + " Field: " + JSON.stringify(field))
                }
            },
            fieldNameByYear: function (field) {
                let fieldYearInfo = this.fieldYearInfo(field);
                if (fieldYearInfo === undefined) {
                    return null
                }
                return fieldYearInfo.name
            },
            fieldAreaByYear: function (field) {
                let fieldYearInfo = this.fieldYearInfo(field);
                return fieldYearInfo === undefined ? 0 : fieldYearInfo.area
            },
            shouldReduceAreaBySlopedArea: function (fieldYearInfo) {
                return this.isCreatingNew() &&
                    fieldYearInfo.slopedArea && fieldYearInfo.slopedArea > 0 &&
                    (this.jobCategoryModel === 'Väetamine' && this.jobModel &&
                        (this.jobModel.name === 'Väetamine - paisklaotamine' || this.jobModel.name === 'Väetamine - pindlaotamine'))
            },
            initialize: function () {
                this.jobCategoryModel = null;
                this.jobModel = null;
                this.requsiteBlocks = [];
                this.types = [];
                this.subtypes = [];
                this.workerModel = null;
                this.inspectionsModel = [];
                this.herdingsModel = [];
                this.jobAmountModel = null;
                this.jobUnitModel = null;
                this.imagesModel = [];
                this.soilSamplerName = null;
                this.jobVariantModel = null;
                this.fertilizationHighNutrientNModel = false;
                this.cropYieldTypeModel = null
                this.fertilizationNutrientNModel = null;
                this.fertilizationSoilSampleYearModel = null;
                this.fertilizationFertilizationBeforeSow = false;
                this.cropYieldModel = null;
                this.cropYieldTypeModel = null;
                this.cropYieldUnitsModel = null;
                this.cropYieldUnitWeightModel = null;
                if (this.field) {
                    this.fieldModel = [this.field];
                    this.areaModel = this.fieldAreaByYear(this.field);
                } else {
                    this.fieldModel = [];
                    this.areaModel = null;
                }
                this.jobStatusModel = 'DONE';
                let currentDate = new Date(this.$store.getters.getCurrentDate);
                if (this.yearNumber) {
                    currentDate.setFullYear(this.yearNumber)
                }
                const currentDateString = currentDate.toISOString().substring(0, 10);
                this.dateStart = currentDateString;
                this.dateEnd = currentDateString;
                this.notesModel = null;
                this.virtualWarnings =  [];
                this.virtualRequisites = [];
                if (this.isModifingExisting()) {
                    this.jobCategoryModel = this.jobEventToEdit.jobCategory;
                    this.jobModel = this.findByName(this.jobs, this.jobEventToEdit.job);
                    if (this.jobEventToEdit.status) { // Job management prefills job event, but does not populate the status field. so only set if it exists
                        this.jobStatusModel = this.jobEventToEdit.status;
                    }
                    if (this.jobEventToEdit.requisite !== null && this.jobEventToEdit.requisite !== undefined) {
                        this.requsiteBlocks = [_.merge(_.cloneDeep(blankRequisites), this.jobEventToEdit.requisite)];
                        this.inspectionsModel = this.jobEventToEdit.requisite.inspections;
                    }
                    if (this.jobEventToEdit.herding !== null && this.jobEventToEdit.herding !== undefined) {
                        this.herdingsModel = this.jobEventToEdit.herding;
                    }
                    if (this.jobEventToEdit.amount !== null && this.jobEventToEdit.amount !== undefined) {
                        this.jobAmountModel = this.jobEventToEdit.amount;
                    }
                    if (this.jobEventToEdit.unit !== null && this.jobEventToEdit.unit !== undefined) {
                        this.jobUnitModel = this.jobEventToEdit.unit;
                    }
                    if (this.jobEventToEdit.images !== null && this.jobEventToEdit.images !== undefined) {
                        this.imagesModel = _.cloneDeep(this.jobEventToEdit.images);
                    }
                    if (this.jobEventToEdit.variant !== null && this.jobEventToEdit.variant !== undefined && this.jobEventToEdit.variant !== "") {
                        this.jobVariantModel = this.jobEventToEdit.variant;
                    }

                    if (this.jobEventToEdit.requisite !== null && this.jobEventToEdit.requisite !== undefined &&
                        this.jobEventToEdit.requisite.fertilization !== null && this.jobEventToEdit.requisite.fertilization !== undefined) {
                        this.fertilizationHighNutrientNModel = this.jobEventToEdit.requisite.fertilization.highNutrientN;
                        this.fertilizationNutrientNModel = this.jobEventToEdit.requisite.fertilization.nutrientN;
                        this.fertilizationSoilSampleYearModel = this.jobEventToEdit.requisite.fertilization.soilSampleYear;
                        this.fertilizationFertilizationBeforeSow = this.jobEventToEdit.requisite.fertilization.fertilizationBeforeSow;
                    }
                    if (this.jobEventToEdit.worker !== null && this.jobEventToEdit.worker !== undefined) {
                        if (this.jobModel && this.jobModel.name.includes("Mullaproovi")) {
                            this.soilSamplerName = this.jobEventToEdit.worker;
                        } else {
                            this.workerModel = this.findByName(this.workersWithMissingPersons, this.jobEventToEdit.worker);
                        }
                    }
                    if (this.jobEventToEdit.notes !== null && this.jobEventToEdit.notes !== undefined) {
                        this.notesModel = this.jobEventToEdit.notes;
                    }
                    if (this.jobEventToEdit.yield !== null && this.jobEventToEdit.yield !== undefined) {
                        this.cropYieldModel = this.jobEventToEdit.yield;
                    }
                    if (this.jobEventToEdit.yieldType !== null && this.jobEventToEdit.yieldType !== undefined) {
                        this.cropYieldTypeModel = this.jobEventToEdit.yieldType;
                    }
                    if (this.jobEventToEdit.yieldUnit !== null && this.jobEventToEdit.yieldUnit !== undefined) {
                        this.cropYieldUnitsModel = this.jobEventToEdit.yieldUnit;
                    }
                    if (this.jobEventToEdit.yieldUnitWeight !== null && this.jobEventToEdit.yieldUnitWeight !== undefined) {
                        this.cropYieldUnitWeightModel = this.jobEventToEdit.yieldUnitWeight;
                    }
                    this.dateStart = this.jobEventToEdit.date.substring(0, 10);
                    if (this.jobEventToEdit.endDate) {
                       this.dateEnd = this.jobEventToEdit.endDate.substring(0, 10);
                    } else {
                        this.dateEnd = this.dateStart;
                    }
                    this.areaModel = this.jobEventToEdit.area;
                }
                this.updateVirtualWarnings()
            },

            findByName: function (collection, nameToFind) {
                return _.find(collection, (e) => {
                    let elementName = e.name === undefined ? e.toLowerCase() : e.name.toLowerCase();
                    return elementName === nameToFind.toLowerCase()
                })
            },
            showAddRequisitesNotification: function () {
                setTimeout(() => {
                    this.$confirm("Soovitame lisada kõik enamkasutatavad seemned, väetised, taimekaitsevahendid ning teised tarvikud nende vastaval halduse alamlehtedel. <br /><br />" +
                        "See <strong>kiirendab</strong> ja <strong>lihtsustab</strong> oluliselt töökannete tegemist tulevikus!", {
                        title: 'Lisage tihti kasutatavad tarvikud!',
                        buttonTrueText: this.$vuetify.breakpoint.mdAndUp ? 'Vahendeid lisama': 'lisama',
                        buttonFalseText: 'Mitte praegu',
                        icon: 'info',
                        width: 460,
                    }).then((result) => {
                        if (result) {
                            this.$router.push({name: 'seeds'});
                        }
                    })
                }, 2200);
                this.$store.dispatch('doNotShowAddRequisitesNotification');
            },
            hasNoRequisites: function (type) {
                let requisites = this.$store.getters.getRequisites(type);
                return requisites === null || requisites === undefined || requisites.length < 1;
            },
            isCreatingNew: function () {
                return this.jobEventToEdit === null || this.jobEventToEdit === undefined || this.jobEventToEdit._id === undefined || this.jobEventToEdit._id === null;
            },
            isModifingExisting: function () { // More relaxed check, so its possible to create new jobs by giving prefilled data
                return this.jobEventToEdit !== null && this.jobEventToEdit !== undefined;
            },
            formatDate(date) {
                if (!date) return null;

                const [year, month, day] = date.substring(0, 10).split('-');
                return `${day}.${month}.${year}`
            },
            hasMoreThanOneField() {
                return this.fieldModel && this.fieldModel.length > 1
            },
            updateVirtualWarnings: function (requisite) {
                if (this.fieldModel && this.fieldModel.length > 0 && this.jobModel) {
                    if (requisite) {
                        this.virtualWarnings = this.calculateVirtualWarnings(requisite)
                    } else if (this.virtualRequisites && this.virtualRequisites.length > 0) {
                        this.virtualWarnings = this.virtualRequisites.flatMap(requisite => this.calculateVirtualWarnings(requisite))
                    }
                } else {
                    this.virtualWarnings = [];
                }
            },
            calculateVirtualWarnings: function (requisiteToUse) {
                if (requisiteToUse !== null && requisiteToUse !== undefined) {
                    requisiteToUse.inspections = this.inspectionsModel
                }
                return this.fieldModel.flatMap(field => {
                    const hasMoreThanOneField = this.hasMoreThanOneField()
                    const area = hasMoreThanOneField ? this.fieldAreaByYear(field) : this.areaModel;
                    let virtualJobEvent = {
                        targetId: undefined,
                        fieldId: field._id,
                        jobCategory: this.jobCategoryModel,
                        job: this.jobModel.name ? this.jobModel.name : this.jobModel,
                        area: area,
                        date: this.dateStart,
                        endDate: this.dateEnd,
                        worker: null,
                        requisite: requisiteToUse,
                        notes: null
                    };
                    if (this.isModifingExisting()) {
                        virtualJobEvent._id = this.jobEventToEdit._id;
                    }
                    return this.$store.getters.getWarningsForNewJobEvent(virtualJobEvent);
                });
            },
            validateInspections(result) {
                this.inspectionsErrors = result;
            },
            validateHerding(result) {
                this.herdingErrors = result;
            },
            validateLandImprovement(result) {
                this.landImprovementErrors = result;
            },
            validateJobVariant(result) {
                this.jobVariantErrors = result;
            },
            validateManure(result) {
                this.manureErrors = result;
            },
            addOrUpdate() {
                if (this.disableSubmit) {
                    return;
                }
                this.disableSubmit = true;
                this.loadingSubmit = true;
                let formHasErrors = false;
                if (this.fieldModel === null || !this.fieldModel || this.fieldModel.length === 0) {
                    formHasErrors = true;
                    this.fieldErrors = ['Kohustuslik väli']
                }

                if (this.fieldModel && this.fieldModel.length === 1) {
                    this.areaErrors = this.errorsForExistingPositiveNumber(this.areaModel);
                    if (this.areaErrors) {
                        formHasErrors = true;
                    }
                }

                if (this.jobModel === null || !this.jobModel) {
                    formHasErrors = true;
                    this.jobCategoryErrors = ['Kohustuslik väli']
                }
                if (this.jobCategoryModel === null || !this.jobCategoryModel) {
                    formHasErrors = true;
                    this.jobErrors = ['Kohustuslik väli']
                }
                if (this.dateEnd !== null && new Date(this.dateEnd) < new Date(this.dateStart)) {
                    formHasErrors = true;
                    this.dateEndErrors = ['Enne alguse kuupäeva']
                }
                this.requsiteBlocks.forEach((requsite, index) => {
                    this.$refs[requsite][index].$emit('updateRequisite');
                });
                if (this.requsiteBlocks.length > 0 && this.requsiteBlocks[0] !== null) {
                    const hasMoreThanOneField = this.hasMoreThanOneField();
                    this.requsiteBlocks.forEach(requsite => {
                        if (requsite.safetyArea !== null && requsite.safetyArea !== undefined && requsite.safetyArea > 0) {
                            if (hasMoreThanOneField) {
                                formHasErrors = true;
                                this.areaErrors = ['Tarvikul on ohutusala, seega tohib tööd teostada ainult ohutuala võrra vähendatud pindaladel. Lisage töökanded ühe põllu kaupa.']
                            } else {
                                if (this.fieldModel !== null && this.fieldModel !== undefined && this.fieldModel.length > 0 &&
                                    this.fieldAreaByYear(this.fieldModel[0]) <= parseFloat(this.areaModel)) {
                                    formHasErrors = true;
                                    this.areaErrors = ['Ohutusala tõttu peab töö pindala olema väiksem, kui põllu kogupindala (' + this.fieldAreaByYear(this.fieldModel[0]) +' ha).']
                                }
                            }
                        }
                        if (requsite.amount === null || requsite.amount === undefined || requsite.amount === "") {
                            formHasErrors = true;
                        }
                    });
                }
                if (this.requiresWorkerName && (this.workerModel === null || !this.workerModel)) {
                    formHasErrors = true;
                    this.workerErrors = ['Kohustuslik väli']
                }
                if (this.requiresSoilSampler && (this.soilSamplerName === null || !this.soilSamplerName)) {
                    formHasErrors = true;
                    this.soilSamplerError = ['Kohustuslik väli']
                }

                if (this.$refs.inspectionsComponent) {
                    this.$refs.inspectionsComponent.validateFields();
                    if (this.inspectionsErrors) {
                        formHasErrors = true;
                    }
                }
                if (this.$refs.herdingComponent) {
                    this.$refs.herdingComponent.validateHerdings();
                    if (this.herdingErrors) {
                        formHasErrors = true;
                    }
                }
                if (this.$refs.landImprovementComponent) {
                    this.$refs.landImprovementComponent.validateLandImprovements();
                    if (this.landImprovementErrors) {
                        formHasErrors = true;
                    }
                }
                if (this.$refs.jobVariantComponent) {
                    this.$refs.jobVariantComponent.validateJobVariant();
                    if (this.jobVariantErrors) {
                        formHasErrors = true;
                    }
                }
                if (this.$refs.manureAmountComponent) {
                    this.$refs.manureAmountComponent.validateManure();
                    if (this.manureErrors) {
                        formHasErrors = true;
                    }
                }
                if (this.$refs.cropYieldModel) {
                    this.cropYieldErrors = this.errorsForExistingPositiveNumber(this.cropYieldModel);
                    if (this.cropYieldErrors) {
                        formHasErrors = true;
                    }
                }

                if (this.requiresFertilizationInfo && (this.fertilizationHighNutrientNModel === true)) {
                    this.fertilizationNutrientNErrors = this.errorsForExistingPositiveNumber(this.fertilizationNutrientNModel);
                    if (this.fertilizationNutrientNErrors) {
                        formHasErrors = true;
                    }
                }
                if (this.requiresFertilizationInfo && (this.fertilizationHighNutrientNModel === true) &&
                    (_.isNil(this.fertilizationSoilSampleYearModel) || this.fertilizationSoilSampleYearModel === '')) {
                    formHasErrors = true;
                    this.fertilizationSoilSampleYearErrors = ['Kohustuslik väli']
                }
                if (this.shouldShowCropWeight && (this.cropYieldUnitWeightModel === null || this.cropYieldUnitWeightModel === undefined || this.cropYieldUnitWeightModel === '')) {
                    formHasErrors = true;
                    this.cropYieldUnitWeightModelErrors = ['Kohustuslik väli']
                }

                if (this.requiresRequisite) {
                    if (this.jobCategoryModel !== 'Muu') {
                        this.requsiteBlocks.forEach(block => {
                            if (block.name === null || block.name === undefined) {
                                formHasErrors = true;
                                block.requisiteError = 'Kohustuslik välid'
                            } else if (block.name && block.name.length < 2) {
                                formHasErrors = true;
                                block.requisiteError = 'Nimi on liiga lühike'
                            } else {
                                block.requisiteError = null
                            }
                            if ((block.amount !== null && block.amount !== "" && block.amount !== undefined) &&
                                (block.unit === null || block.unit === undefined || block.unit === "")) {
                                formHasErrors = true;
                                block.requisiteUnitError = 'Kohustuslik väli'
                            } else {
                                block.requisiteUnitError = null
                            }

                            if (block.amount === null || block.amount === undefined || block.amount === "") {
                                formHasErrors = true;
                                block.requisiteAmountError = 'Kohustuslik väli'
                            } else {
                                block.requisiteAmountError = null
                            }

                            if (block.coating && block.coating.name &&
                                (block.coating.amount === null || block.coating.amount === undefined || block.coating.amount === '')) {
                                formHasErrors = true;
                                block.coatingAmountError = 'Kohustuslik väli'
                            } else {
                                block.coatingAmountError = null
                            }

                            if (block.coating && block.coating.name &&
                                (block.coating.amount !== null && block.coating.amount !== "" && block.coating.amount !== undefined) &&
                                (block.coating.unit === null || block.coating.unit === "" || block.coating.unit === undefined)) {
                                formHasErrors = true;
                                block.coatingUnitError = 'Kohustuslik väli'
                            } else {
                                block.coatingUnitError = null
                            }

                            if (block.type === REQUISITE_SEED.typeName && !block.seedCertificate) {
                                formHasErrors = true;
                            }

                            let coatingRequisiteCheckFailed = false
                            const currentDate = this.$store.getters.getCurrentDate;
                            if (block.coating && (block.coating.name || block.coating.amount || block.coating.unit) &&
                                currentDate.getFullYear() === (new Date(this.dateStart)).getFullYear()) { // Only check for current year. So its possible to change old events
                                const coatingRequisiteModel = _.find(this.stateCoatingChemicals, (e) => {
                                    return e._id === block.coating.requisiteId
                                }) ?? null;
                                coatingRequisiteCheckFailed = coatingRequisiteModel === null || coatingRequisiteModel === undefined
                            }

                            if (block.coating && (block.coating.name || block.coating.amount || block.coating.unit) &&
                                (block.coating.requisiteId === null || block.coating.requisiteId === undefined || coatingRequisiteCheckFailed)) {
                                formHasErrors = true;
                                block.coatingError = 'Puhtimisvahendit ei leitud. Valige uuesti'
                            } else {
                                block.coatingError = null
                            }

                            if (block.name !== null && block.name !== undefined && block.name !== "" && (block.type === null || block.type === "" || block.type === undefined)) {
                                formHasErrors = true;
                                block.requisiteTypeError = 'Tarviku olemasolul tuleb määrata ka tüüp'
                            } else {
                                block.requisiteTypeError = null
                            }
                            if ((block.type === REQUISITE_SEED.typeName || block.type === REQUISITE_FERTILIZER.typeName || (block.type === REQUISITE_CHEMICAL.typeName && block.subtypeVariant === 'TKV_REGISTRIST')) &&
                                (block.subtype === null || block.subtype === "" || block.subtype === undefined)) {
                                formHasErrors = true;
                                block.subTypeError = 'Kohustuslik väli'
                            } else {
                                block.subTypeError = null
                            }
                            if ((block.type === REQUISITE_CHEMICAL.typeName && block.subtypeVariant === 'TKV_REGISTRIST') && (block.ingredients === null || block.ingredients === undefined || block.ingredients.length === 0)) {
                                formHasErrors = true;
                                block.ingredientsError = 'Kohustuslik väli'
                            } else {
                                block.ingredientsError = null
                            }
                            if (block.type === REQUISITE_FERTILIZER.typeName && block.subtype !== FERTILIZER_TYPE.FERTILIZER && block.subtype !== FERTILIZER_TYPE.FERTILIZER_LIMESTONE) {
                                formHasErrors = true;
                                block.subTypeError = 'Kohustuslik väli'
                            } else {
                                block.subTypeError = null
                            }
                            if (block.type === REQUISITE_FERTILIZER.typeName && (block.subtypeVariant === null || block.subtypeVariant === undefined || block.subtypeVariant === '')) {
                                formHasErrors = true;
                                block.subtypeVariantError = 'Kohustuslik väli'
                            } else if (block.type === REQUISITE_CHEMICAL.typeName && (block.subtypeVariant === null || block.subtypeVariant === undefined || block.subtypeVariant === '')) {
                                formHasErrors = true;
                                block.subtypeVariantError = 'Kohustuslik väli'
                            }  else {
                                block.subtypeVariantError = null
                            }
                        });
                    } else {
                        this.requsiteBlocks.forEach(block => {
                            if (block.name !== null && block.name !== undefined && block.name !== "" && (block.type === null || block.type === "" || block.type === undefined)) {
                                formHasErrors = true;
                                block.requisiteTypeError = 'Tarviku olemasolul tuleb määrata ka tüüp'
                            } else {
                                block.requisiteTypeError = null
                            }
                        });

                    }
                }

                if (!formHasErrors) {
                    const hasMoreThanOneField = this.hasMoreThanOneField();
                    let fieldCountForYieldCalculation = this.fieldModel.length
                    let cropYieldDiscreteRemainingValue = this.cropYieldModel
                    let usedOneField = this.fieldModel.length === 1
                    let eventDifferenceWithPreviouslyAddedEvent = null
                    this.fieldModel.forEach(field => {
                        const area = hasMoreThanOneField ? this.fieldAreaByYear(field) : this.areaModel;
                        let jobEvent = {
                            fieldId: field._id,
                            status: this.jobStatusModel,
                            jobCategory: this.jobCategoryModel,
                            job: this.jobModel.name ? this.jobModel.name : this.jobModel,
                            area: area,
                            date: this.dateStart,
                            endDate: this.dateEnd,
                            worker: null,
                            requisite: null,
                            notes: null,
                            images: this.imagesModel.filter(image => image !== null)
                        };
                        if (this.isModifingExisting()) {
                            jobEvent._id = this.jobEventToEdit._id;
                        }
                        if (this.workerModel !== null) {
                            jobEvent.worker = this.workerModel.name;
                        }
                        if (this.soilSamplerName !== null && this.requiresSoilSampler) {
                            jobEvent.worker = this.soilSamplerName;
                        }
                        if (this.jobVariantModel !== null && this.requiresJobVariant) {
                            jobEvent.variant = this.jobVariantModel;
                        }
                        if (this.notesModel !== null) {
                            jobEvent.notes = this.notesModel;
                        }
                        if (this.herdingsModel && this.herdingsModel.length > 0 && this.requiresHerding) {
                            jobEvent.herding = _.cloneDeep(this.herdingsModel);
                            if (hasMoreThanOneField) {
                                const percentage = area / this.totalFieldArea;
                                jobEvent.herding.forEach(herding => {
                                    herding.hoursPerDay = _.round(herding.hoursPerDay * percentage, 2);
                                });
                            }
                        }
                        if (this.jobAmountModel !== null && this.jobAmountModel !== undefined && this.jobAmountModel !== '') {
                            jobEvent.amount = this.jobAmountModel;
                        }
                        if (this.jobUnitModel !== null && this.jobUnitModel !== undefined && this.jobUnitModel !== '') {
                            jobEvent.unit = this.jobUnitModel;
                        }

                        if (this.cropYieldModel !== null && this.cropYieldModel !== undefined && this.cropYieldModel !== ''
                            && this.shouldShowCropYield) {
                            if (fieldCountForYieldCalculation === 1) {
                              jobEvent.yield = cropYieldDiscreteRemainingValue;
                            } else {
                              const percentage = area / this.totalFieldArea;
                              const actualYield = _.round(this.cropYieldModel * percentage, 3);
                              cropYieldDiscreteRemainingValue -= actualYield;
                              jobEvent.yield = actualYield;
                              fieldCountForYieldCalculation--;
                            }
                            jobEvent.yieldUnit = this.cropYieldUnitsModel;
                            jobEvent.yieldType = this.mainCropYieldTypeWithDefaultValue;
                            if (this.shouldShowCropWeight) {
                                jobEvent.yieldUnitWeight = this.cropYieldUnitWeightModel;
                            } else {
                                jobEvent.yieldUnitWeight = null;
                            }
                        }
                        const fieldYearInfo = this.fieldYearInfo(field);
                        if (this.requsiteBlocks.length > 0 && this.requsiteBlocks[0] !== null &&
                            this.requsiteBlocks[0].name !== null && this.requsiteBlocks[0].name !== "") {
                            let jobModificationResults = [];
                            this.requsiteBlocks.forEach(requsite => {
                                const jobEventToSend = _.cloneDeep(jobEvent);
                                let inspections;
                                if (this.requiresInspections) {
                                    inspections = this.inspectionsModel;
                                }
                                jobEventToSend.requisite = {
                                    name: requsite.name.trim(),
                                    type: requsite.type,
                                    subtype: requsite.subtype,
                                    subtypeVariant: requsite.subtypeVariant,
                                    amount: requsite.amount,
                                    ingredients: requsite.ingredients,
                                    unit: requsite.unit,
                                    inspections: inspections,
                                    safetyRecord: requsite.safetyRecord,
                                    workDelayDays: requsite.workDelayDays,
                                    harvestDelayDays: requsite.harvestDelayDays,
                                    usageTimes: requsite.usageTimes,
                                    safetyArea: requsite.safetyArea,
                                    safetyWaterArea: requsite.safetyWaterArea,
                                    seedCertificate: requsite.seedCertificate,
                                    batchNo: requsite.batchNo,
                                    note: requsite.note,
                                    nutrient: requsite.nutrient,
                                    coating: requsite.coating,
                                };
                                if (requsite.type === REQUISITE_FERTILIZER.typeName && requsite.subtype === FERTILIZER_TYPE.FERTILIZER) {
                                    jobEventToSend.requisite.fertilization = {
                                        highNutrientN: this.fertilizationHighNutrientNModel,
                                        nutrientN: this.fertilizationHighNutrientNModel ? this.fertilizationNutrientNModel : null,
                                        soilSampleYear: this.fertilizationHighNutrientNModel ? this.fertilizationSoilSampleYearModel : null,
                                        fertilizationBeforeSow: this.fertilizationFertilizationBeforeSow
                                    }
                                }

                                if (this.fieldModel.length > 1 && this.shouldReduceAreaBySlopedArea(fieldYearInfo)) {
                                    jobEventToSend.area = this.fieldAreaByYear(field) - fieldYearInfo.slopedArea;
                                }
                                // Automatically convert job type if it was fertilizer with chemical
                                let fertilizingJobExists = this.findByName(this.$store.getters.jobCategories, 'Väetamine');
                                if (jobEventToSend.requisite.type === REQUISITE_FERTILIZER.typeName &&
                                    jobEvent.jobCategory === 'Taimekaitse' &&
                                    fertilizingJobExists !== undefined &&
                                    this.requsiteBlocks.length > 1) {
                                    jobEventToSend.jobCategory = "Väetamine";
                                    jobEventToSend.job = "Väetamine - pritsimine";
                                    jobEventToSend.requisite.inspections = null;
                                }
                                if (this.jobEventToEdit && this.jobEventToEdit._id) {
                                    let outcome = this.edit(jobEventToSend);
                                    jobModificationResults.push(outcome);
                                } else {
                                    let outcome = this.add(jobEventToSend);
                                    jobModificationResults.push(outcome);
                                }
                            });
                            Promise.all(jobModificationResults)
                                .then(() => {
                                    this.clearFormIfAllowed();
                                }).catch(() => {
                                    setTimeout(() => {
                                        this.loadingSubmit = false;
                                        this.disableSubmit = false;
                                    }, this.isFromJobManagementPage && !this.clearFormAfterSubmit ? 2500 : 1500);
                                });
                        } else {
                            if (this.jobEventToEdit && this.jobEventToEdit._id) {
                                this.edit(jobEvent)
                                    .then(() => {
                                        this.clearFormIfAllowed();
                                    }).catch(() => {
                                        setTimeout(() => {
                                            this.loadingSubmit = false;
                                            this.disableSubmit = false;
                                        }, this.isFromJobManagementPage && !this.clearFormAfterSubmit ? 2500 : 1500);
                                    })
                            } else {
                                if (usedOneField) { // Detecting if same job was separatly added under several fields. If so, then show notification
                                    eventDifferenceWithPreviouslyAddedEvent = _.reduce(jobEvent, (result, value, key) => {
                                        if (["fieldId", "area"].includes(key)) {
                                            return result; // Ignore fieldId and area changes
                                        }
                                        return _.isEqual(value, this.previouslyAddedJobEvent[key]) ?
                                            result : result.concat(key);
                                    }, []);
                                }
                                this.add(jobEvent)
                                    .then(() => {
                                        this.clearFormIfAllowed();
                                    }).catch(() => {
                                        setTimeout(() => {
                                            this.loadingSubmit = false;
                                            this.disableSubmit = false;
                                        }, this.isFromJobManagementPage && !this.clearFormAfterSubmit ? 2500 : 1500);
                                    })
                            }
                        }
                        this.previouslyAddedJobEvent = jobEvent
                    });
                    if (eventDifferenceWithPreviouslyAddedEvent !== null &&
                        eventDifferenceWithPreviouslyAddedEvent.length === 0 &&
                        this.shouldShowUseMultipleFieldsNotification()) {
                        this.showUseMultipleFieldsNotification()
                    }
                    setTimeout(() => {
                        this.$store.dispatch('loadStatus');
                    }, 1000);
                } else {
                    this.$store.dispatch('setSystemMessage', {text: "Kontrollige andmeid!", type: "ERROR", timeout: 4000});
                    setTimeout(() => {
                        this.loadingSubmit = false;
                        this.disableSubmit = false;
                    }, 1000);
                }

            },
            clearFormIfAllowed() {
                this.loadingSubmit = false;
                setTimeout(() => {
                    this.disableSubmit = false;
                }, this.isFromJobManagementPage && !this.clearFormAfterSubmit ? 2500 : 1500);
                if (this.clearFormAfterSubmit) {
                    this.clearForm();
                }
            },
            addAdditionalRequisite() {
                this.requsiteBlocks.push(_.cloneDeep(blankRequisites));
            },
            isDefined: function (something) {
                return something !== null && something !== undefined
            },
            removeRequisite(indexToRemove) {
                this.requsiteBlocks.forEach((requsite, index) => {
                    this.$refs[requsite][index].$emit('updateRequisite');
                });
                this.requsiteBlocks.splice(indexToRemove, 1);
                this.types.splice(indexToRemove, 1);
                this.subtypes.splice(indexToRemove, 1);
                this.virtualRequisites.splice(indexToRemove, 1);
                this.deleteInspection(indexToRemove)
            },
            recalculateCropYieldValues() {
                let yieldUnits = new Set();
                const hasMoreThanOneField = this.hasMoreThanOneField();
                this.totalFieldArea = 0;
                this.fieldModel.forEach(field => {
                    const fieldYearInfo = this.fieldYearInfo(field); // Can be undefined if field is just random string and not existing field object
                    const fieldMaxArea = fieldYearInfo === undefined ? 0 : fieldYearInfo.area
                    const area = hasMoreThanOneField ? (fieldMaxArea) : this.areaModel;

                    this.totalFieldArea += area;
                    const units = fieldYearInfo === undefined || fieldYearInfo?.mainCrop === undefined ? undefined: fieldYearInfo?.mainCrop.yieldUnit;
                    if (units && units.length > 0) {
                        yieldUnits.add(units)
                    }
                })
                if (this.isCreatingNew()) {
                    if (yieldUnits.size === 1) {
                        if (this.cropYieldUnitsModel === null || this.cropYieldUnitsModel === undefined || this.cropYieldUnitsModel === "") {
                            this.cropYieldUnitsModel = yieldUnits.values().next().value.replace(/\/ha$/, '');
                        }
                    } else {
                        if (this.cropYieldUnitsModel === null || this.cropYieldUnitsModel === undefined || this.cropYieldUnitsModel === "") {
                            this.cropYieldUnitsModel = "t"
                        }
                    }
                }
            },

            recalculateAreas(allowAreaReduction = true) {
                if (this.hasMoreThanOneField()) { // can always be updated because user could not change it
                    const totalArea = this.fieldModel.map(field => {
                        return this.fieldAreaByYear(field)
                    }).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    const totalAreaWithReductions = this.fieldModel.map(field => {
                        return this.recalculateAreasValue(field, allowAreaReduction);
                    }).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                    this.areaModel = totalAreaWithReductions;
                    const areaReduction = _.round(totalArea - totalAreaWithReductions, 2);
                    if (areaReduction > 0) {
                        this.areaReductionMessage = "Kallakute tõttu vähendati pindala " + areaReduction +" ha võrra"
                    } else {
                        this.areaReductionMessage = null
                    }
                } else if (this.fieldModel && this.fieldModel.length > 0) {
                    if (this.isCreatingNew()) {
                        const onlySingleField = this.fieldModel[0];
                        if (this.areaWasAutomaticallySet) {
                            this.areaModel = this.recalculateAreasValue(onlySingleField, allowAreaReduction);
                            setTimeout(() => {
                                this.areaWasAutomaticallySet = true // Delay the reset so that areaModel watch can execute
                            }, 120)
                        } else {
                            setTimeout(() => {
                                if (!this.areaWasAutomaticallySet) {
                                    this.areaReductionMessage = null
                                }
                            }, 140)
                        }
                    }
                }
            },
            recalculateAreasValue(field, allowAreaReduction = true) {
                const fieldYearInfo = this.fieldYearInfo(field);
                if (this.shouldReduceAreaBySlopedArea(fieldYearInfo) && allowAreaReduction) {
                    const areaReduction = fieldYearInfo.slopedArea
                    this.areaReductionMessage = "Kallakute tõttu vähendati pindala " + areaReduction +" ha võrra"
                    return this.fieldAreaByYear(field) - areaReduction;
                } else {
                    this.areaReductionMessage = null
                    return this.fieldAreaByYear(field);
                }
            },
            resetAreaWithoutReduction() {
                this.recalculateAreas(false)
            },
            clearForm() {
                this.jobCategoryModel = null;
                this.jobModel = null;
                this.requsiteBlocks = [];
                this.initialInspectionsForRequisites = [];
                this.types = [];
                this.subtypes = [];
                this.virtualRequisites = [];
                this.inspectionsModel = null;
                this.herdingsModel = null;
                this.jobAmountModel = null;
                this.jobUnitModel = null;
                this.jobStatusModel = 'DONE';
                this.imagesModel = [];
                this.fertilizationHighNutrientNModel = null;
                this.fertilizationNutrientNModel = null;
                this.fertilizationSoilSampleYearModel = null;
                this.fertilizationFertilizationBeforeSow = null;
                this.cropYieldModel = null;
                this.workerModel = null;
                this.soilSamplerName = null;
                this.cropYieldUnitWeightModel = null;
                this.jobVariantModel = null;
                this.areaReductionMessage = null
                // Commented out to test how about we dont clear dates on for submission
                // let currentDate = new Date();
                // if (this.yearNumber) {
                //     currentDate.setFullYear(this.yearNumber)
                // }
                // const currentDateString = currentDate.toISOString().substring(0, 10);
                //this.date = [currentDateString, currentDateString];
                if (!this.fieldSpecificJobs) {
                    this.fieldModel = [];
                    this.areaModel = null;
                }
                setTimeout(() => {
                    this.areaWasAutomaticallySet = true // Delay the reset so that areaModel watch can execute
                }, 120);
            },
            edit(jobEvent) {
                return this.$store.dispatch('editJobEvent', jobEvent).then(() => {
                    this.$emit('closeModal');
                    this.$store.dispatch('loadLatelyAddedJobEvents');
                    this.$store.dispatch('setSystemMessage', {text: "Edukalt muudetud!"});
                    return Promise.resolve()
                }).catch((e) => {
                    this.$store.dispatch('setSystemMessage', {text: "Muutmine ebaõnnestus!", type: "ERROR", timeout: 5000});
                    return Promise.reject(e);
                })
            },
            add(jobEvent) {
                const regularHandling = !this.isFromJobManagementPage || this.clearFormAfterSubmit;
                return this.$store.dispatch('addJobEvent', jobEvent).then(() => {
                    if (regularHandling) {
                        this.$emit('closeModal');
                    } else {
                        this.$emit('saveEvent')
                    }
                    this.scrollTop();
                    this.$store.dispatch('loadLatelyAddedJobEvents');
                    this.$store.dispatch('setSystemMessage', {text: "Edukalt salvestatud!", isTop: regularHandling, timeout: regularHandling ? undefined : 2500});
                    if (jobEvent.requisite !== null && this.hasNoRequisites(jobEvent.requisite.type) && this.$store.getters.getShouldShowAddRequisitesNotification) {
                        this.showAddRequisitesNotification()
                    }
                    return Promise.resolve()
                }).catch((e) => {
                    this.$store.dispatch('setSystemMessage', {text: "Salvestamine ebaõnnestus!", type: "ERROR", timeout: 5000, isTop: regularHandling});
                    return Promise.reject(e);
                });
            },
            warningIcon(type) {
                return warningIcon(type)
            },
            warningIconColor(type) {
                return warningIconColor(type)
            },
            scrollTop: function () {
                if (this.$vuetify.breakpoint.smAndDown && !this.fieldSpecificJobs) {
                    let options = Object.assign({}, ANIMATION.SCROLL);
                    this.$vuetify.goTo(0, options);
                }
            },
            scroll: function (e) {
                if (this.$vuetify.breakpoint.smAndDown) {
                    let options = Object.assign({}, ANIMATION.SCROLL);
                    if (this.fieldSpecificJobs) {
                        options.container = '.jobManagementModal'
                    }
                    this.$vuetify.goTo(e.target, options);
                }
            },
            addNewWorker() {
                this.showWorkerManagementModal = true;
            },
            showUseMultipleFieldsNotification() {
                setTimeout(() => {
                    this.$confirm("Sama tööd on võimalik <strong>korraga lisada ka mitmele põllule</strong>. <br />" +
                        "Selleks tuleks peale ühe põllu valimist avada uuesti põldude nimekiri ja " +
                        "valida lisaks teised põllud, kus samal päeval tehti täpselt sama töö.", {
                        title: 'Lisage töökanne korraga mitmele põllule',
                        buttonTrueText: 'Selge',
                        buttonFalseText: null,
                        icon: 'info',
                    }).then(() => {
                        this.doNotShowUseMultipleFieldsNotification()
                    })
                }, 3000);
            },
            cropColor(field) {
                const cropName = this.cropName(field)
                if (cropName) {
                    const color = this.cropColors[cropName.trim()];
                    return color !== '#e1e1e1' ? color : 'transparent';
                } else {
                    return 'transparent';
                }
            },
            cropName(field) {
                if (typeof field === 'string') { // when selecting new field its initially ID as string
                    field = this.fields.find(f => f._id === field);
                }
                const fieldYearInfo = this.fieldYearInfo(field);
                if (fieldYearInfo.mainCrop?.crop) {
                    return fieldYearInfo.mainCrop.crop.trim()
                } else {
                    return fieldYearInfo.mainCrop?.crop;
                }
            },
            fieldSelectionFromMap(fields) {
                this.showMapModel = false;
                this.fieldModel = fields.map(fieldId => {
                    return this.fields.find(field => field._id === fieldId)
                });
            },
            closeAllPopups() {
                this.showMapModel = false;
                this.$emit('closeModal');
            },
            selectFieldsByCrop(expectedCropName) {
                const currentFieldModel = this.fieldModel;
                this.fieldModel = _.filter(this.fields, field => {
                    return _.some(currentFieldModel, f => f._id === field._id) || this.cropName(field._id) === expectedCropName
                });
            },
        }
    }
</script>