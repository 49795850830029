import {http} from "../../config/http.js"


const state = {
    stock: [],

};

const mutations = {
    SET_STOCK(state, stock) {
        state.stock = stock
    },
};
let lastStockQueryParams = undefined;
const actions = {
    loadStock({commit}, queryParams) {
        if (queryParams) {
            lastStockQueryParams = queryParams;
        }
        if (lastStockQueryParams === undefined) {
            return
        }
        return http().get('/stock', { params: lastStockQueryParams })
            .then(r => r.data.data)
            .then(fields => {
                commit('SET_STOCK', fields)
            })
    },
    addStockAdjustment({}, newAdjustment) {
        return http().post('/stock', newAdjustment)
            .then(r => r.data.data)
    },
    updateStockAdjustment({}, newAdjustment) {
        return http().put('/stock/'+newAdjustment._id, newAdjustment)
            .then(r => r.data.data)
    },
    deleteStockAdjustment({}, stockAjustmentId) {
        return http().delete('/stock/'+ stockAjustmentId)
            .then(r => r.data.data)
    },
};

const getters = {
    getStock: state => {
        return state.stock
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}