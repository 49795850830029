

module.exports = {
    phosphorusOxideToElement: (oxideValue) => {
        if (oxideValue !== null) {
            return (Math.round((parseFloat(oxideValue) * 0.437) * 100)) / 100;
        }
    },
    potassiumOxideToElement: (oxideValue) => {
        if (oxideValue !== null) {
            return (Math.round((parseFloat(oxideValue) * 0.83) * 1000)) / 1000;
        }
    }
}