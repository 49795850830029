<template>
    <v-row no-gutters class="mb-3">
        <v-col
            class="col-12 col-md-3">
            <v-text-field
                :prepend-icon="mdiMathNormBox()"
                v-model="amount"
                type="number"
                min="0"
                data-cy="job-amount-input"
                :suffix="unit"
                label="Töö maht"
                :error-messages="amountErrors"
            />
        </v-col>
    </v-row>
</template>
<script>

import {mdiMathNormBox} from "@mdi/js";
import {provideCloseDropdown, provideRequiredPositiveNumber} from "@/mixins/mixins";

export default {
    components: {},
    props: {
        jobModel: {
            type: Object,
        },
        initialAmount: {
        },
        initialUnit: {
        },
        isCreatingNew: {
            type: Boolean,
            default: () => false
        },
        area: {
            type: Number,
            default: () => 0
        }
    },
    data() {
        return {
            amount: null,
            unit: null,
            amountErrors: [],
            unitErrors: [],
        }
    },
    mixins: [provideCloseDropdown, provideRequiredPositiveNumber],
    computed: {},
    methods: {
        mdiMathNormBox() {
            return mdiMathNormBox
        },
        validateLandImprovements() {
            this.amountErrors = this.errorsForExistingPositiveNumber(this.amount);
            this.$emit('validate-land-improvement', this.amountErrors !== null)
        },
    },
    watch: {
        initialAmount(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.amount = newVal;
            }
        },
        initialUnit: {
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.unit = newVal;
                }
            },
            deep: true,
            immediate: true
        },
        amount(newVal) {
            this.amountErrors = null
            this.$emit('update:initialAmount', newVal);
        },
        unit(newVal) {
            this.$emit('update:initialUnit', newVal);
        },
        jobModel: {
            handler: function (newVal) {
                if (newVal) {
                    switch (newVal.name.toLowerCase().trim()) {
                        case "veejuhtmest (kraavist/eesvoolust) takistuste eemaldamine":
                            this.unit = "m"
                            break;
                        case "veejuhtmel niitmine":
                            this.unit = "ha"
                            break;
                        case "veejuhtmest sette eemaldamine":
                            this.unit = "m3/m"
                            break;
                        case "puittaimestiku eemaldamine sh kändude eemaldamine veejuhtmelt":
                            this.unit = "ha";
                            break;
                        case "drenaažirikke kõrvaldamine":
                            this.unit = "tk";
                            break;
                        case "drenaažisuudme settest puhastamine":
                            this.unit = "tk";
                            break;
                        case "truubi settest puhastamine":
                            this.unit = "m";
                            break;
                        case "drenaaži suudme korrastamine":
                            this.unit = "tk";
                            break;
                        case "drenaažikaevu korrastamine":
                            this.unit = "tk";
                            break;
                        case "drenaažialalt puittaimestiku eemaldamine":
                            this.unit = "ha";
                            break;
                        case "keskkonnakaitserajatise rajamine":
                            this.unit = "tk";
                            break;
                        case "keskkonnakaitserajatise settest puhastamine":
                            this.unit = "m3/m";
                            break;
                        case "drenaaži läbipesemine":
                            this.unit = "km";
                            break;
                        default:
                            this.unit = null
                    }
                } else {
                    this.unit = null
                }
            },
            deep: true,
            immediate: true
        },


    },
    created() {
        this.amount = this.initialAmount;
    },
}
</script>

