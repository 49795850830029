import _ from "lodash";
import {http} from "../../config/http.js"

const state = {
    jobEvents: [],
    latelyAddedJobEvents: [],
    newLatelyAddedJobEventIds: [],
    lastAddedJobEventDate: undefined,
    currentFieldId: "",
    seasonStart: undefined,
    seasonEnd: undefined
};

const mutations = {
    SET_EVENTS(state, jobEvents) {
        state.jobEvents = jobEvents.jobEvents;
        state.seasonStart = new Date(jobEvents.seasonStart);
        state.seasonEnd = new Date(jobEvents.seasonEnd);
    },
    ADD_JOB_EVENT(state, newJobEvent) {
        if (state.currentFieldId === newJobEvent.fieldId &&
            state.seasonStart <= new Date(newJobEvent.date) &&
            state.seasonEnd > new Date(newJobEvent.date)
        ) {
            state.jobEvents.push(newJobEvent)
        }
    },
    EDIT_JOB_EVENT(state, changedJobEvent) {
        const index = _.findIndex(state.jobEvents, _.pick(changedJobEvent, "_id"));
        // For "Lately added job events" the this.jobEvents list is empty. So index will be -1. Can be skipped
        if (index >= 0 && state.jobEvents[index].fieldId === changedJobEvent.fieldId) { // Stayed on same field
            state.jobEvents.splice(index, 1, changedJobEvent);
        } else {
            state.jobEvents = removeJobEvent(state, changedJobEvent._id)
        }
    },
    REMOVE_JOB_EVENT(state, jobEventIdToRemove) {
        state.jobEvents = removeJobEvent(state, jobEventIdToRemove)
        state.newLatelyAddedJobEventIds = [];
    },
    CLEAR_EVENTS(state) {
        state.jobEvents = [];
    },
    LOAD_LATELY_ADDED_JOB_EVENTS(state, events) {
        let latelyAddedJobEventIds = state.latelyAddedJobEvents.map(e => e._id);
        const eventIds = events.map(e => e._id);
        const currentTime = new Date().getTime();
        if (state.lastAddedJobEventDate === undefined) {
            latelyAddedJobEventIds = eventIds
        }
        if (state.lastAddedJobEventDate === undefined || currentTime - state.lastAddedJobEventDate > 1000) {
            state.newLatelyAddedJobEventIds = [];
        }
        state.lastAddedJobEventDate = currentTime;
        const newJobEvents = eventIds.filter(id => !latelyAddedJobEventIds.includes(id));
        if (newJobEvents.length !== 1 || newJobEvents[0] !== _.last(eventIds)) {
            setTimeout(() => {
                state.newLatelyAddedJobEventIds.push(...newJobEvents);
            }, 100)
        }
        state.latelyAddedJobEvents = events;
    },
    CLEAR_NEW_LATELY_ADDED(state) {
        state.newLatelyAddedJobEventIds = [];
    },
    RESET_NEW_LATELY_ADDED(state) {
        state.lastAddedJobEventDate = undefined;
    },
    CURRENT_FIELD(state, fieldId) {
        state.currentFieldId = fieldId;
        state.jobEvents = _.filter(state.jobEvents, job => job.fieldId === fieldId)
    },
};

function removeJobEvent(state, jobEventIdToRemove) {
    return _.filter(state.jobEvents, (jobEvent) => {
        return jobEvent._id !== jobEventIdToRemove;
    })
}

const actions = {
    loadJobEvents({commit}, {fieldId, year}) {
        commit('CLEAR_EVENTS');
        commit('CURRENT_FIELD', fieldId);
        http().get('/fields/' + fieldId + "/" + year + "/events")
            .then(r => r.data.data)
            .then(jobEvents => {
                commit('SET_EVENTS', jobEvents)
            }).catch(() => {})
    },
    loadJobEventsWithoutClearing({commit}, {fieldId, year}) {
        http().get('/fields/' + fieldId + "/" + year + "/events")
            .then(r => r.data.data)
            .then(jobEvents => {
                commit('SET_EVENTS', jobEvents)
            });
    },
    resetNewLatelyAddedJobEvents({commit}) {
        commit('RESET_NEW_LATELY_ADDED')
    },
    addJobEvent({commit, state, dispatch}, jobEvent) {
        commit('CLEAR_NEW_LATELY_ADDED')
        return http().post('/job-events', jobEvent)
            .then(r => r.data.data)
            .then(response => {
                commit('ADD_JOB_EVENT', response)
                if (state.currentYear !== new Date(response.date).getFullYear()){
                    dispatch('loadAllFields');
                }
            })
    },
    editJobEvent({commit, state, dispatch}, jobEvent) {
        return http().put('/job-events/' + jobEvent._id, jobEvent)
            .then(r => r.data.data)
            .then(response => {
                commit('EDIT_JOB_EVENT', response)
                if (state.currentYear !== new Date(response.date).getFullYear()){
                    dispatch('loadAllFields');
                }
            })
    },
    removeJobEvent({commit}, jobEventId) {
        return http().delete('/job-events/' + jobEventId)
            .then(() => {
                commit('REMOVE_JOB_EVENT', jobEventId)
            })
    },
    loadLatelyAddedJobEvents({commit}) {
        return http().get('/job-events/lastAdded')
            .then(r => r.data.data)
            .then((events) => {
                commit('LOAD_LATELY_ADDED_JOB_EVENTS', events)
            })
    },
    // eslint-disable-next-line no-unused-vars
    findJobEventsByRequsiteName({commit}, requisiteName) {
        return http().get('/job-events', { params: { requisiteName: requisiteName } })
            .then(r => r.data.data)
            .then((jobEvents) => {
                return jobEvents
            })
    },
    // eslint-disable-next-line no-unused-vars
    updateJobEventRequisites({commit}, payload) {
        return http().post('/job-events/requisites', payload)
            .then(r => r.data.data)
            .then((jobEvents) => {
                return jobEvents
            })
    }
};

const getters = {
    jobEvents: state => {
        return state.jobEvents
    },
    latelyAddedJobEvents: state => {
        return state.latelyAddedJobEvents;
    },
    getNewLatelyAddedJobEvents: state => {
        const newLatelyAddedJobEventIds = state.newLatelyAddedJobEventIds;
        const newLatelyAddedEvents = state.latelyAddedJobEvents.filter(jobEvent => newLatelyAddedJobEventIds.includes(jobEvent._id));
        const newLatelyAddedEventIds = _.map(newLatelyAddedEvents, e => e._id);
        return newLatelyAddedEventIds;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}