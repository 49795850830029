import {http} from "../../config/http.js"

const state = {
    fertilizationPlan: null,
};

const mutations = {
    SET_FERTILIZATION_PLAN(state, fertilizationPlan) {
        state.fertilizationPlan = fertilizationPlan
    },
};

const actions = {
    loadFertilizationPlan({commit}, year) {
        return http().get('/fertilization-plans', { params: year})
            .then(r => r.data.data)
            .then(fertilizationPlan => {
                commit('SET_FERTILIZATION_PLAN', fertilizationPlan)
            })
    },
    saveFertilizationPlan({commit}, data) {
        return http().post('/fertilization-plans', data)
            .then(r => r.data.data)
            .then(fertilizationPlan => {
                commit('SET_FERTILIZATION_PLAN', fertilizationPlan)
            })
    },
    updateFertilizationPlan({commit}, data) {
        return http().put('/fertilization-plans/' + data._id, data)
            .then(r => r.data.data)
            .then(fertilizationPlan => {
                commit('SET_FERTILIZATION_PLAN', fertilizationPlan)
            })
    },
    clearFertilizationPlan({commit}) {
        commit('SET_FERTILIZATION_PLAN', null)
    },
    deleteFertilizationPlan({commit}, year) {
        return http().delete('/fertilization-plans/' + year)
            .then(r => r.data.data)
            .then(fertilizationPlan => {
                commit('SET_FERTILIZATION_PLAN', fertilizationPlan)
            })
    },
};

const getters = {
    getFertilizationPlan: state => {
        return state.fertilizationPlan
    },

};

export default {
    state,
    getters,
    actions,
    mutations
}