import {http} from "../../config/http.js"

const state = {
    fields: null
};

const mutations = {
    SET_PRIA_FIELDS(state, importData) {
        state.fields = importData
    }
};

const actions = {
    uploadPriaFieldData({commit}, data) {
        return http().post('/import/pria-field-file', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(r => r.data)
            .then(r => r.data)
            .then(importData => {
                commit('SET_PRIA_FIELDS', importData)
            })
    },
    uploadSoilSampleData({}, data) {
        return http().post('/import/soil-samples', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(r => r.data)
            .then(importData => {
                return importData
            })
    },
    // eslint-disable-next-line no-unused-vars
    savePriaFieldData({commit}, data) {
        return http().post('/import/priaFields/data', data)
    },
    clearPriaFieldData({commit}) {
        commit('SET_PRIA_FIELDS', null)
    },
};

const getters = {
    getImportFields: state => {
        return state.fields
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}