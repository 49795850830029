import {http} from "../../config/http.js"

const state = {
    ingredients: [],
};

const mutations = {
    SET_INGREDIENTS(state, ingredients) {
        state.ingredients = ingredients
    },
};

const actions = {
    loadChemicalIngredientsIfNeeded({commit}) {
        if (state.ingredients.length === 0) {
            return http().get('/external-communication/agri/chemical-ingredients')
                .then(r => r.data.data)
                .then(ingredients => {
                    commit('SET_INGREDIENTS', ingredients)
                }).catch(() => {
                })
        }
    },
};

const getters = {
    getChemicalIngredients: state => {
        return state.ingredients
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}