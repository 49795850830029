<template>
    <v-card style="width: 100%; height: 100%">
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <all-fields-map
            :initialFieldIds="initialFieldIds"
            :isVisible=isVisible
            :date="date"
            :allowedToChange="allowedToChange"
            @close="close"
            @closeAllPopups="closeAllPopups"
            @fieldSelection="fieldSelection"
        />
    </v-card>
</template>
<script>

export default {
    props: {
        isVisible: {
            type: Boolean,
        },
        allowedToChange: {
            type: Boolean,
        },
        date: {
            type: String,
        },
        initialFieldIds: {
            type: Array,
            default: () => []
        }
    },
    components: {
        allFieldsMap: () => import("@/jobEvent/allFieldsMap.vue"),
    },
    methods:{
        close() {
            this.$emit('close')
        },
        closeAllPopups() {
            this.$emit('closeAllPopups')
        },
        fieldSelection(selectedFieldIds) {
            this.$emit('fieldSelection', selectedFieldIds);
        },
    }

};
</script>
