<template>
    <v-card-text class="pt-4 px-3 px-md-6">
        <slot></slot>
        <v-row no-gutters class="justify-center justify-md-end mt-8">
            <slot name="footer"></slot>
        </v-row>
    </v-card-text>
</template>
<script>
    export default {
        name:"info-modal-container-wrapper"
    }
</script>