import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VuetifyConfirm from 'vuetify-confirm'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueYouTubeEmbed from 'vue-youtube-embed'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'vuetify/dist/vuetify.min.css'
import * as Sentry from "@sentry/vue";
// import '@mdi/js'
import './stylus/main.styl'
import et from 'vuetify/es5/locale/et'
import {eventDate, toLocalNumber, toLocalRoundedNumber} from "../../dist/common/eventToTextHelper";
import { Integrations } from "@sentry/tracing";
import {toLocalRoundedNumberEuros} from "../../backend/common/eventToTextHelper";
//Vue.config.performance = true;
//Vue.config.devtools = true;
if (!Vue.config.devtools) {
    Sentry.init({
        Vue: Vue,
        dsn: "https://648476c2889c44fd9033539544aa6a4a@o364340.ingest.sentry.io/5747842",
        logErrors: true,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["xn--plluraamatu-ffb.abimasin.ee", /^\//],
            }),
        ],
        beforeSend(event, hint) {
            const error = hint.originalException;
            if (event.request &&
                event.request.url &&
                event.request.url.match(/.*ylevaade.*/i)) {
                return null;
            }
            if (
                error &&
                error.message &&
                error.message.match(/via a navigation guard|Navigation cancelled from|timeout of .* exceeded|request failed with status code 401|Request failed with status code 404/i)
            ) {
                return null;
            }
            return event;
        },
    });
}
Vue.use(Vuetify)
Vue.use(VueYouTubeEmbed, {global: false});
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBcZ_RnOIoDWpJFGEHWVjIYmwGkOPGkhp0',
        // key: 'AIzaSyDz0xmYS92fW8_O_99bzkrtFYm9AsEghCo',
        libraries: 'drawing', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
});

Vue.filter('toDateString', function (value) {
    return eventDate(value);
});
Vue.filter('toLocalNumber', function (value) {
    return toLocalNumber(value);
});
Vue.filter('toLocalRoundedNumber', function (value) {
    return toLocalRoundedNumber(value);
});

Vue.filter('toLocalRoundedNumberEuros', function (value) {
    return toLocalRoundedNumberEuros(value);
});

const vuetifyOpts = {
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#31a80b',
                // primary: '#992b82',
                secondary: '#31a80b',
                // secondary: '#992b82',
                chips: '#739568',
                secondaryButton: '#34a3c7',
                error: '#b72d2d',
                orangeWarning: '#ffa000',
                warning: '#0095e4'
            }
        }
    },
    lang: {
        locales: { et },
        current: 'et'
    },
    icons: {
        iconfont: 'mdiSvg',
    }
}
const vuetifyInstance = new Vuetify(vuetifyOpts);
new Vue({
    store,
    router,
    render: h => h(App),
    vuetify: vuetifyInstance
}).$mount('#app');

Vue.use(VuetifyConfirm, {
    buttonFalseText: 'Katkesta',
    color: '#298e09',
    icon: 'warning',
    buttonFalseColor: 'black',
    title: 'Hoiatus',
    width: 460,
    property: '$confirm',
    vuetify: vuetifyInstance
});