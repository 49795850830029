import _ from 'lodash'
import {REQUISITE_RESTRICTIONS} from "../../../dist/common/constants";

function notSameIgnoringUndefined(value1, value2) {
    return undefinedAsNull(value1) !== undefinedAsNull(value2);
}

function undefinedAsNull(value) {
    if (value === undefined || value === null || value === "") {
        return null;
    }
    return String(value);
}

function listChanges(requisite, jobEvent) {
    const propertiesToCompare = _.concat("name", _.values(REQUISITE_RESTRICTIONS));
    return _.filter(_.map(propertiesToCompare, property => {
        if (notSameIgnoringUndefined(requisite[property], jobEvent.requisite[property])) {
            return {property: property, initialValue: jobEvent.requisite[property], newValue: requisite[property]}
        } else {
            return null;
        }
    }), change => change !== null);
}

export const detectJobEventsWithChangedRequisites = (requisite, jobEventsToCheck) => {
    let jobEventsWithChanges = _.map(jobEventsToCheck, jobEvent => {
        return {
            jobEvent: jobEvent,
            changes: listChanges(requisite, jobEvent),
            include: true
        }
    });
    return _.filter(jobEventsWithChanges, jobEvent => jobEvent.changes.length > 0);
};
