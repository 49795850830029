<template>
    <!-- Add Dialog -->
    <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title class="grey lighten-4 text-h6" relative>
                <v-btn color="primary" elevation="0" small right fab absolute class="closeButton"
                       @click="$emit('close-modal')">
                    <v-icon>close</v-icon>
                </v-btn>
                {{title}}
            </v-card-title>
            <modal-container-wrapper>
                <v-row no-gutters>
                    <v-col>
                        <v-text-field
                                ref="workerName"
                                prepend-icon="account_circle"
                                v-model="worker.name" label="Töötaja nimi"
                                :rules="[v => !!v || 'Kohustuslik väli', v => !!v && v.length > 1 || 'Liiga lühike']"
                                required
                                data-cy="requisite-management-worker-name-input"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-text-field
                                prepend-icon="euro_symbol"
                                type="number"
                                min="0"
                                v-model="worker.hourlyRate" label="Tunnipalk (vajalik ainult tööaja arvestusel)"
                                data-cy="requisite-management-worker-hourly-rate-input"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-textarea
                            prepend-icon="notes"
                            auto-grow rows="3" v-model="worker.notes" label="Märkmed:"
                                    placeholder=""></v-textarea>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-checkbox color="primary" v-model="worker.chemicalCertification" label="Omab taimekaitsetunnistust"
                                                style="max-width: 260px"></v-checkbox>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="worker.chemicalCertification">
                    <v-col>
                      <v-menu
                          ref="chemicalWorkerLicenceDate"
                          v-model="chemicalWorkerLicenceDate"
                          :close-on-content-click="false"
                          nudge-right=32
                          nudge-top=20
                          transition="scale-transition"
                          min-width="290px"
                          offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                              v-on="on"
                              v-model="computedDateFormatted"
                              label="Taimekaitsetunnistuse kehtivuse lõpp"
                              prepend-icon="event"
                              readonly
                              :rules="[() => !!worker.chemicalWorkerLicenceEndDate && worker.chemicalWorkerLicenceEndDate.length > 3 || 'Kohustuslik väli']" required
                              data-cy="jobevent-date-input"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="worker.chemicalWorkerLicenceEndDate"
                            no-title
                            color="primary"
                            :key="chemicalWorkerLicenceDate"
                            first-day-of-week=1
                            locale="et-ee"
                        >
                          <div class="mt-n12 flex row">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="chemicalWorkerLicenceDate = false">sulge</v-btn>
                          </div>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                </v-row>
                <template #footer>
                    <v-btn color="primary" outlined class="mx-2" @click="$emit('close-modal')">Katkesta</v-btn>
                    <v-btn color="primary" @click="addOrUpdate(worker)" data-cy="requisite-management-worker-save-button">Salvesta</v-btn>
                </template>
            </modal-container-wrapper>
        </v-form>
    </v-card>
</template>

<script>
    import _ from "lodash";
    import modalContainerWrapper from "../components/modalContainerWrapper";
    export default {
        components: {
            modalContainerWrapper
        },
        props: {
            editTarget: {
                default: () => ({
                    chemicalCertification: true
                }), type: Object
            },
            visible: Boolean
        },
        data() {
            return {
              valid: true,
              worker: {},
              chemicalWorkerLicenceDate: false,
            }
        },
        computed: {
            title() {
                if (this.editTarget._id) {
                    return "Muuda töötajat: " + this.editTarget.name;
                } else {
                    return "Lisa uus töötaja"
                }
            },
            computedDateFormatted() {
              return this.formatDate(this.worker.chemicalWorkerLicenceEndDate)
            },
        },
        watch: {
            editTarget: {
                handler: function (initialData) {
                    this.worker = _.cloneDeep(initialData)
                    if (this.worker.chemicalWorkerLicenceEndDate) {
                      this.worker.chemicalWorkerLicenceEndDate = this.worker.chemicalWorkerLicenceEndDate.substring(0, 10)
                    }
                },
                deep: true,
                immediate: true
            },
            visible(isVisible) {
                if (isVisible) {
                    setTimeout(() => {
                        this.$refs.workerName.onFocus();
                        this.$refs.workerName.errorBucket = []
                    });
                }
                if (!this.editTarget) {
                    this.$refs.form.reset();
                }
            },
        },
        methods: {
            addOrUpdate(data) {
                if (this.$refs.form.validate()) {
                    if (this.editTarget._id) {
                        this.edit(data)
                    } else {
                        this.add(data)
                    }
                }
            },
            add(data) {
                this.$store.dispatch('addWorker', data).then(() => {
                    this.$emit('close-modal');
                    this.$store.dispatch('setSystemMessage', {text: "Edukalt salvestatud!"});
                    this.$store.dispatch('loadStatus');
                })
            },
            edit(data) {
                this.$store.dispatch('editWorker', data).then(() => {
                    this.$emit('close-modal');
                    this.$store.dispatch('setSystemMessage', {text: "Edukalt muudetud!"});
                    this.$store.dispatch('loadStatus');
                })
            },
          formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.substring(0, 10).split('-');
            return `${day}.${month}.${year}`
          },
        },
        created() {
            setTimeout(() => {
                this.$refs.workerName.onFocus();
                this.$refs.workerName.errorBucket = []
            });
        }
    }
</script>