import {http} from "../../config/http.js"

const state = {
    cropRotations: null,
};

const mutations = {
    SET_CROP_ROTATIONS(state, cropRotations) {
        state.cropRotations = cropRotations
    },
};

const actions = {
    loadCropsRotation({commit}) {
        return http().get('/crops-rotation')
            .then(r => r.data.data)
            .then(cropRotations => {
                commit('SET_CROP_ROTATIONS', cropRotations)
            })
    },
    loadCropsRotationYears({commit}, years) {
        return http().get('/crops-rotation', { params: years})
            .then(r => r.data.data)
            .then(cropRotations => {
                commit('SET_CROP_ROTATIONS', cropRotations)
            })
    },
    clearCropsRotation({commit}) {
        commit('SET_CROP_ROTATIONS', null)
    },
};

const getters = {
    getCropsRotation: state => {
        return state.cropRotations
    },

};

export default {
    state,
    getters,
    actions,
    mutations
}