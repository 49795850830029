<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="jobEvents"
                hide-default-footer
                hide-default-header
                class="elevation-0"
                mobile-breakpoint="0"
                :class="{'compactTable': $vuetify.breakpoint.xs}"
                style="position:relative"
                transition="slide-x-transition"
        >
            <template v-slot:body="props">
                <tbody name="fade" is="transition-group">
                <template>
                <tr v-for="(item) in props.items" :key="item._id" :style="$vuetify.breakpoint.xs ? 'vertical-align: top' : ''" data-cy="last-added-jobevent" :class="justAdded(item) ? 'justAddedJob' : 'normalLatelyAddedJob'">
                    <td style="width:0">
                        <span v-html="jobEventDate(item)"></span>
                        <v-row no-gutters v-if="!$vuetify.breakpoint.smAndUp" style="padding-left: 1px" class="flex-nowrap">
                            <v-btn color="primary" style="min-width: 40px"
                                   class="elevation-0 px-0 ml-0 mr-0" @click="deleteItem(item)">
                                <v-icon>delete_forever</v-icon>
                            </v-btn>
                            <v-btn color="primary" style="min-width: 40px" class="elevation-0 px-2 mr-0"
                                   @click="editItem(item)">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </v-row>
                    </td>
                    <td @click="openField(item)"
                        style="width:30%"
                        class="primary--text font-weight-bold linkLike text-subtitle-2 text-sm-subtitle-1">{{
                        fieldName(item)}}
                    </td>
                    <td :style="hasSafety(item) ? 'cursor:pointer': ''"
                        style="width:70%"
                        class="py-2">
                        <span class="mr-2">{{description(item)}}</span>
                        <v-chip
                            v-if="item.status !== 'DONE'"
                            class="px-2 job-status-chip"
                            style="border-width: 2px !important"
                            outlined
                            :color="item.status === 'DONE' ? 'primary' : (item.status === 'PLANNED' ? 'deep-purple lighten-1' : 'light-blue darken-2')"
                            small
                        >
                            {{statusText(item)}}
                        </v-chip>
                        <requsiteRestrictions :event="item" class="ml-n3 ml-sm-0"/>
                    </td>
                    <td v-if="$vuetify.breakpoint.smAndUp" class="justify-center px-0 text-no-wrap"
                        style="display: table-cell; text-align: center;">
                        <editButton title="Redigeeri" :clickAction="() => editItem(item)" data-cy="last-added-jobevent-edit"></editButton>
                        <deleteButton title="Kustuta"
                                      :clickAction="() => deleteItem(item)"></deleteButton>
                    </td>
                </tr>
                </template>
                </tbody>
                <tr v-if="!props.items.length">
                    <td colspan="10">
                        <v-subheader>
                            Ajalugu puudub.
                        </v-subheader>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" content-class="jobManagementModal"
                  v-model="showEditModal" absolute max-width="800px">
            <jobEventManagementModal @closeModal="close()" :field="jobEventField" :jobEventToEdit=jobEventToEdit>
            </jobEventManagementModal>
        </v-dialog>
    </div>
</template>

<script>
    import requsiteRestrictions from "../jobEvent/requsiteRestrictions.vue"
    import {findFieldYearInfoForDate} from "../../../dist/common/fieldYearInfo.js"
    import {eventDescriptionSimplified} from "../../../dist/common/eventToTextHelper";
    import editButton from "./editButton";
    import deleteButton from "./deleteButton";
    import {VBtn} from 'vuetify/lib'
    import jobEventManagementModal from "../jobEvent/jobEventManagementModal.vue"
    import {JOB_STATUS} from "../../../backend/common/constants";

    export default {
        props: {
            initialJobEvents: {
                type: Array,
            }
        },
        data() {
            return {
                headers: [
                    {
                        text: 'Kuupäev',
                        align: 'left',
                        sortable: true,
                        value: 'date'
                    }, {
                        text: 'Kirjeldus',
                        align: 'left',
                        sortable: true,
                        value: 'job'
                    },
                    {text: 'Pindala (ha)', value: 'area'}
                ],
                showEditModal: false,
                jobEventToEdit: null,
                jobEventField: null
            }
        },
        computed: {
            jobEvents() {
                if (this.initialJobEvents) {
                    return this.initialJobEvents
                } else {
                    return this.$store.getters.latelyAddedJobEvents
                }
            },
            newlyAddedJobEvents() {
                return this.$store.getters.getNewLatelyAddedJobEvents
            }
        },
        components: {
            requsiteRestrictions,
            editButton,
            deleteButton,
            // eslint-disable-next-line vue/no-unused-components
            VBtn,
            jobEventManagementModal
        },
        created() {
            this.initialize()
        },
        methods: {
            yearInfo(item) {
                return findFieldYearInfoForDate(this.$store.getters.field(item.fieldId), item.date);
            },
            justAdded(item) {
                return this.newlyAddedJobEvents.includes(item._id);
            },
            initialize: function () {
                this.$store.dispatch('loadLatelyAddedJobEvents');
            },
            openField(item) {
                const yearInfo = this.yearInfo(item);
                this.$router.push({name: 'jobEvents', params: {id: item.fieldId, year: yearInfo.year}})
            },
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('T')[0].split('-');
                return `${day}.${month}.${year}`
            },
            jobEventDate(jobEvent) {
              if (jobEvent.endDate && jobEvent.date !== jobEvent.endDate) {
                return this.formatDate(jobEvent.date) +"&nbsp;- "+ this.formatDate(jobEvent.endDate)
              } else if (jobEvent.endDate === null) {
                return this.formatDate(jobEvent.date) +"&nbsp;- ..."
              } else {
                return this.formatDate(jobEvent.date)
              }
            },
            fieldName(item) {
                if (this.$store.getters.field(item.fieldId)) {
                    return this.yearInfo(item).name
                } else {
                    return ""
                }
            },
            description(event) {
                return eventDescriptionSimplified(event)
            },
            hasSafety(event) {
                return event.requisite && event.requisite.safetyRecord;
            },
            deleteItem(item) {
                const $this = this;
                this.$confirm('Olete kindel, et soovite <strong>jäädavalt kustutada</strong> töökande "' + this.formatDate(item.date)+
                    ' ' + this.description(item) + '"? <br/>Seda sammu ei saa tagasi võtta!', {
                    title: 'Töökande kustutamine ',
                    buttonTrueText: 'Jah, kustuta!'
                })
                    .then((result) => {
                        if (result) {
                            $this.$store.dispatch('removeJobEvent', item._id).then(() => {
                                $this.$store.dispatch('loadLatelyAddedJobEvents');
                                $this.$store.dispatch('loadStatus');
                            });

                        }
                    });
            },
            editItem(jobEvent) {
                this.jobEventField = this.field(jobEvent.fieldId)
                this.jobEventToEdit = jobEvent;
                this.showEditModal = true;
            },
            field(fieldId) {
                return this.$store.getters.field(fieldId);
            },
            close() {
                this.showEditModal = false
            },
            statusText(item) {
                return JOB_STATUS.find(status => status.value === item.status).label
            }
        }


    }
</script>