<template>
    <div></div>
</template>

<script>
    export default {
        data: () => ({}),
        beforeCreate() {
            this.$store.dispatch('demoMode');
            this.$router.push('/lisa')
        }
    }
</script>