import axios from 'axios'
import store from '../store/store'
import {mockSetup, removeMock} from "./setupDemo";

let baseUrl = '//localhost:8080/api';
if (process.env.NODE_ENV === 'production') {
    baseUrl = '/api'
}

let shouldInitDemoMode = true;
export const http = () => {
    if (store.getters.isDemoMode && shouldInitDemoMode) {
        mockSetup();
        shouldInitDemoMode = false;
    }
    if (!store.getters.isDemoMode && !shouldInitDemoMode) {
        removeMock();
        shouldInitDemoMode = true;
    }
    let axiosInstance = axios.create({
        baseURL: baseUrl, //base URL goes here
        // credentials: 'same-origin',
        withCredentials : true,
        headers: {'X-CSRF-TOKEN': store.getters.csrfToken}
    });
    axiosInstance.defaults.timeout = 11000;
    axiosInstance.interceptors.response.use(null, (error) => {
        if (axios.isCancel(error)) {
            // Request was canceled, no need to handle it as an error
            return Promise.reject(error);
        }

        if (!error.response) {
            store.dispatch('setSystemMessage', {text: "Puudub internetiühendus!", type:"ERROR", timeout: 5000})
        }
        if (error.response && (error.response.status === 401 || error.response.status === 403) && error.config.url !== '/auth') {
            store.dispatch('setSystemMessage', {text: "Palun logige uuesti sisse!", type:"ERROR", timeout: 6000})
        }
        return Promise.reject(error);
    });
    return axiosInstance
};