<template>
    <!-- Add Dialog -->
    <v-card>
        <v-form ref="form">
            <v-card-title class="grey lighten-4 text-h6" relative>
                <v-btn color="primary" elevation="0" small right fab absolute class="closeButton"
                       @click="$emit('closeModal')">
                    <v-icon>close</v-icon>
                </v-btn>
                Võta ühendust
            </v-card-title>
            <info-modal-container-wrapper>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-textarea
                                color="primary"
                                label="Küsimus, soovitus, ettepanek, tagasiside ..."
                                v-model="message"
                                auto-grow
                                data-cy="feedback-text-input"
                        ></v-textarea>
                    </v-col>

                </v-row>
                <template #footer>
                    <v-btn color="primary" outlined class="mx-2" @click="$emit('closeModal')">Katkesta</v-btn>
                    <v-btn color="primary" @click="send" data-cy="feedback-submit-button" :disabled="disabled">Saada<v-icon right dark>mail</v-icon></v-btn>
                </template>
            </info-modal-container-wrapper>
        </v-form>
    </v-card>
</template>

<script>
    import infoModalContainerWrapper from "../infoModalContainerWrapper";

    export default {
        components: {
            infoModalContainerWrapper
        },
        data() {
            return {
                message: '',
                disabled: true
            }
        },
        computed: {
            worker() {
                return {}
            }
        },
        watch: {
            message(currentMessage) {
                this.disabled = !(currentMessage && currentMessage.length > 5);
            }
        },
        methods: {
            send() {
                this.disabled = true;
                let message = {
                    message: this.message
                };
                this.$store.dispatch('sendFeedback', message).then(() => {
                    this.$emit('closeModal');
                    this.$refs.form.reset();
                    this.$store.dispatch('setSystemMessage', {text: "Tagasiside saadetud! Aitäh!"});
                    this.disabled = false;
                }).catch(() => {
                    this.disabled = false;
                    this.$store.dispatch('setSystemMessage', {text: "Tagasiside saatmine ebaõnnestus!", type: "ERROR"});
                })
            }
        }
    }
</script>