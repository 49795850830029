<template functional>
    <v-btn :title="props.title" :disabled="props.disabled" color="primary" class="elevation-0 px-2 mr-0 smallManageButton" @click="props.clickAction" :data-cy="props.dataCy">
        <i aria-hidden="true" class="v-icon material-icons theme--dark">delete_forever</i>
    </v-btn>
</template>
<script>
    export default {
        props: {
            title: { type: String },
            clickAction: { type: Function },
            dataCy: String,
            disabled: Boolean
        }
    }
</script>