<template>
    <v-row no-gutters>
        <v-col
            v-if="jobVariants && jobVariants.length > 0"
            class="col-12">
            <v-select
                :prepend-icon="icon"
                :items="jobVariants"
                v-model="jobVariant"
                item-text="label"
                item-value="value"
                :label="inputLabel"
                data-cy="job-variant-input"
                :error-messages="errors"
            >
                <template #item="{item}">
                    <v-list-item-content>
                        <v-list-item-title>{{item.label}}</v-list-item-title>
                        <v-list-item-subtitle v-if="item.hint" style="overflow: auto; text-overflow: initial; white-space: initial;">{{ item.hint }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-select>
        </v-col>
    </v-row>
</template>
<script>

import {mdiGrass, mdiLeaf, mdiMathNormBox, mdiMicroscope, mdiSprout} from "@mdi/js";
import {provideCloseDropdown, provideRequiredPositiveNumber} from "@/mixins/mixins";
import {JOB_VARIANT_TYPES} from "../constant/constants";
import {cultureTypes, soilSampleLaboratory} from "../../../backend/common/constants";
import _ from "lodash";

export default {
    components: {},
    props: {
        initialJobVariant: {},
        jobModel: {
        },
        jobCategoryModel: {
        },
    },
    data() {
        return {
            jobVariant: null,
            jobVariantType: null,
            errors: [],
        }
    },
    mixins: [provideCloseDropdown, provideRequiredPositiveNumber],
    computed: {
        jobVariants() {
            if (this.jobVariantType === JOB_VARIANT_TYPES.SOIL_SAMPLE) {
                return soilSampleLaboratory
            } else if (this.jobVariantType === JOB_VARIANT_TYPES.YIELD) {
                return _.filter(cultureTypes, (culture) => !culture.value.includes("VAHEKULTUUR"))
            } else {
                return []
            }
        },
        inputLabel() {
            if (this.jobVariantType === JOB_VARIANT_TYPES.SOIL_SAMPLE) {
                return "Analüüse teostav labor"
            } else if (this.jobVariantType === JOB_VARIANT_TYPES.YIELD) {
                return "Koristatav kultuur"
            } else {
                return "Liik"
            }
        },
        icon() {
            if (this.jobVariantType === JOB_VARIANT_TYPES.SOIL_SAMPLE) {
                return mdiMicroscope
                // return mdiTestTube
            } else if (this.jobVariantType === JOB_VARIANT_TYPES.YIELD) {
                if (this.jobVariant === "POHIKULTUUR") {
                    return mdiLeaf
                } else if (this.jobVariant === "ALLAKULV") {
                    return mdiSprout
                } else {
                    return mdiGrass
                }
            } else {
                return mdiMathNormBox
            }
        },
    },
    methods: {
        validateJobVariant() {
            if (!this.jobVariant && this.jobVariants.length > 0) {
                this.errors = ["Kohustuslik väli"]
            }
            this.$emit('validate-job-variant', this.errors.length !== 0)
        },
        setFromInitialValue() {
            if (this.jobVariants.length > 0 && this.initialJobVariant){
                const currentJobVariant = this.jobVariants.find(jv => jv.value === this.initialJobVariant);
                if (currentJobVariant) {
                    this.jobVariant = currentJobVariant.value;
                } else {
                    this.jobVariant = this.jobVariants[0].value;
                }
            }
        }
    },
    watch: {
        initialJobVariant: {
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.setFromInitialValue()
                }
            },
            deep: true,
            immediate: true
        },
        jobVariant(newVal) {
            this.errors = []
            this.$emit('update:initialJobVariant', newVal);
        },
        jobVariants(newVal) {
            this.setFromInitialValue()
            if (this.jobVariant === null || this.jobVariant === undefined || this.jobVariant === "") {
                this.jobVariant = newVal[0].value
            }
        },
        jobModel: {
            handler: function (newVal) {
                if (newVal) {
                    if (this.jobCategoryModel && this.jobCategoryModel.toLowerCase().trim() === "koristus") {
                        this.jobVariantType = JOB_VARIANT_TYPES.YIELD;
                        return
                    }
                    const newJobName = newVal?.name?.toLowerCase()?.trim();
                    switch (newJobName) {
                        case "mullaproovi võtmine":
                            this.jobVariantType = JOB_VARIANT_TYPES.SOIL_SAMPLE;
                            break;
                        default:
                            break;
                    }
                }
            },
            deep: true,
            immediate: true
        },


    },
}
</script>

