const _ = require('lodash');
const {
    inspectionDiseases,
    inspectionInsects,
    inspectionWeeds,
    inspectionEvents,
    inspectionUnits, HERDING_INFO, cultureTypes
} = require("./constants");
const {herdingNutrientsTotal} = require("./herdingCalculator");

function inspectionTargets(inspectionType) {
    let inspectionTargets = [];
    if (inspectionType) {
        if (inspectionType === "TAIMEHAIGUSED") {
            inspectionTargets = inspectionDiseases
        } else if (inspectionType === "KAHJURID") {
            inspectionTargets = inspectionInsects
        } else if (inspectionType === "UMBROHUD") {
            inspectionTargets = inspectionWeeds
        } else if (inspectionType === "SUNDMUSED") {
            inspectionTargets = inspectionEvents
        }
    }
    return inspectionTargets;
}

function augmentedInspectionTargets(inspection) {
    const targets = inspectionTargets(inspection.inspectionType);
    const predefinedTarget = targets.find(inspectionTarget => inspectionTarget.value === inspection.target);
    if (predefinedTarget === undefined) {
        return inspection.target
    } else {
        return predefinedTarget.label
    }
}

function getInspectionUnits(units) {
    return inspectionUnits.find(unit => unit.value === units)?.label
}

function inspectionToString(inspection) {
    let result = augmentedInspectionTargets(inspection)
    if (inspection.amount) {
        result += " " + inspection.amount
    }
    if (inspection.unit) {
        result += " " + getInspectionUnits(inspection.unit)
    }
    return result
}
function herdingTotalHours(herding) {
    if (herding.days && herding.hoursPerDay) {
        return herding.days * herding.hoursPerDay
    }
    return undefined
}
function herdingToString(herding) {
    const hours = module.exports.toLocalRoundedNumber(herdingTotalHours(herding))
    const nutrientN = herdingNutrientsTotal([herding], 'N');
    const nutrientP = herdingNutrientsTotal([herding], 'P');
    const nutrientK = herdingNutrientsTotal([herding], 'K');
    const herdingNutrientInfo = HERDING_INFO.find(nutrientInfo => nutrientInfo.unit === herding.animalType);
    return herdingNutrientInfo.label + " " + herding.amount + "tk " + hours + "h: NPK " + _.round(nutrientN, 0) + "-" + _.round(nutrientP, 0) + "-" + _.round(nutrientK, 0)
}


function herdingToSimpleString(herding) {
    const hours = herdingTotalHours(herding)
    if (hours > 0) {
        const herdingNutrientInfo = HERDING_INFO.find(nutrientInfo => nutrientInfo.unit === herding.animalType);
        return herdingNutrientInfo.label + " " + herding.amount + "tk " + module.exports.toLocalRoundedNumber(herdingTotalHours(herding)) + "h"
    }
}



module.exports = {
    eventDescription: (event, notesAllowed = true) => {
        let description = "";
        if (event.jobCategory === 'Sõnniku aunastamine') {
            description += 'Sõnniku aunastamine. ';
        }
        description += event.job;
        if (event.yield !== null &&
            event.yield !== undefined &&
            event.variant !== null &&
            event.variant !== undefined &&
            event.variant !== '') {
            const cropCultureTypeForYield = cultureTypes.find(culture => culture.value === event.variant)?.label;
            if (cropCultureTypeForYield) {
                description += ". " + cropCultureTypeForYield;
            }
        }
        if (event.requisite !== null &&
            event.requisite !== undefined &&
            event.requisite.inspections !== null &&
            event.requisite.inspections !== undefined &&
            event.requisite.inspections.length > 0) {
            description += ". Seire: " + event.requisite.inspections.map(i => inspectionToString(i)).join(", ");
        }
        if (event.amount) description += " - " + event.amount.toLocaleString('et').replace(".", ",")
        if (event.unit) description += " " + event.unit

        if (event.herding !== null &&
            event.herding !== undefined &&
            event.herding.length > 0) {
            description += ". " + event.herding.map(i => herdingToString(i)).join(". ");
        }
        if (event.worker) {
            description += ". Teostas: " + event.worker
        }
        if (event.notes !== null &&
            event.notes !== undefined &&
            event.notes !== "" &&
            notesAllowed) {
            if (event.jobCategory !== 'Muu' || (event.jobCategory === 'Muu' && event.job !== 'Muu')) {
                description += ". " + event.notes
            } else {
                description = event.notes
            }
        }

        return description;
    },
    eventDescriptionSimplified: (event) => {
        let description = "";
        if (event.jobCategory === 'Sõnniku aunastamine') {
            description += 'Sõnniku aunastamine. ';
        }
        description += event.job;
        if (event.yield !== null &&
            event.yield !== undefined &&
            event.variant !== null &&
            event.variant !== undefined &&
            event.variant !== '') {
            const cropCultureTypeForYield = cultureTypes.find(culture => culture.value === event.variant)?.label;
            if (cropCultureTypeForYield) {
                description += ". " + cropCultureTypeForYield + ". ";
            }
        }
        if (event.requisite !== null &&
            event.requisite !== undefined &&
            event.requisite.name !== null &&
            event.requisite.name !== undefined) {
            description += ". " + event.requisite.name + ". ";
        }
        if (event.herding !== null &&
            event.herding !== undefined &&
            event.herding.length > 0) {
            description += ". " + event.herding.map(i => herdingToSimpleString(i)).join(". ");
        }
        if (event.amount) description += " - " + event.amount.toLocaleString('et').replace(".", ",")
        if (event.unit) description += " " + event.unit

        if (event.notes !== null &&
            event.notes !== undefined &&
            event.notes !== "") {
            if (event.jobCategory !== 'Muu' || (event.jobCategory === 'Muu' && event.job !== 'Muu')) {
                description += ". " + event.notes + ". "
            } else {
                description = event.notes
            }
        }

        return description;
    },
    eventDate: (date) => {
        if (!date) return null;
        if (typeof date === 'string' || date instanceof String) {
            date = new Date(date);
        }
        const [year, month, day] = date.toISOString().split('T')[0].split('-');
        return `${day}.${month}.${year}`
    },
    toLocalRoundedNumber: (value) => {
        if (value || value === 0) {
            const roundedValue = _.round(parseFloat(value), 2);
            return roundedValue.toString().replace(".", ",")
        } else {
            return "-"
        }
    },
    toLocalRoundedNumberEuros: (value) => {
        if (value || value === 0) {
            const roundedValue = _.round(parseFloat(value), 2);
            return roundedValue.toFixed(2).toString().replace(".", ",")
        } else {
            return "-"
        }
    },
    toLocalNumber: (value) => {
        if (value || value === 0) {
            return value.toString().replace(".", ",")
        } else {
            return "-"
        }
    },
    requisites: (event) => {
        let requisitesText = "";
        if (event.requisite && event.requisite.name) { // Has material and maybe amount defined
            let unit = event.requisite.unit;
            if (unit == null) { unit = "" }

            let amount = event.requisite.amount;
            let subtype = event.requisite.subtype;
            let subtypeText = "";
            if (subtype && subtype.length > 0 && event.requisite.type !== "fertilizer" && event.requisite.type !== "chemical") {
                subtypeText = capitilize(subtype) + ". "
            }
            let requisiteDescription = subtypeText + capitilize(event.requisite.name) + (amount ? " (" + amount.toLocaleString('et').replace(".", ",") + " " + unit + ")" : "");
            if (event.requisite.coating && event.requisite.coating.name !== null && event.requisite.coating.name !== undefined) {
                requisiteDescription += ". Puhitud: " + event.requisite.coating.name
            }
            if (event.requisite.seedCertificate && event.requisite.seedCertificate.length > 0) {
                requisiteDescription += ". " + event.requisite.seedCertificate
            }
            if (event.requisite.batchNo && event.requisite.batchNo.length > 0) {
                requisiteDescription += " " + event.requisite.batchNo
            }
            requisitesText = requisiteDescription;
        }

        if (event.yield && event.yieldUnit) {
            if (requisitesText.length > 0) {
                requisitesText += ". ";
            }
            if (event.yieldType) {
                requisitesText += event.yieldType + ". ";
            }
            requisitesText += "Saak: " + event.yield.toLocaleString('et').replace(".", ",") + " "+event.yieldUnit;
        }
        if (event.yieldUnitWeight && event.yield) {
            requisitesText += " (tüki kaal "+event.yieldUnitWeight+" t). Kokku: " + (event.yieldUnitWeight * event.yield).toLocaleString('et').replace(".", ",") + " t";
            requisitesText += " - " + ((event.yieldUnitWeight * event.yield) / event.area).toLocaleString('et').replace(".", ",") + " t/ha";

        } else if (event.yield) {
            requisitesText += " - " + (event.yield / event.area).toLocaleString('et').replace(".", ",") + " " + event.yieldUnit + "/ha";
        }
        return requisitesText;
    },
    area: (event) => {
        if (event.area) {
            return _.round(parseFloat(event.area), 3).toString().replace(".", ",");
        }
        return ""
    },
    safetyWarning: (event) => {
        if (event.requisite && event.requisite.name &&
            (event.requisite.safetyRecord || event.requisite.usageTimes || event.requisite.safetyArea || event.requisite.safetyWaterArea || event.requisite.workDelayDays || event.requisite.harvestDelayDays)
        ) { // Has safety record defined
            return " !!!";
        } else {
            return "";
        }
    }
};
function capitilize(word) {
    if (word && word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1)
    } else {
        return word
    }
}
