<template>
    <!-- Add Dialog -->
    <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title class="grey lighten-4 text-h6">
                <v-btn color="primary" elevation="0" small right fab absolute class="closeButton"
                       @click="$emit('close-modal')">
                    <v-icon>close</v-icon>
                </v-btn>
                {{title}}
            </v-card-title>
            <modal-container-wrapper>
                <!---------------------------------------- SEED ---------------------------------------->
                <div v-if="isSeed">
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-4 px-0 pr-md-2">
                            <v-combobox
                                    ref="requisiteModel"
                                    :search-input.sync="searchSeedNames"
                                    hide-no-data
                                    auto-select-first
                                    :items="seedNames"
                                    :prepend-icon="mdiSeedOutline()"
                                    v-model="requisite.name" label="Sordi nimetus"
                                    :rules="[v => !!v || 'Kohustuslik väli', v => !!v && v.length > 1 || 'Liiga lühike']"
                                    data-cy="requisite-name"
                            ></v-combobox>
                        </v-col>
                        <v-col class="col-12 col-md-4 px-0 px-md-2">
                            <v-combobox
                                    :prepend-icon="mdiDotsTriangle()"
                                    :items="seedTypes"
                                    ref="seedTypes"
                                    auto-select-first
                                    v-model="requisite.subtype"
                                    label="Taime liik"
                                    hint="Võite ka uue lisada"
                                    persistent-hint
                                    :menu-props="{auto: $vuetify.breakpoint.mdAndUp}"
                                    :error-messages="subTypeError"
                                    data-cy="requisite-seed-type-select"
                            ></v-combobox>
                        </v-col>
                        <v-col class="col-12 col-md-4 px-0 pl-md-2">
                            <v-select
                                    prepend-icon="category"
                                    :items="requisiteTypes"
                                    v-model="requisite.type"
                                    :item-text="requisiteTypeName"
                                    :item-value="requisiteTypeObject"
                                    data-cy="requisite-type-select"
                                    label="Tarviku tüüp"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-8 px-0 pr-md-2">
                            <v-text-field
                                    ref="maxAmount"
                                    prepend-icon="opacity"
                                    type="number"
                                    min="0"
                                    v-model="requisite.amount" label="Tüüpiline kasutusnorm"
                                    data-cy="requisite-amount-input"
                                    :rules="[v => !!this.$refs.maxAmount && !this.$refs.maxAmount.badInput || this.$refs.maxAmount===undefined || 'Number ei ole korrektne']"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-12 col-md-4 px-0 pl-md-2">
                            <v-autocomplete
                                    prepend-icon="widgets"
                                    :items="units"
                                    v-model="requisite.unit"
                                    auto-select-first
                                    :error-messages="requisiteUnitError"
                                        label="Ühik"
                                    data-cy="requisite-unit-select"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col class="col-12 col-md-6 px-0 pr-md-2">
                            <v-select
                                ref="seedCertificateModel"
                                :prepend-icon="mdiCertificateOutline()"
                                v-model="requisite.seedCertificate"
                                label="Sertifikaat"
                                :items="seedCertificates"
                                item-text="label"
                                item-value="value"
                                :rules="[v => !!v || 'Kohustuslik väli']"
                                required
                                :error-messages="requisite.seedCertificate ? null : ['Kohustuslik väli']"
                                data-cy="requisite-seed-certificate-select"
                            ></v-select>
                        </v-col>
                        <v-col v-if="(requisite.seedCertificate !== undefined && requisite.seedCertificate !== 'OTS') || requisite.batchNo" class="col-12 col-md-6 px-0 pl-md-2">
                            <v-text-field
                                    ref="seedBatchModel"
                                    :prepend-icon="mdiNumeric()"
                                    v-model="requisite.batchNo" label="Partii number"
                                    data-cy="requisite-seed-batchno-input"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-3 px-0 pr-md-2 pb-1">
                            <v-checkbox v-model="coatingModel" color="primary" label="Seeme puhitud"
                                        data-cy="requisite-seed-coating-checkbox"></v-checkbox>
                        </v-col>
                        <v-col v-if="coatingModel" class="col-12 col-md-9 px-0 pl-md-2">
                            <v-autocomplete
                                :prepend-icon="mdiFlaskOutline()"
                                item-text="name"
                                :items="coatingChemicals"
                                return-object
                                v-model="coatingRequisiteModel"
                                ref="coatingRequisiteModel"
                                :attach="$vuetify.breakpoint.smAndDown"
                                :label="isMissingCoatingRequisite ? 'Puhtimisvahendit \'' + requisite.coating.name+ '\' ei leitud' : 'Puhtimisvahendi nimetus'"
                                auto-select-first
                                :rules="[v => !!v || 'Kohustuslik väli']"
                                :error-messages="coatingError"
                                @update:search-input=customSortCoatingChemicals
                                v-on:click:append="() => provideCloseDropdown(this.$refs.coatingRequisiteModel)"
                                data-cy="requisite-seed-coating-name"
                            >
                                <template v-slot:no-data>
                                    <v-col class="mx-4">
                                        Sellise nimega taimekaitsevahendit ei leitud
                                    </v-col>
                                </template>
                                <template #item="{item}">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.name }} <span
                                            style="color: #575757; font-weight: 300">{{
                                                requisiteAmountAndUnitValue(item)
                                            }}</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <template v-slot:append-item>
                                    <v-row no-gutters class="pr-5 pr-sm-auto my-2 px-sm-4">
                                        <v-btn
                                            color="primary"
                                            class="mb-2"
                                            block
                                            data-cy="jobevent-requisite-add-new"
                                            @click.stop="addNewRequisite">
                                            <v-icon>add</v-icon>
                                            Lisa taimekaitsevahend
                                        </v-btn>
                                    </v-row>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col class="col-12 col-md-3 px-0 pr-md-2 pb-1"
                               :style="$vuetify.breakpoint.mdAndUp ? 'height:75px': ''"></v-col>
                        <v-col v-if="coatingModel" class="col-12 col-md-9 px-0 pl-md-2">
                            <v-row no-gutters>
                                <v-col class="col-12 col-md-7 px-0 pr-md-2">
                                    <v-text-field
                                        ref="maxCoatingAmount"
                                        prepend-icon="opacity"
                                        type="number"
                                        min="0"
                                        :disabled="!coatingModel"
                                        v-model="requisite.coating.amount" label="Puhtimisvahendi norm"
                                        data-cy="requisite-seed-coating-amount-input"
                                        :rules="[v => !!v && coatingModel || 'Kohustuslik väli', v => !!this.$refs.maxCoatingAmount && !this.$refs.maxCoatingAmount.badInput || this.$refs.maxCoatingAmount===undefined || 'Number ei ole korrektne']"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-md-5 px-0 pl-md-2">
                                    <v-autocomplete
                                        prepend-icon="widgets"
                                        :items="coatingUnits"
                                        :disabled="!coatingModel"
                                        v-model="requisite.coating.unit"
                                        :error-messages="coatingUnitError"
                                        label="Ühik"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters >
                            <v-text-field
                                    :prepend-icon="mdiTextBoxEditOutline()"
                                    v-model="requisite.note" label="Märkmed või lisainfo"
                            ></v-text-field>
                    </v-row>
                </div>
                <!---------------------------------------- FERTILIZER ---------------------------------------->
                <div v-else-if="isFertilizer">
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-8 px-0 pr-md-2">
                            <v-radio-group v-model="requisite.subtype" row
                                           :error-messages="subTypeError"
                                           data-cy="requisite-fertilizer-subtype"
                            >
                                <v-radio label="Tavaline väetis" :value="FERTILIZER_TYPE.FERTILIZER" data-cy="requisite-fertilizer-subtype-regular"></v-radio>
                                <v-radio label="Lubiväetis" :value="FERTILIZER_TYPE.FERTILIZER_LIMESTONE" data-cy="requisite-fertilizer-subtype-limestone"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col class="col-12 col-md-4 px-0 pl-md-2">
                            <v-select
                                    prepend-icon="category"
                                    :items="requisiteTypes"
                                    v-model="requisite.type"
                                    :item-text="requisiteTypeName"
                                    :item-value="requisiteTypeObject"
                                    data-cy="requisite-type-select"
                                    label="Tarviku tüüp"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-12">
                            <v-select
                                :items="fertilizerSubtypeVariants"
                                item-text="label"
                                item-value="value"
                                v-model="requisite.subtypeVariant"
                                :prepend-icon="mdiSelectGroup()"
                                label="Väetise tüüp"
                                data-cy="requisite-subtype-variant-selection"
                                :rules="[v => !!v || 'Kohustuslik väli', v => !!v && v.length > 1 || 'Liiga lühike']"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="isFertilizerFromRegistry">
                        <v-combobox
                            ref="requisiteModel"
                            :search-input.sync="searchFertilizerNames"
                            hide-no-data
                            :items="fertilizerNames"
                            :prepend-icon="mdiDotsTriangle()"
                            auto-select-first
                            :error-messages="fertilizerNameError"
                            :rules="[v => !!v && v.length > 2 || 'Kohustuslik väli', v => !!v && v.length > 1 || 'Liiga lühike']"
                            data-cy="fertilizer-name-input"
                            v-model="requisite.name"
                            label="Väetise nimetus"
                        ></v-combobox>
                    </v-row>
                    <v-row v-else no-gutters>
                        <v-col class="col-12">
                            <v-text-field
                                v-model="requisite.name"
                                label="Nimetus"
                                :prepend-icon="mdiDotsTriangle()"
                                data-cy="fertilizer-name-input-free-format"
                                :rules="[v => !!v || 'Kohustuslik väli', v => !!v && v.length > 1 || 'Liiga lühike']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-8 px-0 pr-md-2">
                            <v-text-field
                                    prepend-icon="opacity"
                                    type="number"
                                    min="0"
                                    ref="maxAmount"
                                    :rules="[v => !!this.$refs.maxAmount && !this.$refs.maxAmount.badInput || this.$refs.maxAmount===undefined || 'Number ei ole korrektne']"
                                    data-cy="requisite-amount-input"
                                    v-model="requisite.amount" label="Tüüpiline kasutusnorm"></v-text-field>
                        </v-col>
                        <v-col class="col-12 col-md-4 px-0 pl-md-2">
                            <v-autocomplete
                                    prepend-icon="widgets"
                                    :items="fertilizerUnits"
                                    v-model="requisite.unit"
                                    :error-messages="requisiteUnitError"
                                    data-cy="requisite-unit-select"
                                    label="Ühik"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row class="ml-5 mb-n2 font-weight-bold">
                        <v-col>
                            Väetise keemilised väärtused ({{nutrientUnit}}) {{isFertilizerFromRegistry ? ' - registriandmete põhjal' : ''}}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-6">
                            <v-text-field
                                type="number"
                                min="0"
                                ref="elementNModel"
                                class="darkerDisabledInput"
                                :disabled="isFertilizerFromRegistry"
                                :suffix="nutrientUnitSuffix"
                                :append-outer-icon="isFertilizerFromRegistry ? mdiLock() : null"
                                :rules="[v => !!this.$refs.elementNModel && !this.$refs.elementNModel.badInput || this.$refs.elementNModel===undefined || 'Number ei ole korrektne']"
                                v-model="requisite.nutrient.N" label="Lämmastik"
                            >
                                <template v-slot:prepend><strong class="chemicalElement">N :</strong></template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-6 px-0 pr-2">
                            <v-text-field
                                    suffix="="
                                    type="number"
                                    min="0"
                                    class="darkerDisabledInput"
                                    :disabled="isFertilizerFromRegistry"
                                    hint="Kasutatakse fosfori arvutamiseks" persistent-hint
                                    v-model="elementP2Model">
                                <template v-slot:prepend>P<sub>2</sub>O<sub>5</sub></template>
                            </v-text-field>
                        </v-col>
                        <v-col class="col-6 px-0 pl-2">
                            <v-text-field
                                min="0"
                                type="number"
                                ref="elementPModel"
                                class="darkerDisabledInput"
                                :disabled="isFertilizerFromRegistry"
                                :suffix="nutrientUnitSuffix"
                                :append-outer-icon="isFertilizerFromRegistry ? mdiLock() : null"
                                :rules="[v => !!this.$refs.elementPModel && !this.$refs.elementPModel.badInput || this.$refs.elementPModel===undefined || 'Number ei ole korrektne']"
                                v-model="requisite.nutrient.P" label="Fosfor"
                            >
                                <template v-slot:prepend><strong class="chemicalElement">P :</strong></template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-6 px-0 pr-2">
                            <v-text-field
                                    suffix="="
                                    type="number"
                                    min="0"
                                    class="darkerDisabledInput"
                                    :disabled="isFertilizerFromRegistry"
                                    hint="Kasutatakse kaaliumi arvutamiseks" persistent-hint
                                    v-model="elementK2Model">
                                <template v-slot:prepend>K<sub>2</sub>O</template>
                            </v-text-field>
                        </v-col>
                        <v-col class="col-6 px-0 pl-2">
                            <v-text-field
                                type="number"
                                min="0"
                                ref="elementKModel"
                                class="darkerDisabledInput"
                                :disabled="isFertilizerFromRegistry"
                                :suffix="nutrientUnitSuffix"
                                :append-outer-icon="isFertilizerFromRegistry ? mdiLock() : null"
                                :rules="[v => !!this.$refs.elementKModel && !this.$refs.elementKModel.badInput || this.$refs.elementKModel===undefined || 'Number ei ole korrektne']"
                                v-model="requisite.nutrient.K" label="Kaalium"
                            >
                                <template v-slot:prepend><strong class="chemicalElement">K :</strong></template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-6">
                            <v-text-field
                                type="number"
                                min="0"
                                ref="elementCaOModel"
                                class="darkerDisabledInput"
                                :disabled="isFertilizerFromRegistry"
                                :suffix="nutrientUnitSuffix"
                                :append-outer-icon="isFertilizerFromRegistry ? mdiLock() : null"
                                :rules="[v => !!this.$refs.elementCaOModel && !this.$refs.elementCaOModel.badInput || this.$refs.elementCaOModel===undefined || 'Number ei ole korrektne']"
                                v-model="requisite.nutrient.CaO" label="Kaltsiumoksiid"
                            >
                                <template v-slot:prepend><strong class="chemicalElement">CaO :</strong></template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-6">
                            <v-text-field
                                type="number"
                                min="0"
                                ref="elementMgOModel"
                                class="darkerDisabledInput"
                                :disabled="isFertilizerFromRegistry"
                                :suffix="nutrientUnitSuffix"
                                :append-outer-icon="isFertilizerFromRegistry ? mdiLock() : null"
                                :rules="[v => !!this.$refs.elementMgOModel && !this.$refs.elementMgOModel.badInput || this.$refs.elementMgOModel===undefined || 'Number ei ole korrektne']"
                                v-model="requisite.nutrient.MgO" label="Magneesiumoksiid"
                            >
                                <template v-slot:prepend><strong class="chemicalElement">MgO :</strong></template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" :class="{'xs12': $vuetify.breakpoint.smAndDown}">
                            <v-text-field
                                    :prepend-icon="mdiTextBoxEditOutline()"
                                    v-model="requisite.note" label="Märkmed või lisainfo"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <!---------------------------------------- CHEMICAL ---------------------------------------->
                <div v-else-if="isChemical">
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-8 px-0 pr-md-2">
                            <v-select
                                :items="chemicalSubtypeVariants"
                                item-text="label"
                                item-value="value"
                                v-model="requisite.subtypeVariant"
                                :prepend-icon="mdiSelectGroup()"
                                label="Kemikaali tüüp"
                                data-cy="requisite-subtype-variant-selection"
                            ></v-select>
                        </v-col>
                        <v-col class="col-12 col-md-4 px-0 pl-md-2">
                            <v-select
                                    prepend-icon="category"
                                    :items="requisiteTypes"
                                    v-model="requisite.type"
                                    :item-text="requisiteTypeName"
                                    :item-value="requisiteTypeObject"
                                    data-cy="requisite-type-select"
                                    label="Tarviku tüüp"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="isChemicalFromRegistry">
                        <v-col :class="chemicalNameError || unsupportedChemicalSubtype ? 'col-12 col-md-8 px-0 pr-md-2' : 'col-12 col-md-12 px-0 pr-md-2'">
                            <v-combobox
                                ref="requisiteModel"
                                :search-input.sync="searchChemicalNames"
                                hide-no-data
                                auto-select-first
                                :items="chemicalNames"
                                :prepend-icon="mdiFlaskOutline()"
                                :suffix="!chemicalNameError && !unsupportedChemicalSubtype ? '(' + chemicalTypeText(requisite.subtype) + ')' : null"
                                :error-messages="chemicalNameError"
                                :rules="[v => !!v || 'Kohustuslik väli', v => !!v && v.length > 1 || 'Liiga lühike']"
                                data-cy="chemical-name-input"
                                v-model="requisite.name" label="Taimekaitsevahendi nimetus"
                            ></v-combobox>
                        </v-col>
                        <v-col v-if="chemicalNameError || unsupportedChemicalSubtype" class="col-12 col-md-4">
                            <v-select
                                :prepend-icon="mdiFormatListBulletedType()"
                                :items="chemicalSubtypes"
                                v-model="requisite.subtype"
                                :item-value="v => v.value"
                                label="Tüüp"
                                :error-messages="subTypeError"
                                data-cy="requisite-chemical-type-selection"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row v-else no-gutters>
                        <v-col class="col-12">
                            <v-text-field
                                v-model="requisite.name"
                                label="Nimetus"
                                :prepend-icon="mdiFlaskOutline()"
                                :rules="[v => !!v || 'Kohustuslik väli', v => !!v && v.length > 1 || 'Liiga lühike']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="isChemicalFromRegistry">
                        <v-col class="col-12 px-0">
                            <v-autocomplete
                                v-model="requisite.ingredients"
                                :prepend-icon="mdiFlaskRoundBottom()"
                                :items="chemicalIngredients"
                                ref="ingredients"
                                label="Toimeained"
                                multiple
                                chips
                                deletable-chips
                                item-text="name"
                                item-value="code"
                                return-object
                                :error-messages="ingredientsError"
                                data-cy="requisite-chemical-ingredients-select"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-8 px-0 pr-md-2">
                            <v-text-field
                                    prepend-icon="opacity"
                                    type="number"
                                    min="0"
                                    ref="maxAmount"
                                    :rules="[v => !!this.$refs.maxAmount && !this.$refs.maxAmount.badInput || this.$refs.maxAmount===undefined || 'Number ei ole korrektne']"
                                    data-cy="requisite-amount-input"
                                    v-model="requisite.amount" :label="'Tüüpiline kasutusnorm'+ usageAmountHint">

                            </v-text-field>
                        </v-col>
                        <v-col class="col-12 col-md-4 px-0 pl-md-2">
                            <v-autocomplete
                                    prepend-icon="widgets"
                                    :items="units"
                                    v-model="requisite.unit"
                                    :error-messages="requisiteUnitError"
                                    label="Ühik"
                                    data-cy="requisite-unit-select"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <inspections-component v-if="isChemicalFromRegistry" ref="inspectionsComponent" v-bind:initialInspections.sync="requisite.inspections" :chemicalSubtype="this.requisite.subtype" @validate-inspections="validateInspections"></inspections-component>
                    <v-row no-gutters>
                        <v-text-field
                            :prepend-icon="mdiTextBoxEditOutline()"
                            v-model="requisite.note" label="Märkmed või lisainfo"
                        ></v-text-field>
                    </v-row>

                    <v-row no-gutters v-if="isChemicalFromRegistry">
                        <v-col>
                            <v-list dense class="my-2">
                                <v-subheader v-if="chemicalDetails && chemicalDetails.files.length > 0" class='text--primary'  :class="$vuetify.breakpoint.smAndDown ? 'my-4' : ''">
                                    Kasutuspiirangute kontrollimiseks palun kasutage <a href="https://portaal.agri.ee/avalik/#/taimekaitse/taimekaitsevahendid-otsing/et" target="_blank" style="display: contents;">PMA taimekaitse registrit</a> või
                                        tutvuge failidega:</v-subheader>
                                <v-subheader v-else class='text--primary'>Kasutuspiirangute kontrollimisek palun kasutage <a href="https://portaal.agri.ee/avalik/#/taimekaitse/taimekaitsevahendid-otsing/et" target="_blank" style="display: contents;">PMA taimekaitse registrit</a></v-subheader>
                                <template v-for="(item) in chemicalDetails.files">
                                    <v-list-item
                                            :key="item.url"
                                            @click="openPriaFile(item.url)">
                                        <v-list-item-avatar>
                                            <v-icon style="font-size: 40px" color="gray">description</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.type}}  </v-list-item-title>
                                            <v-list-item-subtitle><span class='text--primary'>{{item.fileName}}</span>
                                                <span v-if="item.comment">&mdash; {{item.comment}}</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn color="primary" :icon="$vuetify.breakpoint.smAndDown" dark :class="!$vuetify.breakpoint.smAndDown ? 'px-2 pr-4 elevation-0 ml-2' : 'ml-2 elevation-0'">
                                                <v-icon class="px-2">cloud_download</v-icon>
                                                <span v-if="$vuetify.breakpoint.mdAndUp">laadi alla</span>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="isChemicalFromRegistry">
                            <v-text-field
                                    type="number"
                                    min="0"
                                    v-model="requisite.workDelayDays"
                                    label="Tööoode (päeva)"
                                    hint="Vaheaeg ööpäevades taimekaitsevahendi kasutamise ja taimede teiste hooldustööde alustamise vahel." persistent-hint
                                    data-cy="chemical-work-delay-input"
                                    :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                            >
                                <template v-slot:prepend>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on">{{ mdiProgressClock() }}</v-icon>
                                        </template>
                                        Tööoote aeg päevades
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                    </v-row>
                    <v-row no-gutters v-if="isChemicalFromRegistry">
                        <v-text-field
                                type="number"
                                min="0"
                                v-model="requisite.harvestDelayDays"
                                label="Ooteaeg (päeva)"
                                hint="Vaheaeg ööpäevades taimekaitsevahendi kasutamise ja saagi koristamise vahel." persistent-hint
                                :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                        >
                            <template v-slot:prepend>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">update</v-icon>
                                    </template>
                                    Koristamise ooteaeg päevades
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-row>
                    <v-row no-gutters v-if="isChemicalFromRegistry">
                        <v-text-field
                                type="number"
                                min="0"
                                v-model="requisite.usageTimes"
                                label="Kasutuskordi"
                                hint="Lubatud kasutuskordade arv ühe aasta jooksul" persistent-hint
                                :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                                data-cy="chemical-usagetime-input"
                        >
                            <template v-slot:prepend>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">rotate_right</v-icon>
                                    </template>
                                    Lubatud kasutuskordade arv ühe aasta jooksul
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-row>
                    <v-row no-gutters v-if="isChemicalFromRegistry">
                        <v-col class="col-12 col-md-6 px-0 pr-md-2">
                            <v-text-field
                                    type="number"
                                    min="0"
                                    v-model="requisite.safetyArea"
                                    label="Ohutusala (m)"
                                    hint="Mittepõllumajanduslikust maast" persistent-hint
                                    :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                            >
                                <template v-slot:prepend>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">remove_circle_outline</v-icon>
                                        </template>
                                        Kohustuslik ohutusala kemikaali kasutamisel
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col class="col-12 col-md-6 px-0 pl-md-2">
                            <v-text-field
                                    type="number"
                                    min="0"
                                    v-model="requisite.safetyWaterArea"
                                    label="Ohutusala veekogust (m)"
                                    hint="Veekogu piirist (pervest)"  persistent-hint
                                    :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                            >
                                <template v-slot:prepend>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">waves</v-icon>
                                        </template>
                                        Kohustuslik ohutusala kemikaali kasutamisel
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="isChemicalFromRegistry">
                        <v-text-field
                                prepend-icon="error_outline"
                                v-model="requisite.safetyRecord" label="Ohutuskirje"
                        ></v-text-field>
                    </v-row>
                </div>
                <!---------------------------------------- OTHER ---------------------------------------->
                <div v-else>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-8 px-0 pr-md-2">
                            <v-text-field
                                    ref="requisiteModel"
                                    :prepend-icon="mdiPackageVariantClosed()"
                                    v-model="requisite.name" label="Tarviku nimetus (ülejäänud tarkvikud)"
                                    :rules="[v => !!v || 'Kohustuslik väli', v => !!v && v.length > 1 || 'Liiga lühike']"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-12 col-md-4 px-0 pl-md-2">
                            <v-select
                                    prepend-icon="category"
                                    :items="requisiteTypes"
                                    v-model="requisite.type"
                                    :item-text="requisiteTypeName"
                                    :item-value="requisiteTypeObject"
                                    label="Tarviku tüüp"
                                    data-cy="requisite-type-select"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-8 px-0 pr-md-2">
                            <v-text-field
                                    prepend-icon="opacity"
                                    type="number"
                                    min="0"
                                    ref="maxAmount"
                                    :rules="[v => !!this.$refs.maxAmount && !this.$refs.maxAmount.badInput || this.$refs.maxAmount===undefined || 'Number ei ole korrektne']"
                                    v-model="requisite.amount" label="Tüüpiline kasutusnorm"></v-text-field>
                        </v-col>
                        <v-col class="col-12 col-md-4 px-0 pl-md-2">
                            <v-autocomplete
                                    prepend-icon="widgets"
                                    :items="units"
                                    v-model="requisite.unit"
                                    :error-messages="requisiteUnitError"
                                    label="Ühik"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <div v-if="requisite.nutrient && (requisite.nutrient.N || requisite.nutrient.P || requisite.nutrient.K || requisite.nutrient.CaO || requisite.nutrient.MgO)">
                        <v-row class="ml-5 mt-3 mb-n2 font-weight-bold">
                            <v-col>
                                Väetise keemilised väärtused ({{nutrientUnit}})
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="col-12 col-md-6">
                                <v-text-field
                                        type="number"
                                        min="0"
                                        ref="elementNModel"
                                        :rules="[v => !!this.$refs.elementNModel && !this.$refs.elementNModel.badInput || this.$refs.elementNModel===undefined || 'Number ei ole korrektne']"
                                        v-model="requisite.nutrient.N" label="Lämmastik"
                                >
                                    <template v-slot:prepend><strong class="chemicalElement">N :</strong></template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="col-12 col-md-6 px-0 pr-md-2">
                                <v-text-field
                                        suffix="="
                                        type="number"
                                        min="0"
                                        ref="elementPModel"
                                        :rules="[v => !!this.$refs.elementPModel && !this.$refs.elementPModel.badInput || this.$refs.elementPModel===undefined || 'Number ei ole korrektne']"
                                        v-model="requisite.nutrient.P" label="Fosfor"
                                >
                                    <template v-slot:prepend><strong class="chemicalElement">P :</strong></template>
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12 col-md-6 px-0 pl-md-2">
                                <v-text-field
                                        type="number"
                                        min="0"
                                        hint="Kasutatakse fosfori arvutamiseks" persistent-hint
                                        v-model="elementP2Model">
                                    <template v-slot:prepend>P<sub>2</sub>O<sub>5</sub></template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="col-12 col-md-6 px-0 pr-md-2">
                                <v-text-field
                                        suffix="="
                                        type="number"
                                        min="0"
                                        ref="elementKModel"
                                        :rules="[v => !!this.$refs.elementKModel && !this.$refs.elementKModel.badInput || this.$refs.elementKModel===undefined || 'Number ei ole korrektne']"
                                        v-model="requisite.nutrient.K" label="Kaalium"
                                >
                                    <template v-slot:prepend><strong class="chemicalElement">K :</strong></template>
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12 col-md-6 px-0 pl-md-2">
                                <v-text-field
                                        type="number"
                                        min="0"
                                        hint="Kasutatakse kaaliumi arvutamiseks" persistent-hint
                                        v-model="elementK2Model">
                                    <template v-slot:prepend>K<sub>2</sub>O</template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="col-12 col-md-6">
                                <v-text-field
                                    type="number"
                                    min="0"
                                    ref="elementCaOModel"
                                    :suffix="nutrientUnitSuffix"
                                    :rules="[v => !!this.$refs.elementCaOModel && !this.$refs.elementCaOModel.badInput || this.$refs.elementCaOModel===undefined || 'Number ei ole korrektne']"
                                    v-model="requisite.nutrient.CaO" label="Kaltsiumoksiid"
                                >
                                    <template v-slot:prepend><strong class="chemicalElement">CaO :</strong></template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="col-12 col-md-6">
                                <v-text-field
                                    type="number"
                                    min="0"
                                    ref="elementMgOModel"
                                    :suffix="nutrientUnitSuffix"
                                    :rules="[v => !!this.$refs.elementMgOModel && !this.$refs.elementMgOModel.badInput || this.$refs.elementMgOModel===undefined || 'Number ei ole korrektne']"
                                    v-model="requisite.nutrient.MgO" label="Magneesiumoksiid"
                                >
                                    <template v-slot:prepend><strong class="chemicalElement">MgO :</strong></template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row no-gutters v-if="requisite.workDelayDays">
                        s
                        <v-text-field
                                type="number"
                                min="0"
                                v-model="requisite.workDelayDays"
                                label="Tööoode (päeva)"
                                hint="Vaheaeg ööpäevades taimekaitsevahendi kasutamise ja taimede teiste hooldustööde alustamise vahel." persistent-hint
                                :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                        >
                            <template v-slot:prepend>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">{{ mdiProgressClock() }}</v-icon>
                                    </template>
                                    Tööoote aeg päevades
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-row>
                    <v-row no-gutters v-if="requisite.harvestDelayDays">
                        <v-text-field
                                type="number"
                                min="0"
                                v-model="requisite.harvestDelayDays"
                                label="Ooteaeg (päeva)"
                                hint="Vaheaeg ööpäevades taimekaitsevahendi kasutamise ja saagi koristamise vahel." persistent-hint
                                :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                        >
                            <template v-slot:prepend>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">update</v-icon>
                                    </template>
                                    Koristamise ooteaeg päevades
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-row>
                    <v-row no-gutters v-if="requisite.usageTimes">
                        <v-text-field
                                type="number"
                                min="0"
                                v-model="requisite.usageTimes"
                                label="Kasutuskordi"
                                hint="Lubatud kasutuskordade arv ühe aasta jooksul" persistent-hint
                                :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                        >
                            <template v-slot:prepend>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">rotate_right</v-icon>
                                    </template>
                                    Lubatud kasutuskordade arv ühe aasta jooksul
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-row>
                    <v-row no-gutters v-if="requisite.safetyArea || requisite.safetyWaterArea">
                        <v-col class="col-12 col-md-6 px-0 pr-md-2">
                            <v-text-field
                                    type="number"
                                    min="0"
                                    v-model="requisite.safetyArea"
                                    label="Ohutusala (m)"
                                    hint="Mittepõllumajanduslikust maast" persistent-hint
                                    :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                            >
                                <template v-slot:prepend>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">remove_circle_outline</v-icon>
                                        </template>
                                        Kohustuslik ohutusala kemikaali kasutamisel
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col class="col-12 col-md-6 px-0 pl-md-2">
                            <v-text-field
                                    type="number"
                                    min="0"
                                    v-model="requisite.safetyWaterArea"
                                    label="Ohutusala veekogust (m)"
                                    hint="Veekogu piirist (pervest)"  persistent-hint
                                    :rules="[v => v === '' ||  v === undefined || v === null || v > 0 || 'Peab olema positiivne arv või üldse puuduma']"
                            >
                                <template v-slot:prepend>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">waves</v-icon>
                                        </template>
                                        Kohustuslik ohutusala kemikaali kasutamisel
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="requisite.safetyRecord">
                        <v-text-field
                                prepend-icon="error_outline"
                                v-model="requisite.safetyRecord" label="Ohutuskirje"
                        ></v-text-field>
                    </v-row>
                    <v-row no-gutters >
                        <v-text-field
                                :prepend-icon="mdiTextBoxEditOutline()"
                                v-model="requisite.note" label="Märkmed või lisainfo"
                        ></v-text-field>
                    </v-row>
                </div>
                <template #footer>
                    <v-btn color="primary" outlined class="mx-2" @click="$emit('close-modal')">Katkesta</v-btn>
                    <v-btn color="primary" @click="addOrUpdate" :disabled="disableSubmit" :loading="disableSubmit" data-cy="requisite-save-button">Salvesta</v-btn>
                </template>
            </modal-container-wrapper>
        </v-form>
        <v-dialog v-model="updateJobEventsDialog" persistent :fullscreen="$vuetify.breakpoint.smAndDown" absolute max-width="900px">
            <updateJobEventsModal :jobEventUpdateCandidates="jobEventUpdateCandidates" @updateJobEvents="updateJobEvents" />
        </v-dialog>
        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="showRequisiteManagementModal" absolute
                  style="z-index: 300"
                  max-width="800px">
            <requisiteManagementModal @close-modal="requisiteModalClosed"
                                      :initialRequisite="{type: REQUISITE_CHEMICAL.typeName, nutrient: {}, coating: {}, ingredients:[]}">
            </requisiteManagementModal>
        </v-dialog>
    </v-card>
</template>

<script>
import {capitalizeFirstLetter} from "../utils/stringUtils";
import {detectJobEventsWithChangedRequisites} from "../utils/jobEventsWithChangedRequisitesDector";
import updateJobEventsModal from "./updateJobEventsModal";
import {
    chemicalTypeDisplay,
    fertilizerDropdown,
    provideCloseDropdown,
    requisiteSelectionMethods,
} from "../mixins/mixins";

import _ from "lodash";
import {ALL_REQUISITES, REQUISITE_MATERIAL} from "../constant/requisiteType.js"
import {REQUISITE_CHEMICAL, REQUISITE_FERTILIZER, REQUISITE_SEED} from "../constant/requisiteType";
import {http} from "../config/http";
import modalContainerWrapper from "../components/modalContainerWrapper";
import {phosphorusOxideToElement, potassiumOxideToElement} from "../../../backend/common/fertilizerElementCalculator";
import {
    mdiCertificateOutline,
    mdiDotsTriangle,
    mdiFlaskOutline,
    mdiFlaskRoundBottom,
    mdiFormatListBulletedType, mdiLock,
    mdiNumeric,
    mdiPackageVariantClosed,
    mdiProgressClock,
    mdiSeedOutline,
    mdiSelectGroup,
    mdiTextBoxEditOutline,
} from "@mdi/js";
import {mapGetters} from "vuex";
import InspectionsComponent from "@/management/inspectionsComponent.vue";
import {
    chemicalSubtypeVariants,
    FERTILIZER_TYPE_OLD, FERTILIZER_TYPE, limestoneFertilizerSubtypeVariants,
    regularFertilizerSubtypeVariants
} from "../../../backend/common/constants";
import {isMineralFertilizer, isOrganicFertilizer} from "../../../backend/common/fertilizationHelper";


export default {
        components: {
            InspectionsComponent,
            updateJobEventsModal,
            modalContainerWrapper,
            requisiteManagementModal: () => import('./requisiteManagementModal.vue')
        },
        props: {
            initialRequisite: {
                type: Object
            }
        },
        data() {
            return {
                valid: true,
                requisite: _.cloneDeep(this.initialRequisite),
                coatingModel: this.initialRequisite.coating === undefined || !!this.initialRequisite.coating.name,
                coatingRequisiteModel: null,
                requisiteUnitError: null,
                coatingUnitError: null,
                coatingError: null,
                subTypeError: null,
                elementP2Model: null,
                elementK2Model: null,
                userUpdatedN: true,
                updateJobEventsDialog: false,
                jobEventUpdateCandidates: [],
                searchSeedNames: null,
                searchChemicalNames: null,
                searchFertilizerNames: null,
                chemicalExistInRegistry: true,
                fertilizerExistInRegistry: true,
                chemicalNames: [],
                fertilizerNames: [],
                seedNames: [],
                chemicalDetails: {files:[]},
                chemicalNameError: undefined,
                fertilizerNameError: undefined,
                disableSubmit: false,
                skipFirstFertilizationLoading: true,
                skipFirstSeedsLoading: true,
                coatingChemicals: [],
                showRequisiteManagementModal: false,
                ingredientsError: null,
                inspectionError: null,
                firstLoad: true,
            }
        },
        mixins: [fertilizerDropdown, chemicalTypeDisplay, provideCloseDropdown, requisiteSelectionMethods],
        computed: {
            FERTILIZER_TYPE() {
                return FERTILIZER_TYPE
            },
            REQUISITE_CHEMICAL() {
                return REQUISITE_CHEMICAL
            },
            title() {
                if (this.isModifingExisting()) {
                    return "Muuda: " + this.initialRequisite.name;
                } else {
                    const prefix = this.requisite.subtype === FERTILIZER_TYPE.FERTILIZER_LIMESTONE ? 'lubi' : '';
                    return "Lisa uus " + prefix + this.requisiteType.displayName
                }
            },
            chemicalSubtypeVariants() {
                return chemicalSubtypeVariants
            },
            fertilizerSubtypeVariants() {
                if (this.requisite.subtype !== FERTILIZER_TYPE.FERTILIZER_LIMESTONE) {
                    return regularFertilizerSubtypeVariants
                } else {
                    return limestoneFertilizerSubtypeVariants
                }
            },
            requisiteTypes() {
                return ALL_REQUISITES;
            },
            units() {
                return this.$store.getters.units
            },
            isMineralFertilizer() {
                return isMineralFertilizer(this.requisite.subtype, this.requisite.subtypeVariant)
            },
            isOrganicFertilizer() {
                return isOrganicFertilizer(this.requisite.subtype, this.requisite.subtypeVariant)
            },
            fertilizerUnits() {
                if (this.isOrganicFertilizer) {
                    return this.$store.getters.organicFertilizerUnits
                } else {
                    return this.$store.getters.mineralFertilizerUnits
                }
            },
            coatingUnits() {
                return this.$store.getters.coatingUnits;
            },
            seedTypes() {
                return this.$store.getters.getSeedTypes
            },
            requisiteType: function () {
                return _.find(ALL_REQUISITES, requisite => requisite.typeName === this.requisite.type)
            },
            isSeed: function () {
                return this.requisiteType === REQUISITE_SEED;
            },
            isFertilizer: function () {
                return this.requisiteType === REQUISITE_FERTILIZER;
            },
            isChemical: function () {
                return this.requisiteType === REQUISITE_CHEMICAL;
            },
            usageAmountHint: function () {
                if (this.chemicalDetails && this.chemicalDetails.maxNorm && this.chemicalDetails.minNorm) {
                    if (this.chemicalDetails.maxNormUnit === this.chemicalDetails.minNormUnit) {
                        if (this.chemicalDetails.minNorm === "0") {
                            return ". Kuni " + this.chemicalDetails.maxNorm + " " + this.chemicalDetails.maxNormUnit;
                        } else {
                            return ". Vahemikus " + this.chemicalDetails.minNorm + " - " + this.chemicalDetails.maxNorm + " " + this.chemicalDetails.maxNormUnit;
                        }
                    } else {
                        return ". Vahemikus " + this.chemicalDetails.minNorm + " " + this.chemicalDetails.minNormUnit + " - " + this.chemicalDetails.maxNorm+ " " + this.chemicalDetails.maxNormUnit;
                    }
                } else {
                    return "";
                }
            },
            nutrientUnit: function () {
                if (this.isMineralFertilizer) {
                    return "%"
                } else {
                    return "kg/t või kg/m3"
                }
            },
            nutrientUnitSuffix: function () {
                if (this.isMineralFertilizer) {
                    return "%"
                } else {
                    return ""
                }
            },
            chemicalIngredients() {
                return this.$store.getters.getChemicalIngredients
            },
            stateCoatingChemicals() {
                return _.cloneDeep(this.getRequisitesByType()(REQUISITE_CHEMICAL)
                    .filter(requisite =>
                        requisite.subtypeVariant === 'TKV_REGISTRIST' ||
                        requisite.subtypeVariant === 'BIOSTIM' ||
                        requisite.subtypeVariant === null ||
                        requisite.subtypeVariant === undefined
                    ))
            },
            isMissingCoatingRequisite() {
                return (this.requisite.coating && this.requisite.coating.name && this.requisite.coating.requisiteId === undefined && this.coatingRequisiteModel === null) ||
                    (this.requisite.coating && this.requisite.coating.name && this.coatingRequisiteModel === null)
            },
            seedCertificates() {
                return this.$store.getters.getSeedCertificates
            },
            chemicalSubtypes() {
                return this.$store.getters.getChemicalSubtypes.map(subtype => {
                    return {
                        value: subtype,
                        text: capitalizeFirstLetter(this.chemicalTypeText(subtype))
                    }
                })
            },
            isChemicalFromRegistry() {
                return this.requisite.type === REQUISITE_CHEMICAL.typeName && this.requisite.subtypeVariant === "TKV_REGISTRIST"
            },
            isFertilizerFromRegistry() {
                return this.requisite.type === REQUISITE_FERTILIZER.typeName && (this.requisite.subtypeVariant === "VAETIS_REGISTRIST" || this.requisite.subtypeVariant === "LUBIVAETIS")
            },
            unsupportedChemicalSubtype() {
                return !_.some(this.chemicalSubtypes, (variant) => variant.value === this.requisite.subtype)
            }
        },
        methods: {
            mdiLock() {
                return mdiLock
            },
            mdiSelectGroup() {
                return mdiSelectGroup
            },
            mdiProgressClock() {
                return mdiProgressClock
            },
            mdiTextBoxEditOutline() {
                return mdiTextBoxEditOutline
            },
            mdiFormatListBulletedType() {
                return mdiFormatListBulletedType
            },
            mdiCertificateOutline() {
                return mdiCertificateOutline
            },
            mdiNumeric() {
                return mdiNumeric
            },
            ...mapGetters(["getRequisitesByType"]),
            mdiFlaskRoundBottom() {
                return mdiFlaskRoundBottom
            },
            mdiPackageVariantClosed () {
                return mdiPackageVariantClosed
            },
            mdiFlaskOutline() {
                return mdiFlaskOutline
            },
            mdiDotsTriangle() {
                return mdiDotsTriangle
            },
            mdiSeedOutline() {
                return mdiSeedOutline
            },
            init: function () {
                this.firstLoad = true;
                this.requisite = _.cloneDeep(this.initialRequisite); // Important to reset
                this.coatingChemicals = this.stateCoatingChemicals;
                this.coatingModel = this.initialRequisite.coating === undefined || !!this.initialRequisite.coating.name;
                this.coatingRequisiteModel = _.find(this.stateCoatingChemicals, (e) => {
                    return e._id === this.initialRequisite.coating.requisiteId
                }) ?? null;
                this.skipFirstFertilizationLoading = true;
                this.skipFirstSeedsLoading = true;
                setTimeout(() => {
                    if (this.requisite.name === undefined && this.$refs.requisiteModel) {
                        this.$refs.requisiteModel.onFocus();
                    }
                    if (this.$refs.requisiteModel) {this.$refs.requisiteModel.errorBucket = []}
                    if (this.$refs.seedBatchModel) {this.$refs.seedBatchModel.errorBucket = []}
                });
                if (this.requisite.type === REQUISITE_FERTILIZER.typeName && !this.requisite.subtype) {
                    this.requisite.subtype = FERTILIZER_TYPE.FERTILIZER
                }
                this.subTypeError = null;
                if (this.requisite.type === REQUISITE_FERTILIZER.typeName &&
                    (this.requisite.subtype === FERTILIZER_TYPE_OLD.MINERAL_FERTILIZER || this.requisite.subtype === FERTILIZER_TYPE_OLD.ORGANIC_FERTILIZER)) {
                    // Old fertilizer types are not supported and type is cleared
                    this.$set(this.requisite, 'subtype', null);
                    this.subTypeError = ["Kohustuslik väli"];
                }
                if (this.requisite.type === REQUISITE_CHEMICAL.typeName && !this.requisite.subtypeVariant) {
                    // For chemical the default can be set, because previously only official chemical where allowed
                    this.$set(this.requisite, 'subtypeVariant', this.chemicalSubtypeVariants[0].value);
                }
                if (this.requisite.type === REQUISITE_FERTILIZER.typeName && !this.requisite.subtypeVariant) {
                    if (this.isModifingExisting()) {
                        // For fertilizer the default can NOT be set, because it could have been organic fertilizer or something else
                        this.$nextTick(() => {
                            this.$refs.form.validate();
                        });
                    } else {
                        this.$set(this.requisite, 'subtypeVariant', this.fertilizerSubtypeVariants[0].value);
                    }
                }

                if (this.requisite.type === REQUISITE_SEED.typeName &&
                    (this.initialRequisite._id === null || this.initialRequisite._id === undefined) &&
                    this.requisite.seedCertificate === undefined) {
                    this.$set(this.requisite, 'seedCertificate', this.seedCertificates[0].value);
                }
                this.chemicalNames = [];
                this.requisiteUnitError = null;
                this.coatingUnitError = null;
                this.coatingError = null;
                this.ingredientsError = null;
                this.inspectionError = null;
                this.elementP2Model = null;
                this.elementK2Model = null;
                this.chemicalDetails = {files:[]};
            },
            isModifingExisting: function () {
                return this.initialRequisite._id !== null && this.initialRequisite._id !== undefined;
            },
            openPriaFile: function (url) {
                window.open(url, "_blank");
            },
            requisiteTypeName: function (requisiteType) {
                return capitalizeFirstLetter(requisiteType.displayName);
            },
            requisiteTypeObject: function (requisiteType) {
                return requisiteType.typeName;
            },
            validateInspections(result) {
                this.inspectionError = result;
            },
            async addOrUpdate() {
                this.inspectionError = null; // Reset the error. the real validation happens inside inspectionsComponent. This is just stops the submit
                this.disableSubmit = true;
                let formHasErrors = false;
                this.jobEventUpdateCandidates = [];
                let formValidationResults = this.$refs.form.validate();
                if (this.requisite.amount !== null && this.requisite.amount !== undefined && this.requisite.amount !== "" && (this.requisite.unit === undefined || this.requisite.unit === null || this.requisite.unit === "")) {
                    formHasErrors = true;
                    this.requisiteUnitError = ['Kohustuslik väli']
                }

                if (this.$refs.inspectionsComponent) {
                    this.$refs.inspectionsComponent.validateFields();
                    if (this.inspectionError) {
                        formHasErrors = true;
                    }
                }

                if (this.requisite.coating !== undefined &&
                    this.requisite.coating.amount !== null &&
                    this.requisite.coating.amount !== undefined &&
                    this.requisite.coating.amount !== "" &&
                    (this.requisite.coating.unit === undefined || this.requisite.coating.unit === null || this.requisite.coating.unit === "")) {
                    formHasErrors = true;
                    this.coatingUnitError = ['Kohustuslik väli']
                }
                if ((this.requisite.type === REQUISITE_SEED.typeName || this.requisite.type === REQUISITE_FERTILIZER.typeName || (this.requisite.type === REQUISITE_CHEMICAL.typeName && this.isChemicalFromRegistry)) &&
                    (this.requisite.subtype === null || this.requisite.subtype === "" || this.requisite.subtype === undefined || (this.requisite.type === REQUISITE_CHEMICAL.typeName && this.isChemicalFromRegistry && this.unsupportedChemicalSubtype))) {
                    formHasErrors = true;
                    this.subTypeError = 'Kohustuslik väli'
                }
                if ((this.requisite.type === REQUISITE_CHEMICAL.typeName) && this.isChemicalFromRegistry && (this.requisite.ingredients === null || this.requisite.ingredients === undefined || this.requisite.ingredients.length === 0)) {
                    formHasErrors = true;
                    this.ingredientsError = 'Kohustuslik väli'
                } else {
                    this.ingredientsError = null
                }
                if ((this.requisite.type === REQUISITE_FERTILIZER.typeName) && this.isFertilizerFromRegistry && (this.fertilizerExistInRegistry === false)) {
                    formHasErrors = true;
                    this.fertilizerNameError = "Ei leitud registrist!";
                } else {
                    this.fertilizerNameError = null
                }

                if (!formHasErrors && formValidationResults) {
                    // Internal api, not recommended. Put into timeout maybe?
                    if (this.isChemical) {
                        if (this.$refs.ingredients) {
                            this.$refs.ingredients.updateSelf();
                            this.$refs.ingredients.isMenuActive = false;
                            this.$refs.ingredients.isFocused = false;
                        }
                        if (!this.isChemicalFromRegistry) {
                            // clean up unused fields
                            this.requisite.subtype = null
                            this.requisite.inspections = []
                            this.requisite.ingredients = []
                            this.requisite.workDelayDays = null
                            this.requisite.harvestDelayDays = null
                            this.requisite.usageTimes = null
                            this.requisite.safetyArea = null
                            this.requisite.safetyWaterArea = null
                            this.requisite.safetyRecord = null
                        }
                    }
                    if (this.coatingRequisiteModel) {
                        this.requisite.coating.name = this.coatingRequisiteModel.name;
                        this.requisite.coating.requisiteId = this.coatingRequisiteModel._id;
                    }
                    if (!this.coatingModel) {
                        delete this.requisite.coating.name;
                        delete this.requisite.coating.requisiteId;
                        delete this.requisite.coating.amount;
                        delete this.requisite.coating.unit;
                    }

                    if (this.$refs.seedTypes) {
                        this.$refs.seedTypes.updateSelf();
                    }
                    if (this.isModifingExisting()) {
                        this.edit(this.requisite)
                    } else {
                        this.add(this.requisite)
                    }
                } else {
                    this.disableSubmit = false;
                }
            },

            updateJobEvents(jobEventIds) {
                this.$store.dispatch('updateJobEventRequisites', {jobEventIds: jobEventIds, requisite: this.requisite}).then(() => {
                    this.updateJobEventsDialog = false;
                    this.$emit('close-modal');
                    if (jobEventIds.length  > 0) {
                        this.$store.dispatch('loadStatus');
                        this.$store.dispatch('setSystemMessage', {text: "Edukalt salvestatud ja töökanded uuendatud!"});
                    } else {
                        this.$store.dispatch('setSystemMessage', {text: "Edukalt salvestatud!"});
                    }
                }).catch(e => {
                    this.$store.dispatch('setSystemMessage', {text: "Töökannete uuendamine ebaõnnestus!", type:"ERROR"});
                    console.log(e);
                });

            },
            add(data) {
                if (this.$refs.requisiteModel) { // Refreshes input value before reading it
                    this.$refs.requisiteModel.blur();
                }
                let finishInstantly = this.detectJobEventUpdateCandidates(data);
                this.$store.dispatch('addRequisite', data).then(async () => {
                    if (await finishInstantly) {
                        this.$emit('close-modal', data);
                        this.$store.dispatch('setSystemMessage', {text: "Edukalt salvestatud!"});
                    }
                    this.disableSubmit = false;
                }).catch(e => {
                    this.$store.dispatch('setSystemMessage', {text: "Salvestamine ebaõnnestus!", type:"ERROR"});
                    console.log(e);
                    this.disableSubmit = false;
                });
            },
            edit(data) {
                if (this.$refs.requisiteModel) { // Refreshes input value before reading it
                    this.$refs.requisiteModel.blur();
                }
                let finishInstantly = this.detectJobEventUpdateCandidates(data);
                this.$store.dispatch('editRequisite', data).then(async () => {
                    if (await finishInstantly) {
                        this.$emit('close-modal');
                        this.$store.dispatch('setSystemMessage', {text: "Edukalt muudetud!"});
                    }
                    this.disableSubmit = false;
                }).catch(e => {
                    this.$store.dispatch('setSystemMessage', {text: "Salvestamine ebaõnnestus!", type:"ERROR"});
                    console.log(e);
                    this.disableSubmit = false;
                });
            },
            async detectJobEventUpdateCandidates(requisite) {
                let jobEventsToCheck;
                if (this.isModifingExisting()) {
                    jobEventsToCheck = await this.$store.dispatch('findJobEventsByRequsiteName', this.initialRequisite.name);
                } else {
                    jobEventsToCheck = await this.$store.dispatch('findJobEventsByRequsiteName', requisite.name);
                }
                const jobEventUpdateCandidates = detectJobEventsWithChangedRequisites(requisite, jobEventsToCheck);
                if (jobEventUpdateCandidates && jobEventUpdateCandidates.length > 0) {
                    this.updateJobEventsDialog = true;
                    this.jobEventUpdateCandidates = jobEventUpdateCandidates;
                    return false;
                }
                return true;
            },
            addNewRequisite() {
                this.showRequisiteManagementModal = true;
            },
            customSortCoatingChemicals: function (searchValue) {
                if (searchValue && searchValue.length > 0) {
                    const searchValueLowerCase = searchValue.toLowerCase();
                    this.coatingChemicals.sort((first, second) => {
                        const aName = first.name.toLowerCase();
                        const bName = second.name.toLowerCase();
                        const aStartsWithSearch = aName.startsWith(searchValueLowerCase) ? 1 : 0;
                        const bStartsWithSearch = bName.startsWith(searchValueLowerCase) ? 1 : 0;
                        return bStartsWithSearch - aStartsWithSearch;
                    });
                } else {
                    this.coatingChemicals = _.clone(this.stateCoatingChemicals);
                }
            },
            requisiteModalClosed: function (newRequisiteInfo) {
                this.showRequisiteManagementModal = false
                // When creating new then clear the field to make sure user select correct requisites after adding a new one
                // When modifying existing then do not change because the requisite could be dynamically added (missing)
                if (!this.isModifingExisting()) {
                    this.requisiteModel = undefined;
                    if (newRequisiteInfo) {
                        this.requisiteModel = newRequisiteInfo;
                    }
                    if (this.requisites && this.requisites.length === 1) {
                        this.requisiteModel = this.requisites[0];
                    }
                }
            },
            searchFertilizerNameAction(val) {
                const $this = this;
                if (val && val.length >= 2) {
                    if (this.skipFirstFertilizationLoading) {
                        this.skipFirstFertilizationLoading = false;
                        return;
                    }
                    http().get('/fertilizers', { params: {name: encodeURIComponent(val)}})
                        .then(r => r.data.data)
                        .then(fertilizers => {
                            $this.fertilizerNames = fertilizers.map(e => e.name);
                            this.fertilizerExistInRegistry = $this.fertilizerNames.length > 0 && _.some($this.fertilizerNames, entity => entity.toLowerCase() === val.toLowerCase());
                            let exactSearchMatch = _.find(fertilizers, (fertilizer) => {
                                return fertilizer.name.toLowerCase() === val.toLowerCase();
                            });
                            if ($this.fertilizerNames && exactSearchMatch) {
                                $this.requisite.nutrient.N = exactSearchMatch.elements.N;
                                $this.requisite.nutrient.P = exactSearchMatch.elements.P;
                                $this.requisite.nutrient.K = exactSearchMatch.elements.K;
                                $this.requisite.nutrient.CaO = exactSearchMatch.elements.CaO;
                                $this.requisite.nutrient.MgO = exactSearchMatch.elements.MgO;
                            }

                        }).catch(() => {
                        this.fertilizerExistInRegistry = false;
                    });
                } else {
                    this.fertilizerExistInRegistry = true;
                }
            }
        },

        watch: {
            'requisite.amount': function() {
                this.requisiteUnitError = null;
            },
            'requisite.unit': function() {
                this.requisiteUnitError = null;
            },
            'requisite.coating.unit': function() {
                this.coatingUnitError = null;
            },
            'requisite.coating': function() {
                if ((this.requisite.coating && this.requisite.coating.name && this.requisite.coating.requisiteId === undefined) ||
                    (this.requisite.coating && this.requisite.coating.name && this.coatingRequisiteModel === null)) {
                    this.coatingError = [' '];
                } else {
                    this.coatingError = null;
                }
            },
            coatingRequisiteModel: function() {
                if (this.coatingRequisiteModel) {
                    this.coatingError = null;
                }
            },
            coatingModel: function() {
                if (this.coatingModel === true) {
                    this.requisite.coating.unit = "l/t"
                }
            },
            'requisite.subtypeVariant': function() {
                if (this.requisite.type === REQUISITE_FERTILIZER.typeName && (this.requisite.subtypeVariant === 'VAETIS_REGISTRIST' || this.requisite.subtypeVariant === 'LUBIVAETIS')) {
                    this.searchFertilizerNameAction(this.requisite.name)
                }
                if (this.requisite.type === REQUISITE_FERTILIZER.typeName && !this.fertilizerUnits.includes(this.requisite.unit)) {
                    this.requisite.unit = this.fertilizerUnits[0];
                }
            },
            'requisite.subtype': function() {
                this.subTypeError = null;

                if (this.requisite.type === REQUISITE_FERTILIZER.typeName && (
                    this.requisite.subtype !== FERTILIZER_TYPE.FERTILIZER &&
                    this.requisite.subtype !== FERTILIZER_TYPE.FERTILIZER_LIMESTONE
                )) {
                    this.subTypeError = ["Kohustuslik väli"];
                } else {
                    this.subTypeError = null;
                }
                if (this.requisite.type === REQUISITE_FERTILIZER.typeName &&
                    !this.isModifingExisting() &&
                    !_.some(this.fertilizerSubtypeVariants, (variant) => variant.value === this.requisite.subtypeVariant)) {
                    this.$set(this.requisite, 'subtypeVariant', this.fertilizerSubtypeVariants[0].value);
                }
                if (this.requisite.type === REQUISITE_FERTILIZER.typeName && !this.requisite.unit) {
                    this.requisite.unit = this.fertilizerUnits[0];
                }
            },
            'requisite.ingredients': function() {
                this.ingredientsError = null;
            },
            'requisite.type': function() {
                if (this.requisite.type === REQUISITE_FERTILIZER.typeName) {
                    if (!this.requisite.subtype) {
                        this.requisite.subtype = FERTILIZER_TYPE.FERTILIZER
                    }
                    if (this.requisite.subtype !== FERTILIZER_TYPE.FERTILIZER &&
                        this.requisite.subtype !== FERTILIZER_TYPE.FERTILIZER_LIMESTONE) { // OLD TYPE SUPPORT
                        this.requisite.subtype = null;
                    }
                    if (!_.some(this.fertilizerSubtypeVariants, (variant) => variant.value === this.requisite.subtypeVariant)) {
                        this.requisite.subtypeVariant = null;
                    }
                    if (!this.requisite.subtypeVariant) {
                        this.requisite.subtypeVariant = this.fertilizerSubtypeVariants[0].value;
                    }
                }

                if (this.requisite.type === REQUISITE_CHEMICAL.typeName) {
                    if (!_.some(this.chemicalSubtypes, (variant) => variant.value === this.requisite.subtype)) {
                        this.requisite.subtype = null
                    }
                    if (!_.some(this.chemicalSubtypeVariants, (variant) => variant.value === this.requisite.subtypeVariant)) {
                        this.requisite.subtypeVariant = this.chemicalSubtypeVariants[0].value;
                    }
                }

                if (this.requisite.type === REQUISITE_MATERIAL.typeName ||
                    (this.requisite.type === REQUISITE_SEED.typeName && (
                        this.requisite.subtype === FERTILIZER_TYPE_OLD.MINERAL_FERTILIZER || // OLD TYPE
                        this.requisite.subtype === FERTILIZER_TYPE_OLD.ORGANIC_FERTILIZER || // OLD TYPE
                        this.requisite.subtype === FERTILIZER_TYPE.FERTILIZER ||
                        this.requisite.subtype === FERTILIZER_TYPE.FERTILIZER_LIMESTONE ||
                        _.some(this.chemicalSubtypes, (variant) => variant.value === this.requisite.subtype)
                    ))) {
                    this.requisite.subtype = null;
                    this.requisite.subtypeVariant = null;
                }
            },
            initialRequisite(){
                this.init()
            },
            elementP2Model(value){
                if (value !== null) {
                    const newPValue = phosphorusOxideToElement(value);
                    let p = this.requisite.nutrient.P;
                    if (p === undefined) {p = 0}
                    if (Math.abs(newPValue - p) > (newPValue*0.005)) {
                        this.requisite.nutrient.P = newPValue;
                    }
                }
            },
            'requisite.nutrient.P': {
                handler: function(value) {
                    if (value === null || value === undefined || value === 0) this.elementP2Model = null;
                    const newP2Value = (Math.round((value * 2.29147571)*1000))/1000;
                    if (Math.abs(newP2Value - this.elementP2Model) > (newP2Value*0.005)) {
                        this.elementP2Model = newP2Value;
                    }
                },
                deep: true
            },
            elementK2Model(value){
                if (value !== null) {
                    const newKValue = potassiumOxideToElement(value);
                    let k = this.requisite.nutrient.K;
                    if (k === undefined) {k = 0}
                    if (Math.abs(newKValue - k) > (newKValue*0.001)) {
                        this.requisite.nutrient.K = newKValue;
                    }
                }
            },
            'requisite.nutrient.K': {
                handler: function(value) {
                    if (value === null || value === undefined || value === 0) this.elementK2Model = null;
                    const newP2Value = (Math.round((value * 1.2048192)*1000))/1000;
                    if (Math.abs(newP2Value - this.elementK2Model) > (newP2Value*0.001)) {
                        this.elementK2Model = newP2Value;
                    }
                },
                deep: true
            },
            chemicalExistInRegistry() {
                if (this.chemicalExistInRegistry) {
                    this.chemicalNameError = undefined;
                } else {
                    this.chemicalNameError = "Ei leitud PMA taimekaitsev. registrist!";
                }
            },
            fertilizerExistInRegistry() {
                if (this.fertilizerExistInRegistry) {
                    this.fertilizerNameError = undefined;
                } else {
                    this.fertilizerNameError = "Ei leitud registrist!";
                }
            },
            'requisite.name': function() {
                this.chemicalExistInRegistry = this.chemicalNames.length > 0 && this.requisite.name !== null &&
                    _.some(this.chemicalNames, entity => entity.toLowerCase() === this.requisite.name.toLowerCase());
            },
            searchChemicalNames(val) {
                const $this = this;
                const wasFirstLoad = this.firstLoad;
                this.firstLoad = false;
                if (val && val.length >= 3) {
                    http().get('/external-communication/agri/chemical/' + encodeURIComponent(val))
                        .then(r => r.data.data)
                        .then(names => {
                            $this.chemicalNames = names;
                            this.chemicalExistInRegistry = $this.chemicalNames.length > 0 && _.some($this.chemicalNames, entity => entity.toLowerCase() === val.toLowerCase());
                            let containsSearchedChemical = _.find($this.chemicalNames, (name) => {
                                return name.toLowerCase() === val.toLowerCase();
                            });
                            if ($this.chemicalNames && containsSearchedChemical) {
                                http().get('/external-communication/agri/chemical/' + encodeURIComponent(val) + '/details')
                                    .then(r => r.data.data)
                                    .then(details => {
                                        $this.chemicalDetails = details;
                                        if (!$this.isModifingExisting()) { // If creating new
                                            if (this.chemicalDetails.maxNormUnit) {
                                                $this.requisite.unit = this.chemicalDetails.maxNormUnit;
                                            }
                                            if (this.chemicalDetails.maxNorm) {
                                                $this.requisite.amount = parseFloat(this.chemicalDetails.maxNorm.replace(',', '.'));
                                            }
                                            if (this.chemicalDetails.ingredients) {
                                                $this.requisite.ingredients = this.chemicalDetails.ingredients;
                                            }
                                            if (this.chemicalDetails.chemicalSubtype) {
                                                $this.requisite.subtype = this.chemicalDetails.chemicalSubtype;
                                            }
                                        } else { // modifying existing
                                            if (wasFirstLoad) { // first load and current values are empty, then still fill them
                                                if (this.chemicalDetails.maxNormUnit && !this.requisite.unit) {
                                                    $this.requisite.unit = this.chemicalDetails.maxNormUnit;
                                                }
                                                if (this.chemicalDetails.maxNorm && !this.requisite.amount) {
                                                    $this.requisite.amount = parseFloat(this.chemicalDetails.maxNorm.replace(',', '.'));
                                                }
                                                if (this.chemicalDetails.ingredients && (!this.requisite.ingredients || this.requisite.ingredients.length === 0)) {
                                                    $this.requisite.ingredients = this.chemicalDetails.ingredients;
                                                }
                                                if (!this.chemicalDetails.chemicalSubtype) {
                                                    $this.requisite.subtype = this.chemicalDetails.chemicalSubtype;
                                                }
                                            } else { // always update all fields if modifying  existing and user changed the chemical name
                                                $this.requisite.unit = this.chemicalDetails.maxNormUnit;
                                                $this.requisite.amount = parseFloat(this.chemicalDetails.maxNorm.replace(',', '.'));
                                                $this.requisite.ingredients = this.chemicalDetails.ingredients;
                                                $this.requisite.subtype = this.chemicalDetails.chemicalSubtype;
                                            }
                                        }
                                    }).catch(() => {
                                        this.chemicalExistInRegistry = false;
                                });
                            } else {
                                $this.chemicalDetails = {files:[]};
                            }
                        }).catch(() => {});
                } else {
                    this.chemicalExistInRegistry = true;
                }
            },
            searchFertilizerNames(val) {
                this.searchFertilizerNameAction(val)
            },
            searchSeedNames(val) {
                const $this = this;
                if (val && val.length >= 2) {
                    if (this.skipFirstSeedsLoading) {
                        this.skipFirstSeedsLoading = false;
                        return;
                    }
                    http().get('/seeds', { params: {name: encodeURIComponent(val)}})
                        .then(r => r.data.data)
                        .then(seeds => {
                            $this.seedNames = seeds.map(e => e.name);
                            let exactSearchMatch = _.find(seeds, (fertilizer) => {
                                return fertilizer.name.toLowerCase() === val.toLowerCase();
                            });
                            if ($this.seedNames && exactSearchMatch) {
                                $this.requisite.subtype = exactSearchMatch.type;
                            }
                        }).catch(() => {});
                }
            },
            stateCoatingChemicals() {
                this.coatingChemicals = this.stateCoatingChemicals;
            },
        },
        created() {
            setTimeout(() => {
                if (!this.isModifingExisting() && this.$refs.requisiteModel) {
                    this.$refs.requisiteModel.onFocus();
                }
                if (this.$refs.requisiteModel) {this.$refs.requisiteModel.errorBucket = []}
                if (this.$refs.seedBatchModel) {this.$refs.seedBatchModel.errorBucket = []}
            });
            this.$store.dispatch('loadChemicalIngredientsIfNeeded')
            this.init()
        }
    }
</script>