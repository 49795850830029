<template>
    <v-container>
        <v-row no-gutters class="mt-4">
            <v-col>
                <slot></slot>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name:"container-wrapper"
    }
</script>