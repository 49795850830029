const {HERDING_INFO} = require('./constants');

module.exports = {
    herdingTotalHours(herding) {
        if (herding.days && herding.hoursPerDay) {
            return herding.days * herding.hoursPerDay
        }
        return undefined
    },
    herdingNutrientsTotal(herdings, elementName) {
        let result = 0
        if (herdings && herdings.length > 0) {
            herdings
                .filter(h => h.animalType && h.amount)
                .forEach((herding) => {
                    const hours = module.exports.herdingTotalHours(herding)
                    if (hours > 0) {
                        const herdingNutrientInfo = HERDING_INFO.find(nutrientInfo => nutrientInfo.unit === herding.animalType);
                        result += herdingNutrientInfo[elementName] * hours * herding.amount
                    }
                })
        }
        return result
    },

    herdingNutrientsPerHectare(elementValue, area) {
        if (area) {
            return elementValue / area
        } else {
            return 0
        }
    },

};