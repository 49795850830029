import {http} from "@/config/http"
import _ from "lodash";


const state = {
    latelyAddedWorkTimes: [],
    newLatelyAddedWorkTimeIds: [],
    latelyAddedWorkTimeDate: undefined,
};

const mutations = {
    LOAD_LATELY_ADDED_WORK_TIMES(state, events) {
        let latelyAddedWorkTimeIds = state.latelyAddedWorkTimes.map(e => e._id);
        const eventIds = events.map(e => e._id);
        const currentTime = new Date().getTime();
        if (state.latelyAddedWorkTimeDate === undefined) {
            latelyAddedWorkTimeIds = eventIds
        }
        if (state.latelyAddedWorkTimeDate === undefined || currentTime - state.latelyAddedWorkTimeDate > 1000) {
            state.newLatelyAddedWorkTimeIds = [];
        }
        state.latelyAddedWorkTimeDate = currentTime;
        const newJobEvents = eventIds.filter(id => !latelyAddedWorkTimeIds.includes(id));
        if (newJobEvents.length !== 1 || newJobEvents[0] !== _.last(eventIds)) {
            setTimeout(() => {
                state.newLatelyAddedWorkTimeIds.push(...newJobEvents);
            }, 100)
        }
        state.latelyAddedWorkTimes = events;
    },
    CLEAR_NEW_LATELY_ADDED(state) {
        state.newLatelyAddedWorkTimeIds = [];
    },

    RESET_NEW_LATELY_ADDED(state) {
        state.latelyAddedWorkTimeDate = undefined;
    },
};

const actions = {
    addWorkTime({commit, dispatch}, newWorkTimeData) {
        commit('CLEAR_NEW_LATELY_ADDED')
        return http().post('/work-times', newWorkTimeData)
            .then(r => r.data.data)
            .then(() => {
                dispatch('loadLatelyAddedWorkTime');
            })
    },
    editWorkTime({commit, dispatch}, workTimeData) {
        commit('CLEAR_NEW_LATELY_ADDED')
        return http().put('/work-times/' + workTimeData._id, workTimeData)
            .then(r => r.data.data)
            .then(() => {
                dispatch('loadLatelyAddedWorkTime');
            })
    },
    loadLatelyAddedWorkTime({commit}) {
        return http().get('/work-times/last-added')
            .then(r => r.data.data)
            .then((events) => {
                commit('LOAD_LATELY_ADDED_WORK_TIMES', events)
            })
    },
    resetNewLatelyAddedWorkTimes({commit}) {
        commit('RESET_NEW_LATELY_ADDED')
    },
    removeWorkTimes({dispatch}, id) {
        return http().delete('/work-times/' + id)
            .then(() => {
                dispatch('loadLatelyAddedWorkTime');
            })
    },
    toggleWorkTimePaidStatus(_, workTimeId) {
        return http().post('/work-times/' + workTimeId + '/toggle-paid')
            .then(r => r.data.data)
            .then(response => {
                return response.newStatus
            })
    },
    markPeriodPaid(_, payload) {
        return http().post('/workers/' + payload.workerId +'/paid-work-period', payload.period)
    },
};

const getters = {
    latelyAddedWorkTimes: state => {
        return state.latelyAddedWorkTimes;
    },
    getNewLatelyAddedWorkTimes: state => {
        const newLatelyAddedWorkTimeIds = state.newLatelyAddedWorkTimeIds;
        const newLatelyAddedEvents = state.latelyAddedWorkTimes.filter(jobEvent => newLatelyAddedWorkTimeIds.includes(jobEvent._id));
        return _.map(newLatelyAddedEvents, e => e._id);
    },
    getLastAddedWorkTime: state => {
        if (state.latelyAddedWorkTimes.length > 0) {
            return state.latelyAddedWorkTimes[0]
        } else {
            return null
        }
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}