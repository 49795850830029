import Vue from 'vue'
import Vuex from 'vuex'
import fields from "./modules/fields.js"
import jobEvents from "./modules/jobEvents.js"
import imports from "./modules/import.js"
import workers from "./modules/workers.js"
import requisites from "./modules/requisites.js"
import cropsRotation from "./modules/cropsRotation.js"
import settingsModule from "./modules/settings.js"
import user from "./modules/user.js"
import statusModule from "./modules/status.js"
import soilSamples from "./modules/soilSamples.js"
import fertilizationPlan from "./modules/fertilizationPlan.js"
import workTime from "./modules/workTime.js"
import stock from "./modules/stock.js"
import externalData from "@/store/modules/externalData";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        fields,
        jobEvents,
        workers,
        requisites,
        settingsModule,
        user,
        cropsRotation,
        statusModule,
        imports,
        soilSamples,
        fertilizationPlan,
        workTime,
        stock,
        externalData,
    },
})
