<template>
    <v-row no-gutters class="mb-3">
        <v-col
            class="col-12 col-md-3">
            <v-text-field
                :prepend-icon="mdiWeight()"
                v-model="amount"
                type="number"
                min="0"
                data-cy="job-amount-input"
                :suffix="unit"
                label="Sõnniku kogus"
                :error-messages="amountErrors"
            />
        </v-col>
    </v-row>
</template>
<script>

import {mdiWeight} from "@mdi/js";
import {provideCloseDropdown, provideRequiredPositiveNumber} from "@/mixins/mixins";

export default {
    components: {},
    props: {
        jobModel: {
            type: Object,
        },
        initialAmount: {
        },
        initialUnit: {
        },
        isCreatingNew: {
            type: Boolean,
            default: () => false
        },
        area: {
            type: Number,
            default: () => 0
        }
    },
    data() {
        return {
            amount: null,
            unit: "t",
            amountErrors: [],
            unitErrors: [],
        }
    },
    mixins: [provideCloseDropdown, provideRequiredPositiveNumber],
    computed: {},
    methods: {
        mdiWeight() {
            return mdiWeight
        },
        validateManure() {
            this.amountErrors = this.errorsForExistingPositiveNumber(this.amount);
            this.$emit('validate-manure', this.amountErrors !== null)
        },
    },
    watch: {
        initialAmount(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.amount = newVal;
            }
        },
        initialUnit() {
            this.$emit('update:initialUnit', "t");
        },
        amount(newVal) {
            this.amountErrors = null
            this.$emit('update:initialAmount', newVal);
        },
        unit(newVal) {
            this.$emit('update:initialUnit', newVal);
        },

    },
    created() {
        this.amount = this.initialAmount;
        this.$emit('update:initialUnit', this.unit);
    },
}
</script>

