import {http} from "../../config/http.js"

import _ from "lodash";

const state = {
    soilSamples: [],
    soilSampleOverview: [],
    currentYear: ""
};

const mutations = {
    SET_SOIL_SAMPLES(state, soilSamples) {
        state.soilSamples = soilSamples
    },
    SET_SOIL_SAMPLE_OVERVIEW(state, soilSampleOverview) {
        state.soilSampleOverview = soilSampleOverview
    },
    ADD_SOIL_SAMPLE(state, newSample) {
        let sampleDate = new Date(newSample.date);
        if (state.currentYear >= sampleDate.getFullYear() - 5) {
            state.soilSamples.push(newSample)
        }
    },
    REMOVE_SOIL_SAMPLE(state, soilSampleId) {
        state.soilSamples = removeSoilSample(state, soilSampleId);
    },
    EDIT_SOIL_SAMPLE(state, changedSoilSample) {
        const index = _.findIndex(state.soilSamples, _.pick(changedSoilSample, "_id"));
        state.soilSamples.splice(index, 1, changedSoilSample);
    },
    CLEAR_SOIL_SAMPLES(state) {
        state.soilSamples = []
    },
    CURRENT_YEAR(state, year) {
        state.currentYear = year;
    },
};

function removeSoilSample(state, soilSampleId) {
    return _.filter(state.soilSamples, (soilSample) => {
        return soilSample._id !== soilSampleId;
    })
}

const actions = {
    loadSoilSamples({commit}, {fieldId, year}) {
        commit('CLEAR_SOIL_SAMPLES');
        commit('CURRENT_YEAR', parseInt(year));
        return http().get('/soil-samples', { params: {
                fieldId: fieldId,
                year: year,
            }})
            .then(r => r.data.data)
            .then(soilSamples => {
                commit('SET_SOIL_SAMPLES', soilSamples);
            }).catch(() => {})
    },
    loadSoilSampleOverview({commit}, {year}) {
        return http().get('/soil-samples/overview', { params: {
                year: year,
            }})
            .then(r => r.data.data)
            .then(soilSampleOverview => {
                commit('SET_SOIL_SAMPLE_OVERVIEW', soilSampleOverview);
            })
    },
    saveSoilSample({commit}, data) {
        return http().post('/soil-samples', data.soilSample)
            .then(r => r.data.data)
            .then(soilSample => {
                commit('ADD_SOIL_SAMPLE', soilSample);
            })
    },
    editSoilSample({commit}, soilSample) {
        return http().put('/soil-samples/' + soilSample._id, soilSample)
            .then(r => r.data.data)
            .then(soilSample => {
                commit('EDIT_SOIL_SAMPLE', soilSample);
            })
    },
    removeSoilSample({commit}, id) {
        return http().delete('/soil-samples/' + id)
            .then(() => {
                commit('REMOVE_SOIL_SAMPLE', id);
            })
    },
};

const getters = {
    getSoilSamples: state => {
        return state.soilSamples
    },
    getStateSoilSampleOverview: state => {
        return state.soilSampleOverview
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}