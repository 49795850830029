import {http} from "../../config/http.js"
import _ from "lodash";


const state = {
    workers: []
};

const mutations = {
    SET_WORKERS(state, worker) {
        state.workers = worker
    },
    ADD_WORKER(state, newWorker) {
        state.workers.push(newWorker)
    },
    MODIFIED_WORKER(state, workerToChange) {
        const index = _.findIndex(state.workers, _.pick(workerToChange, "_id"));
        state.workers.splice(index, 1, workerToChange);
    },
    REMOVE_WORKER(state, workerToDelete) {
        state.workers = _.filter(state.workers, (field) => {
            return field._id !== workerToDelete._id;
        })
    },
};

const actions = {
    loadWorkers({commit}) {
        http().get('/workers')
            .then(r => r.data.data)
            .then(fields => {
                commit('SET_WORKERS', fields)
            })
    },
    addWorker({commit}, newFieldData) {
        return http().post('/workers', newFieldData)
            .then(r => r.data.data)
            .then(response => {
                commit('ADD_WORKER', response)
            })
    },
    editWorker({commit}, modifiedData) {
        return http().put('/workers/' + modifiedData._id, modifiedData)
            .then(r => r.data.data)
            .then(response => {
                commit('MODIFIED_WORKER', response)
            })
    },
    removeWorker({commit, dispatch}, worker) {
        return http().delete('/workers/' + worker._id)
            .then(() => {
                commit('REMOVE_WORKER', worker)
                dispatch('loadStatus');
            })
    },
};

const getters = {
    getWorkers: state => {
        return state.workers
    },
    worker: state => id => {
        return state.workers.find(worker => worker._id === id);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}