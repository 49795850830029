<template>
    <div>
        <v-file-input
            v-model="selectedFiles"
            label="Lisage pilte"
            multiple
            ref="fileInput"
            accept="image/*"
            @change="previewAndUploadImages"
            style="display: none;"
        ></v-file-input>
        <v-row class="mt-0">
            <template v-for="(file, index) in filesWithThumbnails">
            <v-col cols="12" v-if="file" :md="thumbnailWidth" :key="index">
                <v-card elevation="6" style="width: 900px; height: 160px; border-radius: 8px">
                    <v-img :src="file.thumbnail" class="position-relative" style="opacity: 0.9; width: 100%; height: 100%;">

                        <!-- Container for buttons and progress -->
                        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); display: flex; justify-content: center; align-items: center; width: 100%;">
                            <div style="height:74px;width:74px;display: flex; justify-content: center; align-items: center; margin-right:4px">
                                <v-btn
                                    icon
                                    class="ma-0 elevation-6"
                                    fab
                                    @click="openDialog(file.thumbnail)"
                                    style="background-color: #31a80b; z-index: 15; box-shadow:  0 0 4px rgba(0, 0, 0, 0.6), 0 0 0 6px rgba(0, 0, 0, 0.4) !important"
                                >
                                    <v-icon color="white" size="34">{{ mdiMagnify() }}</v-icon>
                                </v-btn>
                            </div>
                            <div style="height:74px;width:74px;display: flex; justify-content: center; align-items: center; ">
                                <!-- Delete Button -->
                                <v-btn
                                    icon
                                    class="ma-0 elevation-6"
                                    fab
                                    @click="uploadProgress[index] !== null ? cancelFile(index) : deleteFile(index)"
                                    style="background-color: #31a80b; z-index: 15; box-shadow:  0 0 4px rgba(0, 0, 0, 0.6), 0 0 0 6px rgba(0, 0, 0, 0.4) !important; position: relative;"
                                >
                                    <v-icon color="white" size="30">{{ mdiDeleteForever() }}</v-icon>
                                </v-btn>

                                <!-- Progress Circular exactly behind the delete button -->
                                <v-progress-circular
                                    v-if="uploadProgress[index] !== null"
                                    :value="uploadProgress[index]"
                                    :rotate="-90"
                                    color="white"
                                    class="progress-overlay"
                                    size="78"
                                    width="11"
                                    style="z-index: 20; position:absolute; pointer-events: none;"
                                ></v-progress-circular>
                            </div>
                        </div>
                    </v-img>
                </v-card>
            </v-col>
            </template>
        </v-row>
        <v-row>
            <v-col cols="12" class="text-center">
                <v-btn color="primary" dark large elevation="0" @click="triggerFileInput">
                    <v-icon>add</v-icon>
                    Lisa pilt
                    <v-icon class="ml-2">{{ mdiCameraOutline() }}</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog
            v-model="dialog"
            width="100%"
        >
            <v-btn color="white" elevation="0" :small="!$vuetify.breakpoint.mdAndUp" right fab absolute
                   class="closeButton mt-6 mr-6" style="box-shadow: 0 0 40px rgb(0, 0, 0) !important"
                   @click="dialog = false">
                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 50 : 30" style="color: #31a80b">{{ mdiClose() }}</v-icon>
            </v-btn>
            <v-img :src="dialogImageUrl" class="white--text" @click="dialog = false">
                <v-row class="fill-height" align="center" justify="center">
                    <v-icon size="56">mdi-close</v-icon>
                </v-row>
            </v-img>
        </v-dialog>
    </div>
</template>

<script>
import {mdiCameraOutline, mdiClose, mdiDeleteForever, mdiMagnify} from "@mdi/js";
import { http } from "@/config/http";
import {VBtn} from "vuetify/lib";
import axios from 'axios';

export default {
    components: {VBtn},
    props: {
        initialPhotoIds: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            fileIds: [],
            selectedFiles: [],
            filesWithThumbnails: [], // Array to store selected files and their thumbnails
            uploadProgress: [], // Store upload progress for each file
            cancelTokens: [], // Store cancel tokens for each file upload
            dialog: false, // Controls the visibility of the dialog
            dialogImageUrl: '' // URL of the image to show in the dialog
        };
    },
    watch: {
        initialPhotoIds: {
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.fileIds = newVal;
                    this.uploadProgress = this.fileIds.map(() => null);
                    this.filesWithThumbnails = this.fileIds.map((fileId) => {
                        let imageUrl = `https://s3.eu-north-1.amazonaws.com/ee.abimasin.photo/polluraamat/${fileId}.webp`;
                        if (window.location.hostname === 'localhost') {
                            imageUrl = `https://s3.eu-north-1.amazonaws.com/ee.abimasin.photo/polluraamat/_test_${fileId}.webp`;
                        }
                        return {
                            file: null,
                            thumbnail: imageUrl
                        };
                    });
                }
            },
            deep: true,
            immediate: true
        },
        fileIds() {
            this.$emit('update:initialPhotoIds', this.fileIds);
        },
    },
    computed: {
        thumbnailWidth() {
            const imageCount = this.filesWithThumbnails.filter(file => file !== null).length;
            if (imageCount === 1) return 12;
            if (imageCount === 2) return 6;
            return 4;
        },
        fileThumbnailsWithoutNulls() {
            return this.filesWithThumbnails.filter((file) => file !== null);
        }
    },
    methods: {
        mdiDeleteForever() {
            return mdiDeleteForever
        },
        mdiCameraOutline() {
            return mdiCameraOutline
        },
        mdiClose() {
            return mdiClose
        },
        mdiMagnify() {
            return mdiMagnify;
        },
        openDialog(imageUrl) {
            this.dialogImageUrl = imageUrl;
            this.dialog = true;
        },
        triggerFileInput() {
            this.$refs.fileInput.$refs.input.click(); // Access the underlying input element and trigger click
        },
        async previewAndUploadImages() {
            const promises = [];
            // Preview and store thumbnails of selected files
            for (let i = 0; i < this.selectedFiles.length; i++) {
                const file = this.selectedFiles[i];
                const length = this.filesWithThumbnails.length;
                this.filesWithThumbnails.push({file: file, thumbnail: URL.createObjectURL(file)});
                this.uploadProgress.push(0); // Initialize progress for each file
                this.fileIds.push(null); // Initialize progress for each file
                this.cancelTokens.push(null); // Initialize cancel token for each file

                const fileUploaded = this.uploadFile(file, length);
                promises.push(fileUploaded);
            }

            Promise.all(promises).then(() => {
                this.selectedFiles = [];
            });
        },
        async uploadImages() {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                const file = this.selectedFiles[i];
                await this.uploadFile(file, i); // Pass index to track progress of each file
            }
            this.selectedFiles = []; // Clear selected files after upload
        },
        async uploadFile(file, index) {
            const formData = new FormData();
            formData.append("image", file);

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            this.$set(this.cancelTokens, index, source);

            try {
                const response = await http().post("/images", formData, {
                    timeout: 50000,
                    cancelToken: source.token,
                    onUploadProgress: (progressEvent) => {
                        this.$set(this.uploadProgress, index, Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    },
                });
                this.$set(this.fileIds, index, response.data.files[0]);
                this.uploadProgress[index] = null;
            } catch (error) {
                if (axios.isCancel(error)) {
                    this.deleteFile(index);
                } else {
                    this.deleteFile(index);
                    console.log(error)
                    this.$store.dispatch('setSystemMessage', {text: "Pildi lismine ebaõnnestus!", type:"ERROR", timeout: 5000});
                }
            }
        },
        async cancelFile(index) {
            if (this.cancelTokens[index]) {
                this.cancelTokens[index].cancel('User canceled the upload.');
                this.uploadProgress[index] = null;
            }
        },
        async deleteFile(index) {
            const fileId = this.fileIds[index];
            const thumbnail = this.filesWithThumbnails[index];
            if (fileId && thumbnail.file !== null) {
                http().delete("/images/" + fileId);
            }
            this.$set(this.filesWithThumbnails, index, null);
            this.$set(this.uploadProgress, index, null);
            this.$set(this.fileIds, index, null);
            this.$set(this.cancelTokens, index, null);
        },
    },
};
</script>

<style>
.position-relative {
    position: relative;
}

.progress-overlay {
    position: absolute;
    margin-top: 0px;
    z-index: 10; /* Ensure progress bar overlays image */
}
</style>
