<template>
    <v-card>
        <v-card-title class="grey lighten-4 text-h6">
        <v-btn color="primary" elevation="0" small right fab absolute class="closeButton"
                   @click="doNotUpdateJobEvents()">
                <v-icon>close</v-icon>
            </v-btn>
            Seotud töökannete uuendamine
        </v-card-title>
        <modal-container-wrapper>
            <v-card-text>Soovi korral valige nimekirjast töökanded mida sooviksite uuendada. Uuendatakse ainult nime ja kasutamist piiravaid
            kriteeriume nagu tööoode, ooteaeg, ohutusala jne.
            </v-card-text>
            <v-row no-gutters class="justify-center justify-md-end mt-4 mb-4">
                <v-btn outlined color="primary" class="mx-2" @click="selectAll()">vali kõik</v-btn>
                <v-btn outlined color="primary" @click="deselectAll()">eemalda valik kõigilt</v-btn>
            </v-row>

            <v-list one-line subheader style="margin-bottom:40px">
                <v-list-item :key="element.jobEvent._id" v-for="element in jobEvents" style="border-bottom: 1px solid #c5c5c5;">
                    <v-list-item-action>
                        <v-checkbox color="primary" v-model="element.include"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content @click.stop="element.include = !element.include">
                        <v-list-item-title><strong> {{element.jobEvent.date | toDateString}}</strong> {{fieldName(element.jobEvent)}} - {{element.jobEvent.job}}</v-list-item-title>
                        <v-list-item-subtitle>
                            <div v-html="toChangeLog(element.changes)"></div>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template #footer>
                <v-btn class="mx-2" @click="doNotUpdateJobEvents()">Ära töökandeid muuda</v-btn>
                <v-btn class="primary" :disabled="someJobEventsAreaSelected" @click="updateJobEvents()">Uuenda valitud töökanded</v-btn>
            </template>
        </modal-container-wrapper>
    </v-card>
</template>

<script>

    import {fieldName} from "@/mixins/mixins";
    import { REQUISITE_RESTRICTIONS } from "../../../dist/common/constants.js"
    import _ from "lodash";
    import modalContainerWrapper from "../components/modalContainerWrapper";

    export default {
        components: {
            modalContainerWrapper
        },
        props: {
            jobEventUpdateCandidates: {
                type: Array
            }
        },
        data: function () {
            return {
                jobEvents: _.cloneDeep(this.jobEventUpdateCandidates),
                sound: null
            }
        },
        mixins: [fieldName],
        computed: {
            someJobEventsAreaSelected() {
                for (let i = 0; i < this.jobEvents.length; i++) {
                    if (this.jobEvents[i].include === true) {
                        return false
                    }
                }
                return true;
            },
        },
        methods: {
            updateJobEvents() {
                let includedJobEvents = _.filter(this.jobEvents, event => event.include === true);
                const jobEventIdsToUpdate = _.map(includedJobEvents, elem => elem.jobEvent._id);
                this.$emit('updateJobEvents', jobEventIdsToUpdate);
            },
            doNotUpdateJobEvents() {
                this.$emit('updateJobEvents', []);
            },
            changeType(change) {
                if (change === REQUISITE_RESTRICTIONS.WORK_DELAY_DAYS) {
                    return "Tööoode"
                }
                if (change === REQUISITE_RESTRICTIONS.HARVEST_DELAY_DAYS) {
                    return "Ooteaeg"
                }
                if (change === REQUISITE_RESTRICTIONS.USAGE_TIMES) {
                    return "Kasutuskordi"
                }
                if (change === REQUISITE_RESTRICTIONS.SAFETY_AREA) {
                    return "Ohutusala"
                }
                if (change === REQUISITE_RESTRICTIONS.SAFETY_WATER_AREA) {
                    return "Ohutusala veepiirist"
                }
                if (change === REQUISITE_RESTRICTIONS.SAFETY_RECORD) {
                    return "Ohutuskirje"
                }
                if (change === "name") {
                    return "Tarviku nimi"
                }
                return change;
            },
            safeChangeValue(value) {
                if (value === undefined || value === null ||value === '') {
                    return " puudub "
                }
                const length = 15;
                return value.length > length ? value.substring(0, length - 3) + "..." : value;
            },
            toChangeLog(changes) {
                return _.map(changes, change => { return "<strong>" + this.changeType(change.property) + "</strong>: " +
                    "<strong style='color:#b72d2d'>" + this.safeChangeValue(change.initialValue) + "</strong> >> <strong style='color:#008d00'>" + this.safeChangeValue(change.newValue) + "</strong>"}).join(", ")
            },
            selectAll() {
                for (let i = 0; i < this.jobEvents.length; i++) {
                    this.$set(this.jobEvents[i], 'include', true)
                }
            },
            deselectAll() {
                for (let i = 0; i < this.jobEvents.length; i++) {
                    this.$set(this.jobEvents[i], 'include', false)
                }
            },
        },

        watch: {
            jobEventUpdateCandidates: {
                handler: function(value) {
                    this.jobEvents = _.cloneDeep(value)
                },
                deep: true
            }
        }
    }
</script>