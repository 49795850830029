import {http} from "../../config/http.js"
import _ from "lodash";
import {roundToForDecimals} from "../../../../dist/common/numberRounding";
import * as Sentry from "@sentry/browser";

const state = {
    user: null,
    csrfToken: null,
    priaJustConnected: false
};

const mutations = {
    AUTHENTICATE(state, user) {
        if (state.user && user && user.pria && user.pria.identificationNumber && (state.user.pria === null || state.user.pria === undefined || state.user.pria.identificationNumber === null || state.user.pria.identificationNumber === undefined)) {
            state.priaJustConnected = true;
        }
        state.user = user;
    },
    REFRESH_USER(state, user) {
        state.user = user
    },
    LOG_OUT(state) {
        state.user = null;
        state.csrfToken = null;
    },
    CSRF(state, csrfToken) {
        state.csrfToken = csrfToken
    }
};

const actions = {
    loadUser({commit}, credentials) {
        return http().get('/auth', credentials)
            .then(r => r.data)
            .then(response => {
                commit('AUTHENTICATE', response.user);
                commit('CSRF', response.csrfToken)
                try {
                    Sentry.setUser({ username: response.user.company.name, email: response.user.email });
                } catch (ignored) {/*ignored*/}
            }).catch(error => {
                try {
                    commit('CSRF', error.response.data.csrfToken)
                } catch(error) { /* ignored */ }
            })
    },
    login({commit}, credentials) {
        return http().post('/auth/login', credentials)
            .then(r => r.data)
            .then(response => {
                commit('AUTHENTICATE', response)
                try {
                    Sentry.setUser({ username: response.company.name, email: response.email });
                } catch (ignored) {/*ignored*/}
            })
    },
    resetPassword(_, credentials) {
        return http().post('/auth/resetPassword', credentials)
    },
    setNewPasswordAfterReset(_, credentials) {
        return http().post('/auth/setPasswordAfterReset', credentials)
    },
    setNewPassword(_, credentials) {
        return http().post('/auth/setPassword', credentials)
    },
    linkUser({commit}, userToLink) {
        return http().put('/users/link', userToLink)
            .then(r => r.data)
            .then(response => {
                commit('AUTHENTICATE', response)
            })
    },
    unlinkUser(_, userToLink) {
        return http().post('/users/link', userToLink)
    },
    switchUser(_, userToSwitch) {
        return http().post('/users/switch', userToSwitch)
    },
    createLinkedUser(_, user) {
        return http().post('/users/linked-user', user)
    },
    logout({commit, dispatch}) {
        return http().get('/auth/logout')
            .then(() => {
                commit('LOG_OUT');
                dispatch('clearStatus');
                dispatch('resetNewLatelyAddedJobEvents');
                dispatch('resetNewLatelyAddedWorkTimes');
            })
    },
    registration({commit}, registrationForm) {
        return http().post('/users/registration', registrationForm)
            .then(r => r.data)
            .then(response => {
                commit('AUTHENTICATE', response)
            })
    },
    sendFeedback({}, message) {
        return http().post('/email/feedback', _.merge(message, {
            name: state.user.company.name,
            email: state.user.email
        }))
    },
    orderArea(_, order) {
        return http().post('/orders', order)
    },
    updateAccountInfo({commit}, accountInfo) {
        return http().put('/users/company', accountInfo)
            .then(r => r.data)
            .then(response => {
                commit('REFRESH_USER', response)
            })
    },
    deleteAccount() {
        return http().delete('/users')
    },
    updateSettings({commit}, update) {
        return http().post('/users/settings', update)
            .then(r => r.data)
            .then(response => {
                commit('REFRESH_USER', response)
            })
    }
};

const getters = {
    user: state => {
        return state.user
    },
    isAuthenticated: state => {
        return state.user != null
    },
    csrfToken: state => {
        return state.csrfToken;
    },
    hasPaidThisYear: (state, getters) => {
        if (state.user && state.user.status && state.user.status.payments && getters.getCurrentFullYear) {
            const currentFullYear = getters.getCurrentFullYear;
            return _.some(state.user.status.payments, payment => (payment.year === currentFullYear && payment.paidArea > 0));
        }
        return false;
    },
    getThisYearPayment: (state, getters) => {
        if (state.user && state.user.status && state.user.status.payments && getters.getCurrentFullYear) {
            return _.find(state.user.status.payments, payment => {
                return payment.year === getters.getCurrentFullYear; });
        }
        return null;
    },
    hasEverPaid: (state) => {
        if (state.user && state.user.status && state.user.status.payments) {
            return roundToForDecimals(_.sum(_.map(state.user.status.payments, payment => payment.paidArea))) > 0;
        }
        return false;
    },
    priaJustConnected: (state) => {
        return state.priaJustConnected;
    },
    getLinkedActiveArea: (state, getters) => {
        let linkedDiners = getters.getLinkedDiners;
        if (linkedDiners) {
            return linkedDiners.totalArea
        } else {
            return 0;
        }
    },
    getRequestedArea: (state, getters) => {
        let linkedDinerRequestedArea = 0;
        let linkedDiners = getters.getLinkedDiners;
        if (linkedDiners) {
            linkedDinerRequestedArea = linkedDiners.requestedArea
        }

        let thisYearPayment = getters.getThisYearPayment;
        if (thisYearPayment && thisYearPayment.requestedArea) {
            return thisYearPayment.requestedArea + linkedDinerRequestedArea;
        }
        return linkedDinerRequestedArea;
    },
    getPaidArea: (state, getters) => {
        let linkedDinerPaidArea = 0;
        let linkedDiners = getters.getLinkedDiners;
        if (linkedDiners) {
            linkedDinerPaidArea = linkedDiners.paidArea
        }
        let thisYearPayment = getters.getThisYearPayment;
        if (thisYearPayment && thisYearPayment.paidArea) {
            return thisYearPayment.paidArea + linkedDinerPaidArea;
        }
        return linkedDinerPaidArea;
    },
    getCurrentUserRequestedArea: (state, getters) => {
        let thisYearPayment = getters.getThisYearPayment;
        if (thisYearPayment && thisYearPayment.requestedArea) {
            return thisYearPayment.requestedArea;
        }
        return 0;
    },
    hasPaidForServiceThisYear: (state, getters) => {
        let linkedDiners = getters.getLinkedDiners;
        const hasPaidForLinkedDinersThisYear = !!(linkedDiners && linkedDiners.paidArea && linkedDiners.paidArea > 0);
        return (hasPaidForLinkedDinersThisYear || getters.hasPaidThisYear);
    },
    hasPaidEver: (state, getters) => {
        let linkedDiners = getters.getLinkedDiners;
        const hasPaidEverForLinkedDiners = !!(linkedDiners && linkedDiners.hasPaidEver);
        return hasPaidEverForLinkedDiners || getters.hasEverPaid;
    },
    getLinkedUsers: (state) => {
        if (state.user && state.user.status) {
            let linkedUsers = _.orderBy(state.user.status.linkedUsers, 'company.name');
            return _.concat(state.user, linkedUsers);
        }
    },
    getWorkTimeEnabledByConf: (state) => {
        if (state.user && state.user.settings) {
            return state.user.settings.workTime;
        }
        return false
    },
    getPriaConnected: (state) => {
        return !!(state.user && state.user.pria && state.user.pria.identificationNumber);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}