import {findFieldYearInfo, findFieldYearInfoForDate} from "../../../dist/common/fieldYearInfo.js"
import _ from "lodash";
import {ALL_REQUISITES} from "@/constant/requisiteType";
import {capitalizeFirstLetter} from "@/utils/stringUtils";
import {SOCK_TYPES} from "@/constant/constants";
import {
    mdiCalendarStartOutline,
    mdiDotsTriangle,
    mdiFlaskOutline,
    mdiPackageVariantClosed,
    mdiSeedOutline,
    mdiTrayArrowDown
} from "@mdi/js";
import {chemicalSubtypeVariants, FERTILIZER_TYPE} from "../../../backend/common/constants";

export const fieldName = {
    methods: {
        fieldName(item) {
            if (this.$store.getters.field(item.fieldId)) {
                return findFieldYearInfoForDate(this.$store.getters.field(item.fieldId), item.date).name
            } else {
                return ""
            }
        },
        fieldNameByYear(item, year) {
            if (this.$store.getters.field(item.fieldId)) {
                return findFieldYearInfo(this.$store.getters.field(item.fieldId), year).name
            } else {
                return ""
            }
        }
    }
};
export const provideCloseDropdown = {
    methods: {
        provideCloseDropdown(select) {
            if (select.isMenuActive) {
                select.isMenuActive = false;
                select.blur();
            } else {
                select.isMenuActive = true;
                select.focus();

            }
        }
    }
};

export const provideRequiredPositiveNumber = {
    methods: {
        errorsForExistingPositiveNumber(value) {
            if (value === undefined || value === null || value === '') {
                return ["Kohustuslik väli"]
            }
            if (parseFloat(value) <= 0) {
                return ["Number peab olema positiivne"]
            }
            return null;
        },
    }
};

const randomCropColors = [
    "#29B6F6",
    "#9CCC65",
    "#26A69A",
    "#EA80FC",
    "#7986CB",
    "#66BB6A",
    "#FF80AB",
    "#B39DDB",
    "#FF7043",
    "#FFA726",
    "#E57373",
    "#26C6DA",
    "#650065",
    "#930093",
    "#FF00FF",
    "#FF0099",
    "#AD00FF",
    "#FFA9FF",
    "#00005E",
    "#00008F",
    "#0000FF",
    "#5200FF",
    "#0066FF",
    "#A0A0FF",
    "#00FFFF",
    "#00C2FF",
    "#00FFC2",
    "#AAFFFF",
    "#7FFF00",
    "#00FF66",
    "#CCFF00",
    "#CCFF9A",
    "#AFAF00",
    "#D9D900",
    "#FFFF00",
    "#DBFF00",
    "#FFE600",
    "#8F4C00",
    "#C26800",
    "#FF8800",
    "#FFB800",
    "#FF7A00",
    "#FFCF99",
    "#790000",
    "#990000",
    "#FF0000",
    "#FF5C00",
    "#FF005C",
    "#FF9595",
]
export const findCropColor = {
    methods: {
        findCropMapColors(cropNames) {
            const uniqueSet = new Set(cropNames.filter(e => e).map(cropName => cropName.trim()));
            const uniqueCropNames = Array.from(uniqueSet);
            const cropColors = {};
            let randomColorCounter = 0;
            uniqueCropNames.forEach((cropName) => {
                const definedCropColor = getCropDefinedColor(cropName);
                if (definedCropColor) {
                    cropColors[cropName] = definedCropColor
                } else {
                    cropColors[cropName] = randomCropColors[randomColorCounter]
                    if (randomColorCounter <= randomCropColors.length - 1) {
                        randomColorCounter++;
                    }
                }
            })

            return cropColors;
        },
    }
};

function getCropDefinedColor(cropName) {
    if (cropName === null || cropName === undefined || cropName === "-" || cropName === "") {
        return "#e1e1e1"
    }
    if (cropName.match(/.*raps.*/gi)) {
        return '#BA68C8'
    }
    if (cropName.match(/.*rüps.*/gi)) {
        return '#c403e5'
    }
    if (cropName.match(/.*mais.*/gi)) {
        return '#ceff87'
    }
    if (cropName.match(/.*ristik.*/gi)) {
        return '#83e000'
    }
    if (cropName.match(/.*hein.*|.*rohttaimed.*/gi)) {
        return '#c74d28'
    }
    if (cropName.match(/.*nurmikas.*/gi)) {
        return '#8D6E63'
    }
    if (cropName.match(/.*lutsern.*/gi)) {
        return '#A1887F'
    }
    if (cropName.match(/.*mesikas.*/gi)) {
        return '#BCAAA4'
    }
    if (cropName.match(/.*timut.*/gi)) {
        return '#945b48'
    }
    if (cropName.match(/.*rohumaa.*/gi)) {
        return '#d77656'
    }
    if (cropName.match(/.*nisu.*/gi)) {
        return '#ffc300'
    }
    if (cropName.match(/.*rukis.*/gi)) {
        return '#ffa600'
    }
    if (cropName.match(/.*.*kaer.*/gi)) {
        return '#ffe18b'
    }
    if (cropName.match(/.*oder.*/gi)) {
        return '#d56b00'
    }
    if (cropName.match(/.*tritikal.*/gi)) {
        return '#dea875'
    }
    if (cropName.match(/.*hernes.*/gi)) {
        return '#53c260'
    }
    if (cropName.match(/.*uba.*/gi)) {
        return '#0287e8'
    }
    // https://v15.vuetifyjs.com/en/framework/colors
    return null
}
export const cropNameMixin = {
    methods: {
        cropNameFromCropRotation(fieldId) {
            let cropsRotation = this.$store.getters.getCropsRotation;
            if (cropsRotation) {
                const currentFieldCrops = _.find(cropsRotation, rotation => rotation._id === fieldId);
                if (currentFieldCrops) {
                    const currentYearCrop = _.find(currentFieldCrops.cropsByYear, s => s.year === this.year);
                    if (currentYearCrop && currentYearCrop.mainCrop) {
                        return currentYearCrop.mainCrop
                    }
                }
            }
            return "-"
        },
    }
};

export const stockAdjustment = {
    methods: {
        getStockAdjustmentIcon(type) {
            if (type === SOCK_TYPES.INCREASE) return 'keyboard_tab';
            if (type === SOCK_TYPES.DECREASE) return 'keyboard_backspace';
            if (type === SOCK_TYPES.CURRENT_BALANCE) return mdiTrayArrowDown;
            if (type === SOCK_TYPES.PERIOD_START_BALANCE) return mdiCalendarStartOutline;
            // if (type === SOCK_TYPES.PERIOD_END_BALANCE) return mdiCalendarEndOutline;
            // if (type === SOCK_TYPES.DYNAMIC_FIXUP) return 'priority_high';
            return null
        },
        getStockAdjustmentIconForRecord(type) {
            if (type === SOCK_TYPES.DYNAMIC_FIXUP) return 'error';
            return null
        },
        getStockAdjustmentTextColorForRecord(adjustmentRecord) {
            if (adjustmentRecord.adjustmentType === SOCK_TYPES.DYNAMIC_FIXUP) return "#d00000";
            if (adjustmentRecord.adjustmentType === SOCK_TYPES.INCREASE) return "#239d00";
            return null
        },
        getStockAdjustmentTextFontWeightForRecord(adjustmentRecord) {
            if (adjustmentRecord.adjustmentType === SOCK_TYPES.DYNAMIC_FIXUP) return null;
            if (adjustmentRecord.adjustmentType === SOCK_TYPES.PERIOD_END_BALANCE) return "700";
            // if (adjustmentRecord.amountChange > 0) return "500";
            return null
        },
        getStockAdjustmentColor(type) {
            if (type === SOCK_TYPES.DYNAMIC_FIXUP) return 'red darken-4';
            return undefined
        },
        isJobEventAdjustment(adjustmentRecord) {
            return adjustmentRecord.adjustmentType === SOCK_TYPES.JOB_EVENT
        },
    }
}
export const requisiteIcon = {
    methods: {
        requisiteIcon: function (type) {
            if (type === 'fertilizer') return mdiDotsTriangle;
            if (type === 'seed') return mdiSeedOutline;
            if (type === 'chemical') return mdiFlaskOutline;
            return mdiPackageVariantClosed
        },
        requisiteIconColor: function (type) {
            if (type === 'fertilizer') return '#813cc2';
            if (type === 'seed') return 'rgb(152,159,0)';
            if (type === 'chemical') return '#d75b33';
            return 'rgb(0,139,199)'
        },
        requisiteTypeName: function (requisiteTypeName) {
            const requisiteType = _.find(ALL_REQUISITES, type => type.typeName === requisiteTypeName);
            if (requisiteType) {
                return capitalizeFirstLetter(requisiteType.displayName)
            } else {
                return "Tarvik"
            }
        },
    }
};


export const fertilizerDropdown = {
    computed: {
        fertilizerTypes() {
            return [
                {name:"väetis", value: FERTILIZER_TYPE.FERTILIZER},
                {name:"lubiväetis", value: FERTILIZER_TYPE.FERTILIZER_LIMESTONE},
            ]
        },
    }
};

export const chemicalTypeDisplay = {
    methods: {
        chemicalTypeText(chemicalType) {
            if (chemicalType === "PMA_TKV_TOIME_LIIK_KASVUREGULAATOR") return "kasvuregulaator";
            if (chemicalType === "PMA_TKV_TOIME_LIIK_FUNGITSIID") return "fungitsiid";
            if (chemicalType === "PMA_TKV_TOIME_LIIK_HERBITSIID") return "herbitsiid";
            if (chemicalType === "PMA_TKV_TOIME_LIIK_INSEKTITSIID") return "insektitsiid";
            return "tundmatu";
        },
    }
};

export const requisiteSelectionMethods = {
    methods: {
        requisiteAmountAndUnitValue(requisite) {
            let suffix = "";
            if (requisite.amount || requisite.unit) {
                suffix += " - "
                if (requisite.amount) {
                    suffix += requisite.amount + " "
                }
                if (requisite.unit) {
                    suffix += requisite.unit
                }
            }
            if (requisite.note) {
                suffix += " - " + requisite.note
            }
            return suffix
        },
        requisiteSubtypeVariant(requisite) {
            if (requisite.subtypeVariant) {
                const subtypeVariant = chemicalSubtypeVariants.find(variant => variant.value === requisite.subtypeVariant);
                if (subtypeVariant && subtypeVariant.value !== 'TKV_REGISTRIST') { // Do not show label for chemicals from registry
                    return subtypeVariant.label
                }
            }
            return null
        },
        customSortRequisites: function (searchValue) {
            if (searchValue && searchValue.length > 0) {
                const searchValueLowerCase = searchValue.toLowerCase();
                this.requisites.sort((first, second) => {
                    const aName = first.name.toLowerCase();
                    const bName = second.name.toLowerCase();
                    const aStartsWithSearch = aName.startsWith(searchValueLowerCase) ? 1 : 0;
                    const bStartsWithSearch = bName.startsWith(searchValueLowerCase) ? 1 : 0;
                    return bStartsWithSearch - aStartsWithSearch;
                });
            } else {
                this.requisites = _.clone(this.stateRequisites);
            }
        },
        checkIfShowRequisiteReminder: function () {
            this.showAddRequisiteReminder = (this.$refs.requisiteModel &&
                this.$refs.requisiteModel.lazySearch &&
                this.$refs.requisiteModel.lazySearch.length < 4);
        },
    }
};
