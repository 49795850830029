const {FERTILIZER_TYPE_OLD, FERTILIZER_TYPE} = require("./constants");

module.exports = {
    isMineralFertilizer: (subtype, subtypeVariant) => {
        return (subtype === FERTILIZER_TYPE.FERTILIZER_LIMESTONE ||
            (subtype === FERTILIZER_TYPE.FERTILIZER && (subtypeVariant === "VAETIS_REGISTRIST" || subtypeVariant === "VAETIS_EL")))
        || subtype === FERTILIZER_TYPE_OLD.MINERAL_FERTILIZER // OLD type. Delete after migration
    },
    isOrganicFertilizer: (subtype, subtypeVariant) => {
        return (subtype === FERTILIZER_TYPE.FERTILIZER && subtypeVariant !== "VAETIS_REGISTRIST" && subtypeVariant !== "VAETIS_EL")
        || subtype === FERTILIZER_TYPE_OLD.ORGANIC_FERTILIZER // OLD type. Delete after migration
    },
};